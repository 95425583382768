<div class="main-wrapper">
      <form [formGroup]="form"   (ngSubmit)="addEconomicalData()" class="default-form">
      <div class="form-fields">
        <ng-container *ngIf="epic$ | async as epic">

            <div class="d-flex px-0 flex-column flex-md-row justify-content-between">

                <h2 class="main-heading align-self-start">Budget Information</h2>

                <ng-container *ngIf="totalAndAvailableBudget$ | async as totalAndAvailableBudget">
                  <h2 class="main-heading align-self-start mr-5">Available budget of
                    the Valuestream:
                    {{totalAndAvailableBudget.availableBudget| currency:"EUR":"symbol" }}
                  </h2>
                  </ng-container>

        </div>

        <div class="form-fields-inner d-flex flex-column flex-md-row justify-content-start">

          <!-- formControlName="" -->
          <div class="flex-grow-1 d-flex flex-column">
            <h2>Context</h2>
            <div class="d-flex flex-column flex-lg-row">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label> Id </mat-label>
                <input readonly value="{{epic.numeration}}" matInput placeholder="Id">
                <mat-hint>Epic/Feature ID</mat-hint>
                <mat-error>
                </mat-error>
              </mat-form-field>

            <mat-form-field appearance="fill" class="px-0">
              <mat-label> Item Name </mat-label>
              <input readonly value="{{epic.itemName || epic.description}}" matInput placeholder="Name">
              <mat-hint>Epic or Feature Name</mat-hint>
              <mat-error>
              </mat-error>
            </mat-form-field>




          </div>
          <div class="d-flex flex-column flex-md-row">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Key Result</mat-label>
              <input matInput readonly value="{{epic?.keyResult?.description}}">
              <mat-hint>Key Result related</mat-hint>
              <mat-error>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="px-0">
              <mat-label> Scope </mat-label>
              <input matInput readonly value="{{scope}}">
              <mat-hint>Scope</mat-hint>
              <mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex flex-column flex-md-row">

            <mat-form-field appearance="fill" class="px-0">
              <mat-label> Status </mat-label>
              <input matInput readonly value="{{epic.state}}">
              <mat-hint>Status</mat-hint>
              <mat-error>
              </mat-error>
            </mat-form-field>

            <ng-container *ngIf="epicOwners$ | async as epicOwners">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label>Epic Owner</mat-label>
                <mat-select formControlName="epicOwnerId" (selectionChange)="updateEpicOwner(epic.id,$event.value)">
                  <mat-option *ngFor="let owner of epicOwners" [value]="owner.userId">
                    {{owner.user.name}}
                  </mat-option>
                </mat-select>
                <!-- <input matInput placeholder="{{ 'anms.epic.form.placeholder.owner' | translate }}"
                  formControlName="epicOwner"> -->
                  <mat-hint> Epic Owner</mat-hint>
                  <mat-error *ngIf="form.get('epicOwnerId').invalid">
                    {{ 'anms.epic.form.placeholder.owner' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>

            </div>

            <div class="d-flex flex-column flex-md-row">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label> Start Date </mat-label>
              <input matInput readonly value="{{epic.startDate | date: 'dd/MM/yyyy'}}">
              <mat-hint> Start Date of the EPIC </mat-hint>
              <mat-error>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Due Date</mat-label>
              <input matInput readonly value="{{epic.dueDate | date: 'dd/MM/yyyy'}}">
              <mat-hint>Due date of the EPIC</mat-hint>
              <mat-error>
              </mat-error>
            </mat-form-field>
            </div>
            <div>
            <mat-form-field appearance="fill" class="px-0  w-100">
              <mat-label> Forecasted Epic Cost </mat-label>
              <input matInput formControlName="forecastedEpicCost" readonly value="{{epic.forecastedEpicCost}}" anmsCurrencyFormatter>
              <mat-hint>Forecasted Epic Cost</mat-hint>

            </mat-form-field>
          </div>


          </div>

          <div class="mt-4 ml-2" *ngIf="epic$ | async as epics">

            <div class="row">
              <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Expected benefit</mat-label>
              <input matInput formControlName="expectedBenefit" anmsCurrencyFormatter>
              <mat-hint> Expected Benefit</mat-hint>
              <mat-error *ngIf="form.get('expectedBenefit').errors?.required">
                {{ 'Benefit is required' | translate }}
              </mat-error>
              <mat-error *ngIf="form.get('expectedBenefit').invalid">
                {{ 'The Benefit must be greater than 0 € ' | translate }}

              </mat-error>
              <mat-error *ngIf="form.get('expectedBenefit').errors?.errorNaN">
                {{ 'It must be a number'}}

              </mat-error>

            </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="mb-5 px-0" >
              <mat-label> Allocated Budget </mat-label>
              <input matInput formControlName="allocatedBudget" anmsCurrencyFormatter>
              <mat-hint>Allocated budget</mat-hint>
              <mat-error *ngIf="form.get('allocatedBudget').errors?.required">
                {{ 'Allocated Budget is required' | translate }}
              </mat-error>
              <mat-error *ngIf="form.get('allocatedBudget').hasError('min')">
                It must be greater than € 0

              </mat-error>

              <mat-error *ngIf="form.get('allocatedBudget').hasError('max')">
                Allocated budget must be equal to or less than the available budget of the Value Stream
              </mat-error>

            </mat-form-field>
              </div>
              <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="mb-5 px-0">
              <mat-label>Actual cost </mat-label>
              <input matInput formControlName="actualCost" anmsCurrencyFormatter>
              <mat-hint>The spent budget</mat-hint>
              <mat-error *ngIf="form.get('actualCost').errors?.required">
                {{ 'Actual cost is required' | translate }}
              </mat-error>
              <mat-error *ngIf="form.get('actualCost').invalid">
                It cannot be a negative number
              </mat-error>
            </mat-form-field>
            </div>

            <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Estimate at completion</mat-label>
              <input matInput formControlName="estimatedAtCompletion" anmsCurrencyFormatter>
              <mat-hint>The estimated cost at completion</mat-hint>
              <mat-error *ngIf="form.get('estimatedAtCompletion').invalid">
                {{ 'Final cost must be greater than € 0 ' | translate }}
              </mat-error>


            </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Estimate total final cost</mat-label>
              <input matInput formControlName="estimatedFinalCost" anmsCurrencyFormatter>
              <mat-hint>The estimated final cost</mat-hint>
              <mat-error *ngIf="form.get('estimatedFinalCost').invalid">
                {{ 'Final cost must be greater than € 0 ' | translate }}
              </mat-error>


            </mat-form-field>
            </div>
            <div class="col-lg-6 col-12">
            <mat-form-field appearance="none" class="px-0"  *ngIf="solutions.length > 0">
              <mat-label>Products or Solutions</mat-label>
              <mat-chip-list aria-label="Fish selection" formControlName="producOrSolution">
                  <mat-chip *ngFor="let solution of this.solutions" [matTooltip]="solution">{{solution}}</mat-chip>
                </mat-chip-list>
            </mat-form-field>
              </div>
              </div>



          </div>

        </div>

      </ng-container>

      </div>
      <div class="form-actions">
        <div class="action-col secondary">
          <button (click)="closeDialog()" mat-raised-button color="primary" type="button" class="btn-save"
            id="save"><span class="material-icons">close</span>Cancel</button>
        </div>
        <div class="action-col primary">
          <ng-container *ngIf="epic$ | async as epic">
          <button  mat-raised-button color="primary" type="submit" class="btn-save"
            id="saveNew"><span class="material-icons">check</span>Update</button>
            </ng-container>
        </div>


      </div>

    </form>
</div>
