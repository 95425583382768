import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'

export type NavItemType = 'solution' | 'portfolio' | 'art' | 'organization'

export interface NavItem {
  date?: number
  type?: NavItemType
  label: string
  link?: string
  portfolio?: string
  children?: NavItem[]
  icon?: string
  organization?: string
  isroot?: boolean
  id?
}

export interface MenuState {
  items: NavItem[]
}

@Injectable({
  providedIn: 'root'
})
export class MenuStore extends ComponentStore<MenuState> {
  subMenuMaxItems = 3

  readonly items$: Observable<NavItem[]> = this.select((state) => state.items, {
    debounce: true
  })

  readonly solutions$: Observable<NavItem[]> = this.select(
    (state) =>
      state.items
        .filter((item) => item.type === 'solution')
        .filter((_, index) => index < this.subMenuMaxItems),
    {
      debounce: true
    }
  )

  readonly portfolios$: Observable<NavItem[]> = this.select(
    (state) =>
      state.items
        .filter((item) => item.type === 'portfolio')
        .filter((_, index) => index < this.subMenuMaxItems),
    {
      debounce: true
    }
  )

  readonly arts$: Observable<NavItem[]> = this.select(
    (state) =>
      state.items
        .filter((item) => item.type === 'art')
        .filter((_, index) => index < this.subMenuMaxItems),
    {
      debounce: true
    }
  )
  readonly organization$: Observable<NavItem[]> = this.select(
    (state) =>
      state.items
        .filter((item) => item.type === 'organization')
        .filter((_, index) => index < this.subMenuMaxItems),
    {
      debounce: true
    }
  )

  readonly addItem = this.updater((state, navItem: NavItem) => {
    if (state.items.filter((item) => item.id === navItem.id).length > 0) {
      state.items = state.items.filter((item) => item.id !== navItem.id)
    }

    const state1 = {
      items: [navItem, ...state.items]
    }

    localStorage.setItem('menuStoreData', JSON.stringify(state1))

    return state1
  })

  constructor() {
    super({ items: [] })
    this.loadStateFromLocalStorage()
  }
  // Add a method to load the state from local storage
  private loadStateFromLocalStorage() {
    const localStorageData = localStorage.getItem('menuStoreData')
    if (localStorageData) {
      try {
        const parsedData = JSON.parse(localStorageData)
        this.setState(parsedData)
      } catch (error) {
        console.error('Error parsing local storage data:', error)
      }
    }
  }
  // Add a method to save the state to local storage
  private saveStateToLocalStorage(state: MenuState) {
    localStorage.setItem('menuStoreData', JSON.stringify(state))
  }

  // const defaultMenu=this.service.getDefaultNavigationMenu()
  //  super({items:defaultMenu });
  // }
}
