import {
  Epic,
  Feature,
  FeatureI,
  KanbanItemI,
  StoryT
} from '../../features/requirements-store/store/requirements.model'

import { KanbanitemBase } from '../../features/requirements-store/store/item/requirement.model'
import { Task } from '../../features/portfolio-store/portfolio/Task'

export declare type KanbanType = 'program' | 'portfolio' | 'solutions' | 'team'

export interface Item {
  type: string
}
export declare interface TypedItem<T extends string> extends Item {
  readonly type: T
}

export declare type TypeId<T> = () => T
/**
 * A function that returns an object in the shape of the `Item` interface.  Configured using `createItem`.
 */
export declare type Creator<
  P extends any[] = any[],
  R extends object = object
> = FunctionWithParametersType<P, R>
export declare type ItemCreator<
  T extends string = string,
  C extends Creator = Creator
> = C & TypedItem<T>
export declare type ItemType<A> = A extends ItemCreator<infer T, infer C>
  ? ReturnType<C> & {
      type: T
    }
  : never
export declare type FunctionWithParametersType<
  P extends unknown[],
  R = void
> = (...args: P) => R

export interface KanbanDTO {
  type: string
  name: string
  portfolioId?: string
  teamId?: string
  artId?: string
}

export interface ColumnI {
  id: string
}

export interface KanbanColumn<T extends KanbanitemBase> extends ColumnI {
  [x: string]: any

  /**
   * Text used as a tooltip in column display
   */
  readonly description: string
  /**
   * Text displayed as a name of column
   */
  readonly name: string

  /**
   * List of items
   */
  items?: T[]

  /**
   * List of pending tasks
   */
  tasks?: Task[]
  /**
   * List of connected lists identifiers
   */
  dropData?: string[]
}
export interface ExtendedMethods<T extends KanbanitemBase> {
  setTask(tasks: Task[]): void
  setDropData(dropData: string[]): void
  getCleanID(): string
  setItems(items: T[]): void
}

export class Column<T extends KanbanItemI>
  implements KanbanColumn<KanbanItemI>, ExtendedMethods<KanbanItemI> {
  id: string
  description: string
  name: string
  items?: T[]
  tasks?: Task[]
  dropData?: string[]
  constructor({
    id,
    description,
    name,
    items,
    tasks,
    dropData
  }: KanbanColumn<KanbanItemI>) {
    Object.assign(this, {
      id,
      description,
      name,
      items,
      tasks,
      dropData
    })
  }

  setTask(tasks: Task[]): void {
    this.tasks = tasks
    // throw new Error('Method not implemented.');
  }
  setDropData(dropData: string[]) {
    this.dropData = dropData
  }
  getCleanID(): string {
    return this.name.replace(/\W/g, '_')
    throw new Error('Method not implemented.')
  }
  setItems(items: T[]) {
    this.items = items
  }
}
export interface KanbanI<T extends KanbanItemI> {
  readonly id: string
  /**
   * Kanban name
   */
  readonly name: string

  /**
   * List of columns of this Kanban
   */
  readonly columns: KanbanColumn<T>[]

  /**
   * Kanban type: program, portfolio, solutions, team
   */
  readonly type: KanbanType

  /**
   * Identifier of the first column
   */
  readonly initialcolumnId: string
}

export type ProgramKanban = KanbanI<Feature>
export type PortfolioKanban = KanbanI<Epic>
export type TeamKanban = KanbanI<StoryT>

export type UnifiedKanbanItem = Feature | Epic | StoryT
export type UnifiedKanbanColumn = KanbanColumn<UnifiedKanbanItem>

export type Kanban = ProgramKanban | PortfolioKanban | TeamKanban

export type KanbanWithLoaded = Kanban & {
  loaded: boolean
}

export function isProgramKanban(item: Kanban): item is ProgramKanban {
  return item.type === 'program'
}
export function isTeamKanban(item: Kanban): item is TeamKanban {
  return item.type === 'team'
}
export function isPortfolioKanban(item: Kanban): item is PortfolioKanban {
  return item.type === 'portfolio'
}

export class KanbanC implements KanbanI<KanbanItemI> {
  id: string
  /**
   * Kanban name
   */
  name: string

  /**
   * List of columns of this Kanban
   */
  columns: KanbanColumn<KanbanItemI>[]

  /**
   * Kanban type: program, portfolio, solutions, team
   */
  type: KanbanType

  /**
   * Identifier of the first column
   */
  initialcolumnId: string

  portfolioId?

  constructor(
    id: string,
    type: KanbanType,
    name: string,
    columns: KanbanColumn<KanbanItemI>[],
    portfolioId
  ) {
    this.id = id
    this.type = type
    this.name = name
    this.columns = columns
    this.portfolioId = portfolioId
  }
}

export type KanbanColumnConstructor = new <T>(
  id: string,
  description: string,
  name: string,
  tasks?: Task[],
  dropData?: string[],
  items?: KanbanitemBase[]
) => T

export type TaskCreator = new (
  id: string,
  name: string,
  description: string,
  status: number,
  idColum: string
) => Task
export function createColumn<T>(
  ktor: KanbanColumnConstructor,
  id: string,
  description: string,
  name: string,
  dropData?: string[],
  tasks?: Task[],
  items?: KanbanitemBase[]
): T {
  return new ktor(id, description, name, tasks, dropData, items)
}
export function createTask(
  ktor: TaskCreator,
  id: string,
  name: string,
  description: string,
  status: number,
  idColumn: string
): Task {
  return new ktor(id, name, description, status, idColumn)
}
