import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Entry, EntryRequest, PortfolioSafe } from './portfolio-safe.model'

export const loadPortfolioSafes = createAction(
  '[PortfolioSafe/API] Load PortfolioSafes'
)
export const loadPortfolioSafe = createAction(
  '[PortfolioSafe/API] Load PortfolioSAFE',
  props<{ id: string }>()
)
export const loadPortfolioSafeDashboard = createAction(
  '[PortfolioSafe/API] Load PortfolioSAFE Dashboard',
  props<{ id }>()
)
export const loadCanvasPortfolioSafe = createAction(
  '[PortfolioSafe/API] Load Canvas PortfolioSAFE',
  props<{ id: string }>()
)

export const successLoadPortfolioSafe = createAction(
  '[PortfolioSafe/API] Success Load PortfolioSAFE',
  props<{ portfolioSafe: PortfolioSafe }>()
)

export const successLoadPortfolioSafes = createAction(
  '[PortfolioSafe/API] Success Load PortfolioSafes',
  props<{ portfolioSafes: PortfolioSafe[] }>()
)
export const successLoadPortfolioSafeSet = createAction(
  '[PortfolioSafe/API] Success Load PortfolioSafe Set',
  props<{ portfolioSafes: PortfolioSafe[] }>()
)
export const addPortfolioSafe = createAction(
  '[PortfolioSafe/API] Add PortfolioSafe',
  props<{ portfolioSafe: PortfolioSafe }>()
)
export const successAddPortfolioSafe = createAction(
  '[PortfolioSafe/API] Success Add PortfolioSafe',
  props<{ portfolioSafe: PortfolioSafe }>()
)

export const upsertPortfolioSafe = createAction(
  '[PortfolioSafe/API] Upsert PortfolioSafe',
  props<{ portfolioSafe: PortfolioSafe }>()
)

export const addPortfolioSafes = createAction(
  '[PortfolioSafe/API] Add PortfolioSafes',
  props<{ portfolioSafes: PortfolioSafe[] }>()
)

export const upsertPortfolioSafes = createAction(
  '[PortfolioSafe/API] Upsert PortfolioSafes',
  props<{ portfolioSafes: PortfolioSafe[] }>()
)

export const updatePortfolioSafe = createAction(
  '[PortfolioSafe/API] Update PortfolioSafe',
  props<{ portfolioSafe: Update<PortfolioSafe> }>()
)
export const successUpdatePortfolioSafe = createAction(
  '[PortfolioSafe/API] Success Update PortfolioSafe',
  props<{ portfolioSafe: PortfolioSafe }>()
)
export const patchPortfolioSafe = createAction(
  '[PortfolioSafe/API] Patch PortfolioSafe',
  props<{ portfolioSafe: Update<PortfolioSafe> }>()
)
export const successPatchPortfolioSafe = createAction(
  '[PortfolioSafe/API] Success Patch PortfolioSafe',
  props<{ portfolioSafe: PortfolioSafe }>()
)

export const updatePortfolioSafes = createAction(
  '[PortfolioSafe/API] Update PortfolioSafes',
  props<{ portfolioSafes: Update<PortfolioSafe>[] }>()
)

export const deletePortfolioSafe = createAction(
  '[PortfolioSafe/API] Delete PortfolioSafe',
  props<{ id: string }>()
)
export const successDeletePortfolioSafe = createAction(
  '[PortfolioSafe/API] Success Delete PortfolioSafe',
  props<{ id: string }>()
)

export const deletePortfolioSafes = createAction(
  '[PortfolioSafe/API] Delete PortfolioSafes',
  props<{ ids: string[] }>()
)

export const clearPortfolioSafes = createAction(
  '[PortfolioSafe/API] Clear PortfolioSafes'
)
export const setPortfolioSAFE = createAction(
  '[PortfolioSafe/API] Set PortfolioSafe',
  props<{ selectedPortfolioId: string }>()
)
export const addEntryPortfolioSAFE = createAction(
  '[PortfolioSafe/API] Add Entry',
  props<{ entry: EntryRequest; id: string }>()
)
export const removeEntryPortfolioSAFE = createAction(
  '[PortfolioSafe/API] Remove Entry',
  props<{ entry: EntryRequest; portfolioId: string }>()
)
export const successAddEntryPortfolioSAFE = createAction(
  '[PortfolioSafe/API] Success AddEntry PortfolioSafe',
  props<{ portfolioSafe: PortfolioSafe }>()
)
export const error = createAction(
  '[PortfolioSafe/API] Error PortfolioSafe',
  props<{ error }>()
)
