import { createSelector } from '@ngrx/store'
import { selectFeatures, FeaturesState } from '../../features.state'
import { adapter } from './kpis.reducer'

export const selectKPIs = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.kpis
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectKPIs)

export const getKPIById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])
