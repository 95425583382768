<!-- cdkDrag cdkDragRootElement=".cdk-overlay-pane"-->
<div [@mobileDialogAnimation]>
  <div [ngClass]="useMobileAnimation? 'd1':'d1-desktop'">

<div class="overlay background">

  <div class="close-form" style="cursor: pointer;display: flex; justify-content: center; align-items: center;" (click)="closeDialog()">
    <fa-icon icon="times"  aria-label="Close" [matTooltip]="'Close'"
    matTooltipPosition="above"></fa-icon>
  </div>

  <div class="dialog-container gradient">
    <ng-container [ngSwitch]="renderMethod">

      <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>

    </ng-container>
  </div>
</div>
</div>

</div>

