import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { authSuccessLogout } from '../../../core/auth/auth.actions'
import { AuthResponse } from '../../../core/auth/auth.model'
import { authLogout } from '../../../core/core.module'
import { isIntegrationEnviroment } from '../helpers/isIntegration'

@Component({
  selector: 'anms-logout',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {
  constructor(private store: Store) {
    // const response: AuthResponse = {
    //   access_token: null,
    //   expiresIn: null,
    //   exp: null,
    //   issuedDate: null,
    //   name: null,
    //   username: null,
    //   id: null,
    //   isAuthenticated: false
    // }
  }
  ngOnInit(): void {
    this.store.dispatch(authSuccessLogout())
  }
}
