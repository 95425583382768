import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import {
  OrganizationMember,
  OrganizationMemberWithUser
} from '../../features/organization-store/store/organization-member.model'
import { OverlayFormRef } from '../../overlay-forms/overlay/overlay-form-ref'
import * as fromActionsKanbanItems from '../../features/requirements-store/store/item/requirement.actions'
import * as fromFeatureSelectors from '../../features/feature-selectors'
import * as fromPortofolioSelectors from '../../features/portfolio-store/portfolio/portfolio-safe.selectors'
import {
  EconomicalData,
  Epic,
  RequirementForBudget,
  RequirementI
} from '../../features/requirements-store/store/requirements.model'
import { User } from '../../features/user-store/user.model'
import * as fromUsersSelectors from '../../features/user-store/user.selectors'
import { mergeMap, switchMap } from 'rxjs/operators'
import { numberValidator } from '../form-custom-validators/number-validator'

interface Budget {
  sumOfStreams: number
  availableBudget: number
}

@Component({
  selector: 'anms-budget-information-form',
  templateUrl: './budget-information-form.component.html',
  styleUrls: ['./budget-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetInformationFormComponent implements OnInit {
  epic$: Observable<RequirementForBudget>
  epicsImplementinAndDone$: Observable<RequirementForBudget[]>
  form: FormGroup

  scope
  owners$: Observable<User[]>
  subscription: Subscription
  solutions = []
  totalAndAvailableBudget$: Observable<Budget>

  epicOwners$: Observable<OrganizationMemberWithUser[]>

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: OverlayFormRef,
    private ref: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      id: '',
      forecastedEpicCost: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0)]
      ],
      allocatedBudget: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0)]
      ],
      actualCost: ['', [Validators.pattern('^[0-9]*$'), Validators.min(0)]],
      producOrSolution: [''],
      estimatedAtCompletion: [
        '',
        [Validators.pattern('^[0-9]*$'), Validators.min(1)]
      ],
      estimatedFinalCost: [
        '',
        [Validators.pattern('^[0-9]*$'), Validators.min(1)]
      ],
      expectedBenefit: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          numberValidator
        ]
      ],
      epicOwnerId: ''
    })
  }

  ngOnInit(): void {
    this.store.select(fromUsersSelectors.selectAll)
    this.owners$ = this.store.select(fromUsersSelectors.selectAll)

    if ('itemId' in this.dialogRef.data) {
      const { itemId } = this.dialogRef.data

      this.form.get('id').setValue(itemId)

      this.epic$ = this.store.select(
        fromFeatureSelectors.selectEpicByIdForBudget(itemId)
      )

      this.subscription = this.epic$.subscribe((item) => {
        this.form.get('forecastedEpicCost').setValue(item.forecastedEpicCost)

        if (item.valueStreamObjects.length > 0) {
          this.scope = item.valueStreamObjects?.map((v) => v.name).join(', ')
        } else {
          this.scope = 'There is no Values Stream related to this Epic'
        }
        if (item.expectedBenefit) {
          this.form.get('expectedBenefit').patchValue(item.expectedBenefit)
        }
        if (item.economicalData) {
          this.form.patchValue(item.economicalData)
        }
        if (item.valueStreamObjects) {
          this.solutions = []
          for (const key in item.valueStreamObjects) {
            if (item.valueStreamObjects[key].solutions) {
              item.valueStreamObjects[key].solutionsList.forEach((element) => {
                this.solutions.push(element.name)
              })
            }
          }
        }

        this.form.get('epicOwnerId').setValue(item.epicOwnerId)
      })

      // creo que este codigo no hace falta ???
      /* this.epicsImplementinAndDone$ = this.epic$.pipe(
        mergeMap((item) =>
          this.store.select(
            featureSelectors.selectEpicsByKeyResultId(item.keyResult?.id)
          )
        )
      ) */

      this.epicOwners$ = this.epic$.pipe(
        mergeMap((it) =>
          this.store
            .select(
              fromPortofolioSelectors.getPortfolioSafeById(it.portfolioId)
            )
            .pipe(
              mergeMap((portfolio) =>
                this.store.select(
                  fromFeatureSelectors.getMembersList(portfolio.organizationId)
                )
              )
            )
        )
      )

      this.totalAndAvailableBudget$ = this.epic$.pipe(
        switchMap((item) => {
          const vStreamsIds = item.valueStreamObjects.map((v) => v.id)
          return this.store.select(
            fromFeatureSelectors.totaBudgetOfValuestreamsFromActiveStrategicsAndAvailableBudget(
              vStreamsIds,
              item.economicalData?.allocatedBudget
            )
          )
        })
      )

      this.totalAndAvailableBudget$.subscribe((val: Budget) => {
        this.form
          .get('allocatedBudget')
          .setValidators(Validators.max(Number(val.sumOfStreams)))
      })
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }

  addEconomicalData() {
    if (this.form.valid) {
      const item = this.form.getRawValue()
      const economicalData: EconomicalData = {
        id: item.id,
        actualCost:
          this.form.get('actualCost').value !== '' &&
          this.form.get('actualCost').value !== null
            ? this.form.get('actualCost').value
            : item.economicalData?.actualCost !== null
            ? item.economicalData?.actualCost
            : null,
        allocatedBudget:
          this.form.get('allocatedBudget').value !== '' &&
          this.form.get('allocatedBudget').value !== null
            ? this.form.get('allocatedBudget').value
            : item.economicalData?.allocatedBudget !== null
            ? item.economicalData?.allocatedBudget
            : null,
        estimatedAtCompletion:
          this.form.get('estimatedAtCompletion').value !== '' &&
          this.form.get('estimatedAtCompletion').value !== null
            ? this.form.get('estimatedAtCompletion').value
            : item.economicalData?.estimatedAtCompletion !== null
            ? item.economicalData?.estimatedAtCompletion
            : 0,
        estimatedFinalCost:
          this.form.get('estimatedFinalCost').value !== '' &&
          this.form.get('estimatedFinalCost').value !== null
            ? this.form.get('estimatedFinalCost').value
            : item.economicalData?.estimatedFinalCost !== null
            ? item.economicalData?.estimatedFinalCost
            : 0
      }

      const kanbanItem: Update<RequirementI> = {
        id: item.id,
        changes: { economicalData: economicalData }
      }
      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: kanbanItem })
      )

      if (
        this.form.get('forecastedEpicCost').value !== '' &&
        this.form.get('forecastedEpicCost').value !== null
      ) {
        // eslint-disable-next-line no-shadow
        const kanbanItem: Update<RequirementI> = {
          id: item.id,
          changes: {
            forecastedEpicCost: this.form.get('forecastedEpicCost').value
          }
        }
        this.store.dispatch(
          fromActionsKanbanItems.patchKanbanitem({ kanbanitem: kanbanItem })
        )
      }
      if (
        this.form.get('expectedBenefit').value !== '' &&
        this.form.get('expectedBenefit').value !== null
      ) {
        // eslint-disable-next-line no-shadow
        const kanbanItem: Update<RequirementI> = {
          id: item.id,
          changes: {
            expectedBenefit: this.form.get('expectedBenefit').value
          }
        }
        this.store.dispatch(
          fromActionsKanbanItems.patchKanbanitem({ kanbanitem: kanbanItem })
        )
      }
    } else {
      this.validateAllFormFields(this.form)
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    // {1}
    Object.keys(formGroup.controls).forEach((field) => {
      // {2}
      const control = formGroup.get(field) // {3}
      if (control instanceof FormControl) {
        // {4}
        control.markAsTouched({ onlySelf: true })
      } else if (control instanceof FormGroup) {
        // {5}
        this.validateAllFormFields(control) // {6}
      }
    })
  }

  updateEpicOwner(epicId, value) {
    const update: Update<RequirementI> = {
      id: epicId,
      changes: { epicOwnerId: value }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }
}
