import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { Kanban, KanbanDTO } from './kanban.model'
import { KanbanitemBase } from '../../features/requirements-store/store/item/requirement.model'

export const loadKanbans = createAction('[Kanban/API] Load Kanbans')

export const successLoadKanbans = createAction(
  '[Kanban/API] Success Load Kanbans',
  props<{ kanbans: Kanban[] }>()
)
export const loadKanban = createAction(
  '[Kanban/API] Load Kanban',
  props<{ id: string }>()
)
export const successLoadKanban = createAction(
  '[Kanban/API] Success Load Kanban',
  props<{ kanban: Kanban }>()
)

export const addKanban = createAction(
  '[Kanban/API] Add Kanban',
  props<{ kanban: KanbanDTO }>()
)
export const successAddKanban = createAction(
  '[Kanban/API] Success Add Kanban',
  props<{ kanban: Kanban }>()
)

export const upsertKanban = createAction(
  '[Kanban/API] Upsert Kanban',
  props<{ kanban: Kanban }>()
)

export const addKanbans = createAction(
  '[Kanban/API] Add Kanbans',
  props<{ kanbans: Kanban[] }>()
)

export const upsertKanbans = createAction(
  '[Kanban/API] Upsert Kanbans',
  props<{ kanbans: Kanban[] }>()
)

export const updateKanban = createAction(
  '[Kanban/API] Update Kanban',
  props<{ kanban: Update<Kanban> }>()
)

export const updateKanbans = createAction(
  '[Kanban/API] Update Kanbans',
  props<{ kanbans: Update<Kanban>[] }>()
)

export const deleteKanban = createAction(
  '[Kanban/API] Delete Kanban',
  props<{ id: string }>()
)

export const deleteKanbans = createAction(
  '[Kanban/API] Delete Kanbans',
  props<{ ids: string[] }>()
)
export const addColumn = createAction(
  '[Kanban/API] Add Column',
  props<{ kanbanId: string; columnName: string }>()
)
export const editColumnName = createAction(
  '[Kanban/API] Edit Column Name',
  props<{ kanbanId: string; columnId: string; columnName: string }>()
)
export const removeColumn = createAction(
  '[Kanban/API] Remove Column',
  props<{ kanbanId: string; columnId: string }>()
)
export const addColumnSucess = createAction(
  '[Kanban/API] Add Column Success',
  props<{ kanban: Kanban }>()
)
export const errorLoading = createAction(
  '[Kanban/API] Error Loading Kanban',
  props<{ error: any }>()
)

export const clearKanbans = createAction('[Kanban/API] Clear Kanbans')
