import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { PortfolioSafe } from './portfolio-safe.model'
import * as PortfolioSafeActions from './portfolio-safe.actions'

export const portfoliosFeatureKey = 'portfolios'

export interface PortfolioState extends EntityState<PortfolioSafe> {
  // additional entities state properties
  selectedPortfolioId: string | null
}

export const adapter: EntityAdapter<PortfolioSafe> = createEntityAdapter<PortfolioSafe>()

export const initialState: PortfolioState = adapter.getInitialState({
  selectedPortfolioId: null,
  ids: ['1', '2'],
  entities: {
    '1': {
      id: '-',
      name: 'Portfolio One',
      description: 'Portfolio Description',
      ownerId: '1'
    },
    '2': {
      id: '2',
      name: 'Portfolio Two',
      description: 'Portfolio Two Description',
      ownerId: '1'
    }
  }
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  // on(PortfolioSafeActions.addPortfolioSafe, (state, action) =>
  //   adapter.addOne(action.portfolioSafe, state)
  // ),

  on(PortfolioSafeActions.successAddPortfolioSafe, (state, action) =>
    adapter.addOne(action.portfolioSafe, state)
  ),
  on(PortfolioSafeActions.successLoadPortfolioSafe, (state, action) =>
    adapter.upsertOne(action.portfolioSafe, state)
  ),
  /*
  on(PortfolioSafeActions.upsertPortfolioSafe, (state, action) =>
    adapter.upsertOne(action.portfolioSafe, state)
  ),
  */

  on(PortfolioSafeActions.upsertPortfolioSafes, (state, action) =>
    adapter.upsertMany(action.portfolioSafes, state)
  ),
  on(PortfolioSafeActions.successUpdatePortfolioSafe, (state, action) =>
    adapter.upsertOne(action.portfolioSafe, state)
  ),

  on(PortfolioSafeActions.successPatchPortfolioSafe, (state, action) =>
    adapter.upsertOne(action.portfolioSafe, state)
  ),
  on(PortfolioSafeActions.updatePortfolioSafes, (state, action) =>
    adapter.updateMany(action.portfolioSafes, state)
  ),

  on(PortfolioSafeActions.successDeletePortfolioSafe, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(PortfolioSafeActions.deletePortfolioSafes, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(PortfolioSafeActions.successLoadPortfolioSafes, (state, action) =>
    adapter.setAll(action.portfolioSafes, state)
  ),
  on(PortfolioSafeActions.successLoadPortfolioSafeSet, (state, action) =>
    adapter.upsertMany(action.portfolioSafes, state)
  ),

  on(
    PortfolioSafeActions.setPortfolioSAFE,
    (state, action): PortfolioState => ({
      ...state,
      selectedPortfolioId: action.selectedPortfolioId
    })
  ),
  on(PortfolioSafeActions.clearPortfolioSafes, (state) =>
    adapter.removeAll(state)
  )
)

export function portfolioSAFEReducer(
  state: PortfolioState | undefined,
  action: Action
) {
  return reducer(state, action)
}
