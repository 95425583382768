import { createFeatureSelector, createSelector } from '@ngrx/store'
// import { State5 } from './reducers'
import { adapter, UserState, usersFeatureKey } from './user.reducer'
import { FeaturesState, selectFeatures } from '../features.state'

/*
export const selectUsers = createFeatureSelector<State5, State>(usersFeatureKey)
*/
export const selectUsers = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.users
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectUsers)

export const getUserByIdFactory = (id) =>
  createSelector(selectEntities, (entities) => entities[id])
