import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { KPIInstance } from '../kpis/kpi.model'

export const loadKPIInstances = createAction('[KPI/API] Load KPIInstances')

export const successLoadKPIInstances = createAction(
  '[KPI Instance/API] Success Load KPIInstances',
  props<{ kpiInstances: KPIInstance[] }>()
)

export const addKPIInstance = createAction(
  '[KPI Instance/API] Add KPI Instance',
  props<{ kpiInstance: KPIInstance }>()
)

export const updateKPIInstance = createAction(
  '[KPI Instance/API] Update KPI Instance',
  props<{ kpiInstance: Update<KPIInstance> }>()
)

export const patchKPIInstance = createAction(
  '[KPI Instance/API] Patch KPI Instance',
  props<{ kpiInstanceId; kpiInstance: Update<KPIInstance> }>()
)

export const deleteKPIInstance = createAction(
  '[KPI Instance/API] Delete KPI Instance',
  props<{ id: string }>()
)

export const successAddKPIInstance = createAction(
  '[KPI Instance/API] Success Add KPI Instance',
  props<{ kpiInstance: KPIInstance }>()
)

export const successLoadKPIInstance = createAction(
  '[KPI Instance/API] Success Load KPI  Instance',
  props<{ kpiInstance: KPIInstance }>()
)

export const successDeleteKPIInstance = createAction(
  '[KPI Instance/API] Success Delete KPI Instance',
  props<{ id }>()
)

export const successPatchKPIInstance = createAction(
  '[KPI Instance/API] Success Patch KPI Instance',
  props<{ kpiInstance: KPIInstance }>()
)
export const successPutKPIInstance = createAction(
  '[KPI Instance/API] Success Put KPI Instance',
  props<{ kpiInstance: KPIInstance }>()
)

export const error = createAction(
  '[KPI Instance/API] Error KPI Instance petition',
  props<{ error }>()
)
