import { createAction, props } from '@ngrx/store'
import { AuthResponse } from './auth.model'

export const successLogin = createAction(
  '[Auth] Success Login',
  props<{ response: AuthResponse }>()
)
export const successRefreshToken = createAction(
  '[Auth] Success Refresh Token',
  props<{ response: AuthResponse }>()
)
export const refreshToken = createAction('[Auth] Refresh Token')

export const failedRenewToken = createAction(
  '[Auth] Failed Renew Token',
  props<{ error: string }>()
)
export const loginFailure = createAction(
  '[Auth] Failed Login',
  props<{ error: any }>()
)

export const authLogin = createAction(
  '[Auth] Login',
  props<{ username: string; password: string }>()
)

// export const authCheck = createAction('[Auth] Auth Check')

// export const authLogin = createAction('[Auth] Login');
export const authLogout = createAction('[Auth] Logout')
export const authSuccessLogout = createAction('[Auth] Success Logout')
export const keep = createAction(
  '[Auth] Keep the same response to avoid changes in the localstorage and unexpected reloads'
)

export const resendVerificationTicket = createAction(
  '[Authentication/API] Resent Verification Ticket',
  props<{
    email: string
  }>()
)

export const successResendInviteOrganizationMember = createAction(
  '[Authentication/API] Success Resend Verification Ticket',
  props<{ response: any }>()
)
export const error = createAction(
  '[Authentication/API] Error',
  props<{ error }>()
)
