
  <p class="recent-label"><span class="material-icons">schedule</span> Most Recent</p>
  <!--p *ngFor="let item of items">
    <anms-menu-item [item]=item></anms-menu-item>
  </p-->
  <ng-container *ngIf="items$ | async as items1">
    <p *ngFor="let item1 of items1">
      <anms-menu-item (clickMenuEvent)="onClickMenu($event)" [item]=item1></anms-menu-item>
    </p>
  </ng-container>


