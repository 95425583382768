import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'
import { Injectable, NgZone } from '@angular/core'
import {
  MatDrawer,
  MatDrawerToggleResult,
  MatSidenav
} from '@angular/material/sidenav'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

export type ServiceToggleMode = 'backdrop' | 'click'

export type SidenavState = 'collapsed' | 'expanded'

export interface ServiceToogle {
  /**
   * @deprecated Use state1 instead.
   */
  state: boolean
  state1: SidenavState
  mode?: ServiceToggleMode
}

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private serviceStateSubject: BehaviorSubject<ServiceToogle> = new BehaviorSubject<ServiceToogle>(
    {
      state: false,
      state1: 'collapsed'
    }
  )
  private serviceState$ = this.serviceStateSubject.asObservable()

  constructor() {}

  toggle(): void {
    // Get the current value
    const currentValue: ServiceToogle = this.serviceStateSubject.getValue()

    // Toggle the value
    const newValue = !currentValue.state
    const newState1 =
      currentValue.state1 === 'expanded' ? 'collapsed' : 'expanded'

    // Update the BehaviorSubject with the new value
    this.serviceStateSubject.next({ state: newValue, state1: newState1 })
  }
  close(): void {
    // Update the BehaviorSubject with the new value
    this.serviceStateSubject.next({ state: false, state1: 'collapsed' })
  }
  closeFromBackDrop(): void {
    // Update the BehaviorSubject with the new value
    this.serviceStateSubject.next({
      state: false,
      mode: 'backdrop',
      state1: 'collapsed'
    })
  }
  open(): void {
    const currentValue: ServiceToogle = this.serviceStateSubject.getValue()
    if (currentValue.state1 !== 'expanded') {
      // Update the BehaviorSubject with the new value
      this.serviceStateSubject.next({ state: true, state1: 'expanded' })
    }
  }
  getServiceState$() {
    return this.serviceState$
  }
}
