import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector,
  createSelector
} from '@ngrx/store'
import * as fromRouter from '@ngrx/router-store'
import { environment } from '../../environments/environment'

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer'
import { debug } from './meta-reducers/debug.reducer'
import { AuthState } from './auth/auth.models'
import { authReducer } from './auth/auth.reducer'
import { RouterStateUrl } from './router/router.state'
import { settingsReducer } from './settings/settings.reducer'
import { SettingsState } from './settings/settings.model'
import { RouterReducerState } from '@ngrx/router-store'
import { Params } from '@angular/router'

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  settings: settingsReducer,
  router: fromRouter.routerReducer
}

export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage]

if (!environment.production) {
  if (!environment.test) {
    metaReducers.unshift(debug)
  }
}

export const selectAuthState = (state: AppState) => state.auth

/* export const selectSettingsState = createFeatureSelector<
  AppState,
  SettingsState
>('settings')
*/
// Define a selector to select the settings state from your feature state
export const selectSettingsState = (state: AppState) => state.settings

/* export const selectRouterState = createFeatureSelector<
  AppState,
  RouterReducerState<RouterStateUrl>
>('router');*/

export interface AppState {
  auth: AuthState
  settings: SettingsState
  // features:FeaturesState
  // router: RouterReducerState<RouterStateUrl>;
  router: fromRouter.RouterReducerState<any>
}
export const selectRouterState = createFeatureSelector<
  AppState,
  fromRouter.RouterReducerState<any>
>('router')

export const selectRouter = createFeatureSelector<RouterReducerState>('router')

export const selectRouteNestedParams = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root
    let params: Params = {}
    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild
      params = {
        ...params,
        ...currentRoute.params
      }
    }
    return params
  }
)

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param])
export const {
  selectCurrentRoute, // select the current route
  // selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url
} = fromRouter.getSelectors(selectRouterState)
