/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable ngrx/select-style */
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Store, select } from '@ngrx/store'

import { Observable, Subscription } from 'rxjs'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import * as fromPortofolioSelectors from '../../../../features/portfolio-store/portfolio/portfolio-safe.selectors'
import * as fromOrganizationMembers from '../../../../features/organization-store/store/organization-member.selectors'
import * as fromkanbanitemsSelectors from '../../../../features/requirements-store/store/item/requirement.selectors'
import {
  switchMap,
  tap,
  map,
  filter,
  distinctUntilChanged
} from 'rxjs/operators'

import {
  DoReady,
  FeatureI,
  RequirementI,
  StoryI,
  FeatureContext,
  Epic,
  LinkedFeature,
  Feature
} from '../../../../features/requirements-store/store/requirements.model'
import { ART, Id } from '../../../../features/arts-store/art.model'
import { KanbanItemService } from '../../../../features/requirements/services/kanban-item.service'
import { DynamicRequirementFormComponent } from '../dynamic-requirement-form/dynamic-requirement-form.component'
import { ProgramIncrement } from '../../../../features/program-increment-store/store/pi/program-increment.model'
import * as ProgramIncrementSelectors from '../../../../features/program-increment-store/store/pi/program-increment.selectors'
import { OverlayFormRef } from '../../../../overlay-forms/overlay/overlay-form-ref'
import * as fromARTSelectors from '../../../../features/arts-store/art.selectors'

import {
  Team,
  TeamWithKanban
} from '../../../../features/teams-store/store/team.model'
import {
  INIT_DATA1,
  OverlayService
} from '../../../../overlay-forms/overlay/overlay.service'
import { UserStoryFormComponent } from '../../user-story-form/user-story-form.component'
import { EnablerStoryFormComponent } from '../../enabler-story-form/enabler-story-form.component'
import * as kanbanItemActions from '../../../../features/requirements-store/store/item/requirement.actions'
import * as fromKanbanItemSelectors from '../../../../features/requirements-store/store/item/requirement.selectors'
import * as fromPortfolioSelectors from '../../../../features/portfolio-store/portfolio/portfolio-safe.selectors'

import {
  TranslateLoader,
  TranslatePipe,
  TranslateService,
  USE_STORE
} from '@ngx-translate/core'
import { Update } from '@ngrx/entity'
import * as fromRequirementsActions from '../../../../features/requirements-store/store/item/requirement.actions'
import * as fromKanbanSelectors from '../../../../kanban/store/kanban-selector'
import { SelectParentFormComponent } from '../select-parent-form/select-parent-form.component'
import {
  OrganizationMember,
  OrganizationMemberWithUser
} from '../../../../features/organization-store/store/organization-member.model'
import { numberValidator } from '../../../form-custom-validators/number-validator'
import { HistoryAudit } from '../../../../features/history-audit-store/history-audit.model'
import * as fromAuditSelectors from '../../../../features/history-audit-store/history-audit.selectors'
import { AttachMentResponse, File } from '../../../attachment/services/model'
import { AttachmentService } from '../../../attachment/services/attachment.service'
import { KanbanChangeFormComponent } from '../kanban-change-form/kanban-change-form.component'
import { MatSelect } from '@angular/material/select'
import { LinkFeaturesDynamicFormComponent } from '../link-features-dynamic-form/link-features-dynamic-form.component'
import * as fromFeatureSelectors from '../../../../features/feature-selectors'
import { Organization } from '../../../../features/organization-store/store/organization.model'
import {
  KeyResult,
  Objective,
  StrategicTheme,
  StrategicWithEpics
} from '../../../../features/strategic-themes-store/store/strategic-themes.model'
import { Kanban } from '../../../../kanban/store/kanban.model'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { environment } from '../../../../../environments/environment'
import { BreakDownFeatureFormComponent } from '../../../break-down-feature-form/break-down-feature-form.component'
import { Solution } from '../../../../features/solutions-store/store/solutions.model'

import { ThemeService } from 'ng2-charts'
import { AcceptanceCriteriaFormComponent } from '../../../ai/acceptance-criteria-form/acceptance-criteria-form.component'

interface FeatureType {
  viewValue: string
  value: string
}

interface FeatureDor {
  viewValue: string
  value: DoReady
}

interface FeatureContextList {
  viewValue: string
  value: FeatureContext
}

export interface TeamEvent {
  teamId: string
  storyId: string
  kanbanId?: string
  columnId?: string
}

export interface FeatureState {
  id: string
  name: string
}

export interface TeamIdStoryId {
  teamId: string
  storyId: string
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  )
}

@Component({
  selector: 'anms-feature-form',
  templateUrl: './feature-form.component.html',
  styleUrls: ['./feature-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TranslateService,
    { provide: USE_STORE, useValue: true },
    {
      provide: TranslatePipe,
      useFactory(service: TranslateService, ref: ChangeDetectorRef) {
        return new TranslatePipe(service, ref)
      },
      deps: [TranslateService, ChangeDetectorRef]
    },
    {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  ]
})
export class FeatureFormComponent implements OnInit, OnDestroy {
  @ViewChild('textArea') textAea: ElementRef
  @ViewChild('selectOptions') selectOptions: MatSelect
  @ViewChild('dynamic', {
    read: ViewContainerRef
  })
  viewContainerRef: ViewContainerRef
  @ViewChild('relatedDynamic', {
    read: ViewContainerRef
  })
  relatedDynamicRef: ViewContainerRef

  @ViewChild('autosize') autosize: CdkTextareaAutosize
  @ViewChild('autosize2') autosize2: CdkTextareaAutosize

  isUpdate = false
  epics$: Observable<RequirementI[]>

  showAddFeatureMessage = true

  showLinkFeatureMessage = true

  mentionsConfig: any

  private subscription: Subscription

  groupedProgramIncrements: {
    label: string
    programIncrements: ProgramIncrement[]
  }[] = []
  feature: FeatureI<StoryI>
  auditHistoryChanges$: Observable<HistoryAudit[]>
  componentRef: any
  childrenItemList$: Observable<RequirementI[]>

  usersList$: Observable<OrganizationMemberWithUser[]>

  owners$: Observable<OrganizationMemberWithUser[]>
  teamMates$: Observable<OrganizationMemberWithUser[]>

  form: FormGroup
  childTeamForm: FormGroup
  features: FormGroup

  // strategicThemes$: Observable<StrategicWithEpics[]>
  objectives$: Observable<StrategicTheme[]>

  solutions$: Observable<Solution[]>

  arrayOfChildrenWithTeams: TeamIdStoryId[] = []

  linkedFeatures$: Observable<LinkedFeature[]>
  parent$: Observable<RequirementI>

  featureTypes: FeatureType[] = [
    { value: 'business', viewValue: 'Business' },
    { value: 'enabler', viewValue: 'Enabler' }
  ]

  featureDor: FeatureDor[] = [
    // Trobar la forma de que això es generi automàtic (?)
    { value: 'inProgress', viewValue: 'In Progress' },
    { value: 'ready', viewValue: 'Ready' },
    { value: 'todo', viewValue: 'To do' }
  ]

  featureContextList: FeatureContextList[] = [
    { value: 'continuous improvement', viewValue: 'Continuous Improvement' },
    { value: 'technologic evolution', viewValue: 'Technologic Evolution' },
    { value: 'others', viewValue: 'Others' }
  ]

  formDirective: any
  pi$: Observable<ProgramIncrement[]>
  formTitle: string
  created: Date
  updated: Date

  currentArt$: Observable<ART>

  elTeam$: Observable<Team[]>
  storyTeam: Id[]
  teamsIds: Id[]
  executed = false
  relatedFeatureFormExecuted = false

  // Froala Options
  froalaOptions: Object = {
    placeholderText: this.translate.instant(
      'anms.feature.form.acceptanceCriteria.placeholder'
    ),
    charCounterCount: false,
    useClasses: true,
    htmlRemoveTags: [],
    attribution: false,
    heightMin: 200,
    maxHeight: 500,
    inlineMode: false,
    resize: true,
    zIndex: 2000
  }
  htmlTemplate = ''
  // initControls: any
  // editor: any
  disabledTeams = false
  states$: Observable<FeatureState[]>

  file = null
  filesOfItem: File[]
  newFile: File
  showRelatedFeaturesForm = true
  constructor(
    private resolver: ComponentFactoryResolver,
    private formBuilder: FormBuilder,
    private dialogRef: OverlayFormRef,
    private service: OverlayService,
    private store: Store,
    private translate: TranslateService,
    private itemService: KanbanItemService,
    // private service: FormsService
    private attachmentService: AttachmentService,
    @Inject(INIT_DATA1)
    public data: {
      portfolioId?
      feature?
      ownerId?
      artId?
      kanbanId?
      description?
      parentId?
    }
  ) {
    this.form = this.formBuilder.group({
      name: [''],
      description: ['', [Validators.required]],
      benefitHypothesis: ['', [Validators.required]],
      acceptanceCriteria: ['', [Validators.required]],
      id: '',
      parentId: '',
      solutionId: '',
      type: ['', [Validators.required]],
      ownerId: [''],
      programIncrementId: [''],
      columnId: '',
      kanbanId: '',
      toKanban: '',
      locked: '',
      itemName: '',
      // portfolioId: '',
      teams: '',
      context: '',
      dor: ['', [Validators.required]],
      state: '',
      artId: '',
      normalizedStoryPoints: ['', [numberValidator]],
      featureOwnerId: '',
      fileSource: [''],
      files: [''],
      notes: [''],
      relatedItemId: [''],
      relationType: [''],
      keyResultId: [''],
      isMvp: ['']
    })
    this.childTeamForm = this.formBuilder.group({
      childTeam: ['']
    })
  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe()
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: 'userstory',
      description: '',
      itemName: '',
      ownerId: null,
      context: ''
    })
  }
  get teams(): FormControl {
    return this.form.get('teams') as FormControl
  }

  get description(): FormControl {
    return this.form.get('description') as FormControl
  }
  get acceptanceCriteria(): FormControl {
    return this.form.get('acceptanceCriteria') as FormControl
  }

  ngOnInit(): void {
    this.form.reset()

    this.mentionsConfig = {
      items: [''], // Initially set data to empty array
      triggerChar: '@',
      mentionSelect: (item) =>
        // Customize the format of the selected mention
        `@'${item.label}'`
    }

    // FORCE NEW ARRAY  shallow copy  TO TRIGGER CHANGE DETECTION
    this.teams.valueChanges.subscribe((data) => (this.teamsIds = data.slice()))

    // retrieve all owners of the application ...
    this.owners$ = this.store
      .select(fromFeatureSelectors.getAllMembersList())
      .pipe(distinctUntilChanged())

    this.pi$ = this.store.select(ProgramIncrementSelectors.selectAllOrdered)

    this.subscription = this.pi$.subscribe((programIncrements) => {
      const currentDate = new Date()

      this.groupedProgramIncrements = [
        {
          label: 'Active',
          programIncrements: programIncrements.filter(
            (pi) =>
              currentDate >= new Date(pi.startDate) &&
              currentDate <= new Date(pi.endDate)
          )
        },
        {
          label: 'Future',
          programIncrements: programIncrements.filter(
            (pi) => currentDate < new Date(pi.startDate)
          )
        },
        {
          label: 'Recent',
          programIncrements: programIncrements.filter(
            (pi) => currentDate > new Date(pi.endDate)
          )
        }
      ]
    })

    // NEW FEATURE FROM PORTFOLIO
    if ('portfolioId' in this.data) {
      this.epics$ = this.store.select(
        fromkanbanitemsSelectors.getEpicsByPortfoliofactory(
          this.data.portfolioId
        )
      )

      this.solutions$ = this.store
        .select(
          fromPortfolioSelectors.getPortfolioSafeById(this.data.portfolioId)
        )
        .pipe(
          switchMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getSolutionsByOrganizationIdFilterByType(
                portfolio.organizationId,
                'business'
              )
            )
          )
        )

      // retrieve all team mates of this organization, based on portfolio

      this.teamMates$ = this.store
        .select(
          fromPortofolioSelectors.getPortfolioSafeById(this.data.portfolioId)
        )
        .pipe(
          filter((portfolio) => !!portfolio),
          switchMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getMembersList(portfolio.organizationId)
            )
          )
        )

      this.teamMates$.subscribe((teamMembers) => {
        this.mentionsConfig.items = teamMembers.map(
          (teamMember) => teamMember.user.name
        )
      })
    }

    // EDIT FEATURE
    if ('feature' in this.data) {
      this.feature = this.data.feature

      // IF FEATURE IS ASSIGNED TO A KANBAN
      if (this.feature.kanbanId) {
        this.objectives$ = this.store.pipe(
          // eslint-disable-next-line ngrx/select-style
          select(
            fromFeatureSelectors.selectOrganizationByKanbanId(
              this.feature.kanbanId
            )
          ),
          // JUST IN CASE EPICS ARE NOT YET LOADED
          filter((val) => !!val),
          switchMap((organization: Organization) =>
            this.store.select(
              fromFeatureSelectors.selectActiveStrategicThemesByOrganization(
                organization.id
              )
            )
          )
        )
        // IF FEATURE IS ASSIGNED TO A COLUMN
      } else if (this.feature.columnId) {
        this.objectives$ = this.store.pipe(
          select(
            fromKanbanSelectors.getKanbanByColumnId(this.feature.columnId)
          ),
          filter((val) => !!val),
          switchMap((kanban: Kanban) =>
            this.store.select(
              fromFeatureSelectors.selectOrganizationByKanbanId(kanban.id)
            )
          ),
          switchMap((organization: Organization) =>
            this.store.select(
              fromFeatureSelectors.selectActiveStrategicThemesByOrganization(
                organization.id
              )
            )
          )
        )

        // IF FEATURE HAS PARENT
      } else if (this.feature.parentId) {
        this.objectives$ = this.store.pipe(
          select(
            fromKanbanItemSelectors.getkanbanItemByIdFactory(
              this.feature.parentId
            )
          ),
          // JUST IN CASE EPICS ARE NOT YET LOADED
          filter((val) => !!val),
          map((item) => item.kanbanId),

          switchMap((kanbanId) =>
            this.store.select(
              fromFeatureSelectors.selectOrganizationByPortfolioKanbanId(
                kanbanId
              )
            )
          ),

          switchMap((organization: Organization) =>
            this.store.select(
              fromFeatureSelectors.selectActiveStrategicThemesByOrganization(
                organization.id
              )
            )
          )
        )
        this.parent$ = this.store.select(
          fromKanbanItemSelectors.getkanbanItemByIdFactory(
            this.feature.parentId
          )
        )
      }
      if (this.feature.id) {
        this.linkedFeatures$ = this.store.select(
          fromkanbanitemsSelectors.getRelatedFeaturesByFeatureId(
            this.feature.id
          )
        )

        this.store.dispatch(
          kanbanItemActions.loadChildrenKanbanitems({
            parentId: this.feature.id
          })
        )
        this.auditHistoryChanges$ = this.store.select(
          fromAuditSelectors.getHistoryAuditByRequirement(this.feature.id)
        )

        this.isUpdate = true
      }

      if (this.feature.files) {
        this.filesOfItem = this.feature.files
      } else {
        this.filesOfItem = []
      }

      this.formTitle = this.feature.description
      this.created = this.feature.creationDate
      this.updated = this.feature.lastUpdate

      this.form.get('state').setValue(this.feature.state)

      // LOAD ART AND STATE INFO
      if (this.feature.kanbanId || this.feature.columnId) {
        if (this.feature.kanbanId) {
          this.states$ = this.store.select(
            fromFeatureSelectors.getStatesAndColumnIdsByItemId(this.feature.id)
          )
          this.currentArt$ = this.store.pipe(
            select(
              fromFeatureSelectors.selectARTSWithTeamsByKanbanId(
                this.feature.kanbanId
              )
            ),
            filter((val) => !!val),
            tap((art) => {
              this.form.get('artId').setValue(art.id)
              this.form.get('artId').disable()
            })
          )

          this.solutions$ = this.currentArt$.pipe(
            switchMap((art) =>
              this.store.select(
                fromPortfolioSelectors.getPortfolioSafeById(art.portfolioId)
              )
            ),
            switchMap((portfolio) =>
              this.store.select(
                fromFeatureSelectors.getSolutionsByOrganizationIdFilterByType(
                  portfolio.organizationId,
                  'business'
                )
              )
            )
          )

          this.pi$ = this.currentArt$.pipe(
            switchMap((art) =>
              this.store.select(ProgramIncrementSelectors.getPIByARTId(art.id))
            ),
            filter((val) => !!val)
          )

          this.epics$ = this.currentArt$.pipe(
            switchMap((art) =>
              this.store.select(
                fromFeatureSelectors.selectEpicsAssignedToART(art.id)
              )
            ),
            filter((val) => !!val)
          )
        }
        if (this.feature.columnId) {
          this.states$ = this.store.pipe(
            select(
              fromKanbanSelectors.getKanbanByColumnId(this.feature.columnId)
            ),
            switchMap((kanban) =>
              this.store.select(
                fromKanbanSelectors.getStatesAndColumnIdsByKanbanId(kanban.id)
              )
            )
          )
          this.currentArt$ = this.store.pipe(
            select(
              fromKanbanSelectors.getKanbanByColumnId(this.feature.columnId)
            ),
            filter((val) => !!val),
            switchMap((kanban) =>
              this.store.select(
                fromFeatureSelectors.selectARTSWithTeamsByKanbanId(kanban.id)
              )
            ),
            filter((val) => !!val)
          )
          this.solutions$ = this.currentArt$.pipe(
            switchMap((art) =>
              this.store.select(
                fromPortfolioSelectors.getPortfolioSafeById(art.portfolioId)
              )
            ),
            switchMap((portfolio) =>
              this.store.select(
                fromFeatureSelectors.getSolutionsByOrganizationIdFilterByType(
                  portfolio.organizationId,
                  'business'
                )
              )
            )
          )

          this.currentArt$.subscribe((art) => {
            this.form.get('artId').setValue(art.id)
            this.form.get('artId').disable()
          })
          this.epics$ = this.currentArt$.pipe(
            switchMap((art) =>
              this.store.select(
                fromFeatureSelectors.selectEpicsAssignedToART(art.id)
              )
            ),
            filter((val) => !!val)
          )
        }
      } else {
        // NO COLUMN ID NOR KANBANID
        this.form.get('artId').setValue('')
        this.form.get('artId').disable()
      }

      // IF HAS PARENT
      if (this.feature.parentId) {
        this.form.get('parentId').setValue(this.feature.parentId)
        this.usersList$ = this.store.pipe(
          select(
            fromkanbanitemsSelectors.getkanbanItemByIdFactory(
              this.feature.parentId
            )
          ),
          filter((parent) => !!parent),
          switchMap((epic: Epic) =>
            this.store.select(
              fromPortofolioSelectors.getPortfolioSafeById(epic.portfolioId)
            )
          ),
          switchMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getMembersList(portfolio.organizationId)
            )
          )
        )

        this.epics$ = this.store.pipe(
          select(
            fromkanbanitemsSelectors.getkanbanItemByIdFactory(
              this.feature.parentId
            )
          ),
          filter((parent) => !!parent),
          switchMap((epic: Epic) =>
            this.store.select(
              fromkanbanitemsSelectors.getEpicsByPortfoliofactory(
                epic.portfolioId
              )
            )
          )
        )

        this.solutions$ = this.store.pipe(
          select(
            fromkanbanitemsSelectors.getkanbanItemByIdFactory(
              this.feature.parentId
            )
          ),
          filter((parent) => !!parent),
          switchMap((epic: Epic) =>
            this.store.select(
              fromPortofolioSelectors.getPortfolioSafeById(epic.portfolioId)
            )
          ),
          switchMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getSolutionsByOrganizationIdFilterByType(
                portfolio.organizationId,
                'business'
              )
            )
          )
        )

        this.form.get('parentId').disable()
      } else {
        // IF HAS NO PARENT
        this.form.get('parentId').setValue('')

        // THE FOLLOWING CODE SHOW BE DELETED BECAUISE NO POSSIBLE
        /*
        if (this.feature.kanbanId) {
          this.usersList$ = this.store
            .select(
              fromFeatureSelectors.selectOrganizationByKanbanId(
                this.feature.kanbanId
              )
            )
            .pipe(
              switchMap((organization) =>
                this.store.select(
                  fromFeatureSelectors.getMembersList(organization.id)
                )
              )
            )
        } else if (this.feature.columnId) {
          this.usersList$ = this.store.pipe(
            select(
              fromKanbanSelectors.getKanbanByColumnId(this.feature.columnId)
            ),
            filter((kanban) => !!kanban),

            switchMap((kanban) =>
              this.store.select(
                fromFeatureSelectors.selectOrganizationByKanbanId(kanban.id)
              )
            ),
            switchMap((organization) =>
              this.store.select(
                fromFeatureSelectors.getMembersList(organization.id)
              )
            )
          )
        }*/
      }

      this.childrenItemList$ = this.store.pipe(
        // eslint-disable-next-line ngrx/select-style
        select(fromFeatureSelectors.getChildrenKanbanItems(this.feature.id)),

        tap((val) => this.initArrayOfTeams(val))
      )

      if ('ownerId' in this.data.feature) {
        this.form.get('ownerId').disable()
      }
      // const ownerId = this.dialogRef.data.feature.ownerId
      // if (ownerId !== null && ownerId !== undefined) {

      // }

      const ids: string[] = []
      this.feature.teams?.forEach((teamId) => {
        ids.push(teamId.id)
      })

      this.teams.setValue(ids)

      this.form.patchValue(this.feature)

      if (this.usersList$) {
        this.usersList$.subscribe((teamMembers) => {
          this.mentionsConfig.items = teamMembers.map(
            (teamMember) => teamMember.user.name
          )
        })
      }
    } else {
      // NEW FEATURE MODE

      // IF NEW FEATURE CREATED FROM AN ART
      if ('artId' in this.data) {
        const artId = this.data.artId

        if (artId !== null && artId !== undefined) {
          this.solutions$ = this.store
            .select(fromARTSelectors.selectART(this.data.artId))
            .pipe(
              switchMap((art) =>
                this.store.select(
                  fromPortfolioSelectors.getPortfolioSafeById(art.portfolioId)
                )
              ),
              switchMap((portfolio) =>
                this.store.select(
                  fromFeatureSelectors.getSolutionsByOrganizationIdFilterByType(
                    portfolio.organizationId,
                    'business'
                  )
                )
              )
            )

          this.pi$ = this.store.select(
            ProgramIncrementSelectors.getPIByARTId(artId)
          )

          this.currentArt$ = this.store.select(
            fromFeatureSelectors.selectARTSWithTeamsId(artId)
          )

          this.form.get('artId').setValue(artId)
          this.form.get('artId').disable()
        }
      }

      this.usersList$ = this.store
        .select(
          fromFeatureSelectors.selectOrganizationByKanbanId(this.data.kanbanId)
        )
        .pipe(
          filter((val) => !!val),
          switchMap((organization) =>
            this.store.select(
              fromFeatureSelectors.getMembersList(organization.id)
            )
          )
        )

      const portfolioId = this.data.portfolioId

      if (
        portfolioId === '' ||
        portfolioId === undefined ||
        portfolioId === null
      )
        this.epics$ = this.store.select(fromkanbanitemsSelectors.getEpics())
      else {
        const artId = this.data.artId
        this.pi$ = this.store.select(
          ProgramIncrementSelectors.getPIByARTId(artId)
        )
        if (this.data.kanbanId !== undefined) {
          this.currentArt$ = this.store.select(
            fromFeatureSelectors.selectARTSWithTeamsByKanbanId(
              this.data.kanbanId
            )
          )
          this.objectives$ = this.store.pipe(
            // eslint-disable-next-line ngrx/select-style
            select(
              fromFeatureSelectors.selectOrganizationByKanbanId(
                this.data.kanbanId
              )
            ),
            filter((val) => !!val),
            switchMap((organization: Organization) =>
              this.store.select(
                fromFeatureSelectors.selectActiveStrategicThemesByOrganization(
                  organization.id
                )
              )
            )
          )

          this.epics$ = this.currentArt$.pipe(
            switchMap((art) =>
              this.store.select(
                fromFeatureSelectors.selectEpicsAssignedToART(art.id)
              )
            ),
            filter((val) => !!val)
          )
        } else {
          this.epics$ = this.store.select(
            fromkanbanitemsSelectors.getEpicsByPortfoliofactory(portfolioId)
          )
        }
      }

      this.teams.setValue(['none'])
      this.form.patchValue(this.createFeature())
    }
  }
  save() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()

      this.store.dispatch(
        fromActionsKanbanItems.upsertKanbanitem({
          kanbanitem: entity
        })
      )
    }
  }
  delete() {
    const id = this.form.value.id

    this.store.dispatch(fromActionsKanbanItems.deleteKanbanitem({ id }))

    this.dialogRef.close()
  }

  saveNew() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()
      // Create the callback function to close the dialog
      const callbackFunction = () => {
        this.dialogRef.close()
      }

      this.store.dispatch(
        fromActionsKanbanItems.addKanbanitem({
          kanbanitem: entity,
          callback: callbackFunction
        })
      )

      // this.dialogRef.close()
      // this.service.close()
    }
  }

  resetForm() {
    this.formDirective.resetForm()
    this.form.reset()
    this.form.get('name').setValue('feature')
    this.form.get('id').setValue(' ')
  }
  onSubmit($event): void {
    if ($event.submitter.id === 'save') {
      this.save()
    }
    if ($event.submitter.id === 'saveNew') {
      // console.log('here')
      this.saveNew()
    }
  }

  addItem(parentId: string) {
    if (!this.executed) {
      this.executed = true
      const factory = this.resolver.resolveComponentFactory(
        DynamicRequirementFormComponent
      )
      this.componentRef = this.viewContainerRef.createComponent(factory)
      this.componentRef.instance.data = {
        parentId,
        parentComponent: this
      }
      this.showAddFeatureMessage = false
    }
  }

  linkFeature(feature) {
    if (!this.relatedFeatureFormExecuted) {
      this.relatedFeatureFormExecuted = true
      this.showLinkFeatureMessage = false
      const factory = this.resolver.resolveComponentFactory(
        LinkFeaturesDynamicFormComponent
      )
      this.componentRef = this.relatedDynamicRef.createComponent(factory)
      this.componentRef.instance.data = {
        feature,
        parentComponent: this
      }
    }
  }

  deleteItem() {
    this.viewContainerRef.clear()
    this.showAddFeatureMessage = true
    this.executed = false
  }
  closeRelatedFeatureForm() {
    this.relatedDynamicRef.clear()
    this.showLinkFeatureMessage = true
    this.relatedFeatureFormExecuted = false
  }
  closeDialog() {
    this.dialogRef.close()
  }

  openItem(origin: HTMLElement, item: RequirementI) {
    if (item.name === 'userstory')
      this.dialogRef = this.service.open(
        {
          height: window.innerHeight * 0.95,
          width: window.innerWidth * 0.95
        },
        {
          origin,
          data: {
            story: item
          },
          content: UserStoryFormComponent
        }
      )
    else if (item.name === 'enablerstory') {
      this.dialogRef = this.service.open(
        {
          height: window.innerHeight * 0.95,
          width: window.innerWidth * 0.95
        },
        {
          origin,
          data: {
            epic: item
          },
          content: EnablerStoryFormComponent
        }
      )
    }
  }

  initArrayOfTeams(children) {
    if (children)
      for (const key in children) {
        if (children[key].teamId) {
          this.arrayOfChildrenWithTeams.push({
            storyId: children[key].id,
            teamId: children[key].teamId
          })
        }
      }

    return this.arrayOfChildrenWithTeams
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  checkTeams(event: TeamEvent) {
    if (event) {
      if (
        event.teamId !== '' &&
        this.arrayOfChildrenWithTeams
          .map((f) => f.storyId)
          .indexOf(event.storyId) === -1
      ) {
        this.arrayOfChildrenWithTeams.push({
          teamId: event.teamId,
          storyId: event.storyId
        })
      } else {
        const index = this.arrayOfChildrenWithTeams
          .map((f) => f.storyId)
          .indexOf(event.storyId)

        this.arrayOfChildrenWithTeams.splice(index, 1)
      }

      return this.arrayOfChildrenWithTeams
    }
  }
  // Función para inicializar Froala
  initFroala(initControls) {
    initControls.initialize()
    // this.initControls = initControls
    // this.editor = initControls.getEditor()
  }

  private createFeature(): FeatureI<StoryI> {
    const feat: FeatureI<StoryI> = {
      acceptanceCriteria: '',
      id: null,
      code: '',
      parentId: this.data.parentId != null ? this.data.parentId : '',
      benefitHypothesis: '',
      columnId: '',
      creationDate: new Date(),
      itemName: '',
      name: 'feature',
      description: this.data.description != null ? this.data.description : '',
      locked: false,
      kanbanId: this.data.kanbanId != null ? this.data.kanbanId : '',
      lastUpdate: null,
      toKanban: false,
      ownerId: '',
      type: '',
      isMvp: false,
      inRoadmap: false,
      //  portfolioId: this.data.portfolioId != null ? this.data.portfolioId : '',
      teams: [],
      programIncrementId: '',
      keyResultId: ''
    }
    return feat
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  selectTeamForChildStory(event: TeamEvent) {
    this.checkTeams(event)
    if (event.teamId !== '') {
      const kanbanId = event.kanbanId

      const kanbanItem: Update<RequirementI> = {
        id: event.storyId,
        changes: {
          kanbanId: kanbanId,
          teamId: event.teamId,
          columnId: event.columnId
        }
      }
      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanItem
        })
      )
    } else {
      const kanbanItem: Update<RequirementI> = {
        id: event.storyId,
        changes: {
          kanbanId: '',
          teamId: '',
          columnId: ''
        }
      }
      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanItem
        })
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  patchTeams(value) {
    if (!this.isUpdate) return
    if (!this.feature) return
    const kanbanItem: Update<RequirementI> = {
      id: this.feature.id,
      changes: {
        teams: value
      }
    }
    if (this.feature.teams === undefined || this.feature.teams.length === 0) {
      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanItem,
          op: 'add'
        })
      )
    } else {
      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanItem,
          op: 'replace'
        })
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  updateDescriptionFeature(e) {
    if (e !== null && e !== '' && e !== this.feature.description) {
      const update: Update<RequirementI> = {
        id: this.feature.id,
        changes: { description: e }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  changeParentDialogRef(origin, epics: Epic[]) {
    {
      const form: OverlayFormRef = this.service.open(
        { height: 350, width: 650 },
        {
          content: SelectParentFormComponent,
          origin,
          data: { feature: this.feature, epics }
        }
      )
    }
  }
  changeKanbanDialogRef(origin) {
    {
      const form: OverlayFormRef = this.service.open(
        { height: 350, width: 650 },
        {
          content: KanbanChangeFormComponent,
          origin,
          data: { feature: this.feature }
        }
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  updateFeatureOwner(value) {
    if (!this.feature) return
    const update: Update<RequirementI> = {
      id: this.feature.id,
      changes: { featureOwnerId: value }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  changeState(event) {
    const id = event.value

    if (!this.isUpdate) return
    const update: Update<RequirementI> = {
      id: this.feature.id,
      changes: { columnId: id }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }
  changePI(event) {
    const pi = event.value

    if (!this.isUpdate) return
    const update: Update<Feature> = {
      id: this.feature.id,
      changes: { programIncrementId: pi }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }
  changeisMVP(event) {
    const value = event.checked

    if (!this.isUpdate) return
    const update: Update<Feature> = {
      id: this.feature.id,
      changes: { isMvp: value }
    }
    if (this.feature.isMvp === undefined) {
      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: update,
          op: 'add'
        })
      )
    } else {
      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: update,
          op: 'replace'
        })
      )
    }
  }
  changeDoR(event) {
    const value = event.value

    if (!this.isUpdate) return
    const update: Update<Feature> = {
      id: this.feature.id,
      changes: { dor: value }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  onHandleFile(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0]
      this.form.patchValue({
        files: this.file
      })

      const formData = new FormData()
      formData.append('file', this.file)
      this.attachmentService
        .uploadFile(formData)
        .subscribe((data: AttachMentResponse) => {
          this.newFile = { ...data }
          this.filesOfItem = [...this.filesOfItem, this.newFile]

          this.form.patchValue({
            files: this.filesOfItem
          })
          const update: Update<RequirementI> = {
            id: this.feature.id,
            changes: { files: this.filesOfItem }
          }

          this.store.dispatch(
            fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
          )
        })
    }
  }

  onSubmitFile() {
    const update: Update<RequirementI> = {
      id: this.feature.id,
      changes: { files: this.filesOfItem }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  downLoadFile(url: string) {
    window.location.href = url
  }
  deleteFile(id) {
    const index = this.filesOfItem.findIndex((file) => file.id === id)
    const filesArray = [...this.filesOfItem]
    if (index !== -1) {
      filesArray.splice(index, 1)
      const update: Update<RequirementI> = {
        id: this.feature.id,
        changes: { files: filesArray }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
      this.filesOfItem = filesArray
      this.form.patchValue({
        files: this.filesOfItem
      })
    }
  }

  onChangeSolution(event) {
    if (!this.isUpdate) return
    const solutionId = event.value

    if (solutionId) {
      const epic: Update<RequirementI> = {
        id: this.feature.id,
        changes: { solutionId }
      }

      const kanbanitem = (epic as unknown) as Update<RequirementI>

      if (this.feature.solutionId === undefined) {
        this.store.dispatch(
          fromRequirementsActions.patchKanbanitem({
            kanbanitem: kanbanitem,
            op: 'add'
          })
        )
      } else {
        this.store.dispatch(
          fromRequirementsActions.patchKanbanitem({
            kanbanitem: kanbanitem,
            op: 'replace'
          })
        )
      }
    } else {
      const epic = {
        id: this.feature.id,
        changes: { solutionId: null }
      }

      const kanbanitem = (epic as unknown) as Update<RequirementI>

      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanitem
        })
      )
    }
  }
  onChangeKeyResult(event) {
    if (!this.isUpdate) return
    const keyResultId = event.value
    if (keyResultId) {
      const epic: Update<RequirementI> = {
        id: this.feature.id,
        changes: { keyResultId }
      }

      const kanbanitem = (epic as unknown) as Update<RequirementI>

      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanitem
        })
      )
    } else {
      const epic = {
        id: this.feature.id,
        changes: { keyResultId: null }
      }

      const kanbanitem = (epic as unknown) as Update<RequirementI>

      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanitem
        })
      )
    }
  }

  /* compareKeyResults(object1: KeyResult, object2: KeyResult) {
    return object1 && object2 && object1.id === object2.id
  }
  */
  openBreakDownFeatureForm(origin: HTMLElement, context?: RequirementI) {
    const description =
      this.form.get('description').value +
      ' ' +
      this.form.get('benefitHypothesis').value +
      +' ' +
      this.form.get('acceptanceCriteria').value

    const contextDescription = context ? context.description : ''
    this.dialogRef = this.service.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.45,
        maxWidth: '50%',
        /** Position overrides. */
        position: { right: '0' },
        hasBackdrop: false
        // panelClass: 'dialog-slide-in'
      },
      {
        origin,
        data: {
          featureDescription: description,
          parentId: this.feature.id,
          context: contextDescription
        },
        content: BreakDownFeatureFormComponent
      }
    )
  }

  generateAcceptanceCriteria(
    origin: HTMLElement,
    description: string,
    nonFunctionalRequirements?: string
  ) {
    this.dialogRef = this.service.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.95,
        maxWidth: '50%',
        /** Position overrides. */
        position: { right: '0' },
        hasBackdrop: false
        // panelClass: 'dialog-slide-in'
      },
      {
        origin,
        data: {
          description: this.stripHtml(description),

          parentComponent: this
        },
        content: AcceptanceCriteriaFormComponent
      }
    )
  }

  setAcceptanceCriteria(acceptanceCriteria) {
    this.form.get('acceptanceCriteria').setValue(acceptanceCriteria)
  }
  stripHtml(html) {
    if (html == null) {
      return '' // Return an empty string if the input is null or undefined
    }
    const regex = /<[^>]*>?/gm
    return html.replace(regex, '')
  }
}
