import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Renderer2,
  ChangeDetectorRef,
  NgZone
} from '@angular/core'
import { MenuService } from '../services/menu.service'
import { NavItem } from '../store/menu.store'
import { ScrollDirectionService } from '../services/scroll-direction.service'
import { toolbarAnimation } from '../services/toolbar'
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  UrlSegment
} from '@angular/router'

@Component({
  selector: 'anms-organization-context-menu',
  templateUrl: './organization-context-menu.component.html',
  styleUrls: ['./organization-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationContextMenuComponent implements OnInit {
  @Output() closeDialogEvent = new EventEmitter()
  navigation: NavItem[]
  showSubmenu = false
  isScrollingDown = false
  toolbarState: 'hidden' | 'visible' = 'visible' // Initial state is visible

  constructor(
    private service: MenuService,
    private scrollDirectionService: ScrollDirectionService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.navigation = this.service.getContextNavigationMenu()
    const bodyElement = document.body // Get the body element
    this.scrollDirectionService
      .getScrollDirection()
      .subscribe((isScrollingDown) => {
        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            this.isScrollingDown = isScrollingDown
            this.toolbarState = isScrollingDown ? 'hidden' : 'visible' // Update the toolbar state
            this.cdr.detectChanges()
          })
        })
        /*     this.zone.run(() => {
          this.isScrollingDown = isScrollingDown
          this.toolbarState = isScrollingDown ? 'hidden' : 'visible' // Update the toolbar state
          console.log(this.toolbarState)
        }) */
        // this.cdr.markForCheck() // Trigger change detection
      })
  }

  onNavigationClick(currentItem?: NavItem) {
    if (currentItem) {
      // Extract the last fragment from the current URL
      const currentUrl = this.router.url
      const urlSegments = currentUrl.split('/')

      // Get the last segment (fragment) or an empty string if it doesn't exist
      const lastFragment = urlSegments[urlSegments.length - 1] || ''

      // Define a regular expression to match the identifier format
      const identifierPattern = /^[a-f0-9]{24}$/

      // Check if the last fragment matches the identifier format
      const isIdentifier = identifierPattern.test(lastFragment)

      // Construct the new URL with the updated organization ID
      let newUrl = `${currentItem.link}${currentItem.id}`

      // Append the last fragment only if it's present and the URL matches the specified pattern and  it's not an identifier
      if (
        currentItem.type === 'organization' &&
        !isIdentifier &&
        currentUrl.startsWith('/organizations/organization')
      ) {
        newUrl += `/${lastFragment}`
      }

      const navigationExtras: NavigationExtras = {}
      // Preserve query parameters and fragment
      /* const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        fragment: this.route.snapshot.fragment
      }*/
      // Navigate to the new URL
      this.router.navigate([newUrl], navigationExtras)
    }

    this.closeDialogEvent.emit()
  }
}
