import { Action, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { Event } from './event.model'
import * as EventActions from './event.actions'

export const eventsFeatureKey = 'events'

export interface EventState extends EntityState<Event> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>()

export const initialState: EventState = adapter.getInitialState({
  // additional entity state properties
  ids: ['1'],
  entities: {
    '1': {
      id: '1',
      date: new Date(),
      notification: false,
      name: 'Event',
      description: 'Description',
      programIncrementId: '1'
    }
  }
})

export const reducer = createReducer(
  initialState,
  on(EventActions.successAddEvent, (state, action) =>
    adapter.addOne(action.event, state)
  ),
  on(EventActions.upsertEvent, (state, action) =>
    adapter.upsertOne(action.event, state)
  ),
  on(EventActions.addEvents, (state, action) =>
    adapter.addMany(action.events, state)
  ),
  on(EventActions.upsertEvents, (state, action) =>
    adapter.upsertMany(action.events, state)
  ),
  on(EventActions.updateEvent, (state, action) =>
    adapter.updateOne(action.event, state)
  ),
  on(EventActions.updateEvents, (state, action) =>
    adapter.updateMany(action.events, state)
  ),
  on(EventActions.deleteEvent, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(EventActions.deleteEvents, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(EventActions.loadEvents, (state, action) =>
    adapter.setAll(action.events, state)
  ),
  on(EventActions.clearEvents, (state) => adapter.removeAll(state))
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors()
