import browser from 'browser-detect'
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { environment as env } from '../../environments/environment'
import {
  authLogin,
  authLogout,
  routeAnimations,
  LocalStorageService,
  selectIsAuthenticated,
  selectSettingsStickyHeader,
  selectSettingsLanguage,
  selectEffectiveTheme
} from '../core/core.module'
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '../core/settings/settings.actions'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { AuthServiceGeneric } from '../core/auth/auth.service'
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav'
import { SidenavService } from './menu/services/sideNavService'
import { filter, map } from 'rxjs/operators'
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog'
import { AccountMenuComponent } from './menu/account-menu/account-menu.component'
import { Overlay, ScrollStrategyOptions } from '@angular/cdk/overlay'
import { HotjarTrackingCodeService } from '../core/hotjar/hotjar-tracking-code.service'

const enablerSvg = '../../../../assets/icons/icon-type-enabler-dark.svg'
const businessSvg = '../../../assets/icons/icon-type-business-dark.svg'
const featureSvg = '../../../assets/icons/icon-feature.svg'
const epicSvg = '../../../assets/icons/icon-epic.svg'
const storySvg = '../../../assets/icons/icon-story.svg'
const artSvg = '../../../assets/icons/icon-art.svg'
const aiSvg = '../../../assets/icons/icon-ai-magic.svg'
const organizationSvg = '../../../assets/icons/organization-icon.svg'
const serviceSvg = '../../../assets/icons/icon-solution-service.svg'
const productSvg = '../../../assets/icons/icon-solution-product.svg'
const systemSvg = '../../../assets/icons/icon-solution-system.svg'
const portfolioSvg = '../../../assets/icons/icon-portfolio.svg'
const solutionSvg = '../../../assets/icons/icon-solution.svg'
const dodReadySvg = '../../../assets/icons/icon-dodReady.svg'
const dodPendingSvg = '../../../assets/icons/icon-dodPending.svg'
const dodUndefinedSvg = '../../../assets/icons/icon-dodUndefined.svg'
// eslint-disable-next-line @typescript-eslint/naming-convention
const ValueStreamSvg = '../../../assets/icons/icon-value-stream.svg'
const valueStreamDevelpomentSvg =
  '../../../assets/icons/icon-value-stream-development.svg'
// eslint-disable-next-line @typescript-eslint/naming-convention
const IntSolSvg = '../../../assets/icons/icon-internal-solution.svg'
// eslint-disable-next-line @typescript-eslint/naming-convention
const ExtSolSvg = '../../../assets/icons/icon-external-solution.svg'
const assignFeatures = '../../../assets/icons/sidebar/assignFeatures.svg'
const backlogGrooming = '../../../assets/icons/sidebar/backlogGrooming.svg'
const featureBreaker = '../../../assets/icons/sidebar/featureBreaker.svg'
const dashboard = '../../../assets/icons/sidebar/dashboard.svg'
const manageValueStreams =
  '../../../assets/icons/sidebar/manageValueStreams.svg'
const members = '../../../assets/icons/sidebar/members.svg'
const objectives = '../../../assets/icons/sidebar/objectives.svg'
const organizationalAgility =
  '../../../assets/icons/sidebar/organizationalAgility.svg'
const overview = '../../../assets/icons/sidebar/overview.svg'
const planner = '../../../assets/icons/sidebar/planner.svg'
const portfolios = '../../../assets/icons/sidebar/portfolios.svg'
const programKanban = '../../../assets/icons/sidebar/programKanban.svg'
const roadmap = '../../../assets/icons/sidebar/roadmap.svg'
const solutions = '../../../assets/icons/sidebar/solutions.svg'
const strategicThemes = '../../../assets/icons/sidebar/strategicThemes.svg'
const strategy = '../../../assets/icons/sidebar/strategy.svg'
const teams = '../../../assets/icons/sidebar/teams.svg'
const timeline = '../../../assets/icons/sidebar/timeline.svg'

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  mobileQuery: MediaQueryList

  showFiller = false
  isProd = env.production
  envName = env.envName
  version = env.versions.app
  year = new Date().getFullYear()
  logo = '../../assets/logo.png'
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he']

  isAuthenticated$: Observable<boolean>
  stickyHeader$: Observable<boolean>
  language$: Observable<string>
  theme$: Observable<string>
  isInitial$: Observable<boolean>
  currentUrl: string

  // showSubmenu = false

  constructor(
    private store: Store,
    private storageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthServiceGeneric,
    public sidenavService: SidenavService,
    public dialog: MatDialog,
    private readonly sso: ScrollStrategyOptions,
    private hotjarService: HotjarTrackingCodeService
  ) {
    this.matIconRegistry.addSvgIcon(
      'business',
      this.domSanitizer.bypassSecurityTrustResourceUrl(businessSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'enabler',
      this.domSanitizer.bypassSecurityTrustResourceUrl(enablerSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'epic',
      this.domSanitizer.bypassSecurityTrustResourceUrl(epicSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'feature',
      this.domSanitizer.bypassSecurityTrustResourceUrl(featureSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'story',
      this.domSanitizer.bypassSecurityTrustResourceUrl(storySvg)
    )
    this.matIconRegistry.addSvgIcon(
      'userstory',
      this.domSanitizer.bypassSecurityTrustResourceUrl(storySvg)
    )
    this.matIconRegistry.addSvgIcon(
      'enablerstory',
      this.domSanitizer.bypassSecurityTrustResourceUrl(storySvg)
    )
    this.matIconRegistry.addSvgIcon(
      'art',
      this.domSanitizer.bypassSecurityTrustResourceUrl(artSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'ai',
      this.domSanitizer.bypassSecurityTrustResourceUrl(aiSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'organization',
      this.domSanitizer.bypassSecurityTrustResourceUrl(organizationSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'product',
      this.domSanitizer.bypassSecurityTrustResourceUrl(productSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'service',
      this.domSanitizer.bypassSecurityTrustResourceUrl(serviceSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'system',
      this.domSanitizer.bypassSecurityTrustResourceUrl(systemSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'solution',
      this.domSanitizer.bypassSecurityTrustResourceUrl(solutionSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'portfolio',
      this.domSanitizer.bypassSecurityTrustResourceUrl(portfolioSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'dod-ready',
      this.domSanitizer.bypassSecurityTrustResourceUrl(dodReadySvg)
    )
    this.matIconRegistry.addSvgIcon(
      'dod-pending',
      this.domSanitizer.bypassSecurityTrustResourceUrl(dodPendingSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'dod-undefined',
      this.domSanitizer.bypassSecurityTrustResourceUrl(dodUndefinedSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'vs-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(ValueStreamSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'vs-dev-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        valueStreamDevelpomentSvg
      )
    )
    this.matIconRegistry.addSvgIcon(
      'external-customer',
      this.domSanitizer.bypassSecurityTrustResourceUrl(ExtSolSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'internal-customer',
      this.domSanitizer.bypassSecurityTrustResourceUrl(IntSolSvg)
    )
    this.matIconRegistry.addSvgIcon(
      'assignFeatures',
      this.domSanitizer.bypassSecurityTrustResourceUrl(assignFeatures)
    )
    this.matIconRegistry.addSvgIcon(
      'backlogGrooming',
      this.domSanitizer.bypassSecurityTrustResourceUrl(backlogGrooming)
    )
    this.matIconRegistry.addSvgIcon(
      'featureBreaker',
      this.domSanitizer.bypassSecurityTrustResourceUrl(featureBreaker)
    )
    this.matIconRegistry.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl(dashboard)
    )
    this.matIconRegistry.addSvgIcon(
      'manageValueStreams',
      this.domSanitizer.bypassSecurityTrustResourceUrl(manageValueStreams)
    )
    this.matIconRegistry.addSvgIcon(
      'members',
      this.domSanitizer.bypassSecurityTrustResourceUrl(members)
    )
    this.matIconRegistry.addSvgIcon(
      'objectives',
      this.domSanitizer.bypassSecurityTrustResourceUrl(objectives)
    )
    this.matIconRegistry.addSvgIcon(
      'organizationalAgility',
      this.domSanitizer.bypassSecurityTrustResourceUrl(organizationalAgility)
    )
    this.matIconRegistry.addSvgIcon(
      'overview',
      this.domSanitizer.bypassSecurityTrustResourceUrl(overview)
    )
    this.matIconRegistry.addSvgIcon(
      'planner',
      this.domSanitizer.bypassSecurityTrustResourceUrl(planner)
    )
    this.matIconRegistry.addSvgIcon(
      'portfolios',
      this.domSanitizer.bypassSecurityTrustResourceUrl(portfolios)
    )
    this.matIconRegistry.addSvgIcon(
      'programKanban',
      this.domSanitizer.bypassSecurityTrustResourceUrl(programKanban)
    )
    this.matIconRegistry.addSvgIcon(
      'roadmap',
      this.domSanitizer.bypassSecurityTrustResourceUrl(roadmap)
    )
    this.matIconRegistry.addSvgIcon(
      'solutions',
      this.domSanitizer.bypassSecurityTrustResourceUrl(solutions)
    )
    this.matIconRegistry.addSvgIcon(
      'strategicThemes',
      this.domSanitizer.bypassSecurityTrustResourceUrl(strategicThemes)
    )
    this.matIconRegistry.addSvgIcon(
      'strategy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(strategy)
    )
    this.matIconRegistry.addSvgIcon(
      'teams',
      this.domSanitizer.bypassSecurityTrustResourceUrl(teams)
    )
    this.matIconRegistry.addSvgIcon(
      'timeline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(timeline)
    )
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name)
  }

  ngOnInit(): void {
    if (env.production) {
      // Load Hotjar tracking code only in production
      this.hotjarService.loadHotjar()
    }
    this.isInitial$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        this.currentUrl = event.url
        if (
          this.currentUrl &&
          (this.currentUrl.includes('organizations/organization') ||
            this.currentUrl.includes('portfolios/portfolio') ||
            this.currentUrl.includes('arts/art') ||
            this.currentUrl.includes('user') ||
            this.currentUrl.includes('teams') ||
            this.currentUrl.includes('program-increments/program-increment') ||
            this.currentUrl.includes('solutions/solution'))
        )
          return true
        else return false
      })
    )

    this.storageService.testLocalStorage()
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      )
    }
    this.isAuthenticated$ = this.authService.isLoggedIn()
    this.language$ = this.store.pipe(select(selectSettingsLanguage))
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader))
    this.theme$ = this.store.pipe(select(selectEffectiveTheme))
  }

  onLoginClick() {
    this.router.navigate(['./login'], {
      relativeTo: this.route
    })

    // this.store.dispatch(authLogin({ username: '', password: '' }))
  }

  openAccountMenu(): void {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.panelClass = 'custom-dialog-account-menu'
    dialogConfig.backdropClass = 'account-menu-backdrop'
    dialogConfig.autoFocus = false
    dialogConfig.hasBackdrop = true
    dialogConfig.scrollStrategy = this.sso.reposition()
    // const scrollStrategy = this.overlay.scrollStrategies.reposition()
    // dialogConfig.scrollStrategy = scrollStrategy

    const dialogRef: MatDialogRef<AccountMenuComponent> = this.dialog.open(
      AccountMenuComponent,
      dialogConfig
    )
    dialogRef.componentInstance.closeDialogEvent.subscribe(() => {
      dialogRef.close()
    })
  }
  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }))
  }

  closeSidenav() {
    // DISABLED
    //  this.sidenavService.close()
  }

  toggleSidenav() {
    this.sidenavService.toggle()
  }
}
