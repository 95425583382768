import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  InjectionToken,
  Inject
} from '@angular/core'
import { MOBILE_ANIMATION_FLAG, PopoverContent } from '../overlay.service'
import { OverlayFormRef } from '../overlay-form-ref'
import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata,
  query,
  animateChild,
  group
} from '@angular/animations'
import { mobileDialogAnimation, noneAnimation } from '../overlay-animations'

@Component({
  selector: 'anms-overlaycomponent',
  templateUrl: './overlaycomponent.component.html',
  styleUrls: ['./overlaycomponent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [mobileDialogAnimation]
})
export class OverlayComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component'
  content: PopoverContent
  context

  useMobileAnimation: boolean

  constructor(
    private popoverRef: OverlayFormRef,
    @Inject(MOBILE_ANIMATION_FLAG) private mobileAnimationFlag: boolean
  ) {
    this.useMobileAnimation = this.mobileAnimationFlag // Assign the animation flag to the local property
  }

  ngOnInit() {
    this.content = this.popoverRef.content

    if (typeof this.content === 'string') {
      this.renderMethod = 'text'
    } else if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template'
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef)
      }
    }
  }
  closeDialog() {
    this.popoverRef.close()
  }
}
