import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { Inject } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromOpenAIActions from '../../features/openai/open-ai.actions'
import { OverlayFormRef } from '../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../overlay-forms/overlay/overlay.service'
import { FormBuilder, FormGroup } from '@angular/forms'
import {
  FindLeadingIndicatorsRequest,
  ImproveRequest,
  OpenAIStore
} from '../../features/openai/open-ai.model'
import { EpicOverlayFormComponent } from '../requirement-forms/epic/epic-overlay-form.component'
import { Observable, Subscription } from 'rxjs'

@Component({
  selector: 'anms-find-leading-indicators',
  templateUrl: './find-leading-indicators.component.html',
  styleUrls: ['./find-leading-indicators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindLeadingIndicatorsComponent implements OnInit {
  form: FormGroup

  parentComponent: EpicOverlayFormComponent

  descriptions$: Observable<string[]>

  subscription: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: { epicDescription; nonFunctionalRequirements; parentComponent }
  ) {
    this.form = this.formBuilder.group({
      epicDescription: this.data.epicDescription,
      leadingIndicators: '',
      leadingIndicatorsFound: '',
      nonFunctionalRequirements: this.data.nonFunctionalRequirements
    })
  }

  ngOnInit(): void {
    this.parentComponent = this.data.parentComponent as EpicOverlayFormComponent
    this.descriptions$ = this.openAIStore.filterItemsByType('leading')
    this.subscription = this.descriptions$.subscribe((val) => {
      if (val && val.length > 0) {
        const description = val[0]
        if (description) {
          const filteredDescription = description
          this.form.get('leadingIndicatorsFound').setValue(filteredDescription)
        }
      }
    })
  }
  onSubmit(buttonType): void {
    if (buttonType === 'load') {
      this.findLeadingIndicators()
    }
  }

  findLeadingIndicators() {
    if (this.form.valid) {
      const form = this.form.getRawValue()
      const req: FindLeadingIndicatorsRequest = {
        epicDescription: this.data.epicDescription,
        nonFunctionalRequirements: this.data.nonFunctionalRequirements,
        n: 1
      }
      this.store.dispatch(
        fromOpenAIActions.findLeadingIndicators({ data: req })
      )
    }
  }

  moveTextToLeft(text: string): void {
    this.form.get('leadingIndicators').setValue(text)
  }

  copyContentToParent() {
    const description = this.form.get('leadingIndicators').value
    // this.dialogRef.
    this.parentComponent.setLeadingIndicators(description)
    this.dialogRef.close()
  }
  closeDialog() {
    this.dialogRef.close()
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
