import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { OverlayFormRef } from '../../../../overlay-forms/overlay/overlay-form-ref'
import {
  Feature,
  RequirementI
} from '../../../../features/requirements-store/store/requirements.model'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import * as fromPortfolioSAFESelectors from '../../../../features/portfolio-store/portfolio/portfolio-safe.selectors'
import * as fromFeatureSelectors from '../../../../features/feature-selectors'
import * as fromARTSelectors from '../../../../features/arts-store/art.selectors'
import * as fromKanbanSelectors from '../../../../kanban/store/kanban-selector'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { ART } from '../../../../features/arts-store/art.model'
import { PortfolioSafe } from '../../../../features/portfolio-store/portfolio/portfolio-safe.model'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { FeatureState } from '../feature-form/feature-form.component'
import { Kanban } from '../../../../kanban/store/kanban.model'
import { Organization } from '../../../../features/organization-store/store/organization.model'
import { INIT_DATA1 } from '../../../../overlay-forms/overlay/overlay.service'

@Component({
  selector: 'anms-kanban-change-form',
  templateUrl: './kanban-change-form.component.html',
  styleUrls: ['./kanban-change-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanChangeFormComponent implements OnInit {
  feature: Feature
  form: FormGroup

  arts$: Observable<ART[]>
  kanbans$: Observable<Kanban[]>
  portfolio$: Observable<PortfolioSafe>

  states$: Observable<FeatureState[]>

  openDialog$: Observable<any>

  selectedART$: BehaviorSubject<string>

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: {
      feature?
    }
  ) {
    this.form = this.formBuilder.group({
      kanbanId: ['', [Validators.required]],
      columnId: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.selectedART$ = new BehaviorSubject(undefined)

    if ('feature' in this.data) {
      this.feature = this.data.feature

      if (
        this.feature.parentId !== null &&
        this.feature.parentId !== undefined
      ) {
        this.portfolio$ = this.store.select(
          fromFeatureSelectors.selectPortfolioByEpicId(this.feature.parentId)
        )

        this.arts$ = this.portfolio$.pipe(
          switchMap((portfolio: PortfolioSafe) =>
            this.store.select(
              fromARTSelectors.getARTListByPortfolioId(portfolio.id)
            )
          )
        )
        this.kanbans$ = this.arts$.pipe(
          map((arts) => {
            const ids: string[] = []
            arts.forEach((art) => {
              if (art.kanbanId !== this.feature.kanbanId) ids.push(art.kanbanId)
            })
            return ids
          }),
          switchMap((ids) =>
            this.store.select(fromKanbanSelectors.selectKanbanListFromIds(ids))
          )
        )

        this.states$ = combineLatest([this.selectedART$, this.kanbans$]).pipe(
          map(([selectedArts, kanbans]) => {
            if (selectedArts === null || selectedArts === undefined)
              return undefined
            return kanbans.find((kanban) => kanban.id === selectedArts)
          }),
          filter((val) => val !== undefined),
          switchMap((kanban: Kanban) =>
            this.store.select(
              fromKanbanSelectors.getStatesAndColumnIdsByKanbanId(kanban.id)
            )
          )
        )
      } else {
        if (
          this.feature.columnId !== undefined &&
          this.feature.columnId !== null
        ) {
          this.kanbans$ = this.store
            .select(
              fromKanbanSelectors.getKanbanByColumnId(this.feature.columnId)
            )
            .pipe(
              tap((val) => console.log(val)),
              switchMap((kanban) =>
                this.store.select(fromARTSelectors.getARTByKanbanId(kanban.id))
              ),
              switchMap((art: ART) =>
                this.store.select(
                  fromARTSelectors.getARTListByPortfolioId(art.portfolioId)
                )
              ),
              // eslint-disable-next-line ngrx/avoid-mapping-selectors
              map((arts) => {
                const ids: string[] = []
                arts.forEach((art) => {
                  if (art.kanbanId !== this.feature.kanbanId)
                    ids.push(art.kanbanId)
                })
                return ids
              }),
              switchMap((ids) =>
                this.store.select(
                  fromKanbanSelectors.selectKanbanListFromIds(ids)
                )
              )
            )

          this.states$ = combineLatest([this.selectedART$, this.kanbans$]).pipe(
            map(([selectedArts, kanbans]) => {
              if (selectedArts === null || selectedArts === undefined)
                return undefined
              return kanbans.find((kanban) => kanban.id === selectedArts)
            }),
            filter((val) => val !== undefined),
            switchMap((kanban: Kanban) =>
              this.store.select(
                fromKanbanSelectors.getStatesAndColumnIdsByKanbanId(kanban.id)
              )
            )
          )
        }
      }

      if (this.data.feature.kanbanId)
        this.form.get('kanbanId').setValue(this.data.feature.kanbanId)

      if (this.data.feature.columnId)
        this.form.get('columnId').setValue(this.data.feature.columnId)
    }
  }

  changeKanban(kanbanId) {
    this.selectedART$.next(kanbanId)
  }

  updateItem(columnId) {}
  closeDialog() {
    this.dialogRef.close()
  }

  save(): void {
    if (this.form.valid) {
      const kanbanId = this.form.get('kanbanId').value
      const columnId = this.form.get('columnId').value
      if (kanbanId !== undefined && columnId !== undefined) {
        const update: Update<RequirementI> = {
          id: this.feature.id,

          changes: { columnId: columnId, kanbanId: kanbanId }
        }

        this.store.dispatch(
          fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
        )

        this.dialogRef.close()
      }
    }
  }
}
