import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterContentInit,
  AfterContentChecked,
  EventEmitter,
  Output
} from '@angular/core'
import { Observable } from 'rxjs'
import { MenuStore, NavItem, NavItemType } from '../store/menu.store'

@Component({
  selector: 'anms-child-menu',
  templateUrl: './child-menu.component.html',
  styleUrls: ['./child-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChildMenuComponent implements OnInit {
  // @Input() items: NavItem[]
  @Input() type: NavItemType

  @Output() clickMenuEvent: EventEmitter<NavItem> = new EventEmitter()

  items$: Observable<NavItem[]>
  // = this.menuStore.items$

  constructor(private readonly menuStore: MenuStore) {
    /* if (this.type === 'art') this.items$ = this.menuStore.arts$
    else if (this.type === 'solution') this.items$ = this.menuStore.solutions$
    else if (this.type === 'portfolio') this.items$ = this.menuStore.portfolios$
    else this.items$ = this.menuStore.items$*/
  }

  ngOnInit(): void {
    if (this.type === 'art') this.items$ = this.menuStore.arts$
    else if (this.type === 'solution') this.items$ = this.menuStore.solutions$
    else if (this.type === 'portfolio') this.items$ = this.menuStore.portfolios$
    else if (this.type === 'organization')
      this.items$ = this.menuStore.organization$
    else this.items$ = this.menuStore.items$
    // this.items$.subscribe((items1) => console.log('items subscribe', items1))
  }
  onClickMenu(item: NavItem) {
    this.clickMenuEvent.emit(item)
  }
}
