import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ProgramIncrement } from './program-increment.model'
import { adapter } from './program-increment.reducer'
import {
  PIState,
  PlanificationState,
  planificationFeatureKey
} from '../reducers'
import { FeaturesState, selectFeatures } from '../../../features.state'

export const selectProgramIncrementsState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.planification
)

/* export const selectProgramIncrementsState = createFeatureSelector<
  PlanificationState,
  PIState
>(planificationFeatureKey)*/

export const selectProgramIncrements = createSelector(
  selectProgramIncrementsState,
  (state: PIState) => state.programincrements
)
export const selectEvents = createSelector(
  selectProgramIncrementsState,
  (state: PIState) => state.events
)
export const selectObjectives = createSelector(
  selectProgramIncrementsState,
  (state: PIState) => state.objectives
)

export const selectProgramIncrementsEntities = createSelector(
  selectProgramIncrements,
  (state) => state.entities
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectProgramIncrements)

export const selectProgramIncrementArray = createSelector(
  selectEntities,
  (entities) => Object.values(entities)
)

export const getProgramIncrementById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)

export const getProgramIncrementByIF = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getPIByPropertyValue = createSelector(
  selectProgramIncrementArray,
  (
    entities: ProgramIncrement[],
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) =>
    entities
      .filter((item) => item[columnId] === columnValue)
      .sort(
        (a, b) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      )
)
export const getFirtsPIByPropertyValue = createSelector(
  selectProgramIncrementArray,
  (
    entities: ProgramIncrement[],
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) => entities.find((item) => item[columnId] === columnValue)
)
export const getPIByARTId = (artId: string) =>
  createSelector(selectAll, (entities: ProgramIncrement[]) =>
    entities
      .filter((pi) => pi.ARTId === artId)
      .sort(
        (a, b) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      )
  )
export const selectAllOrdered = createSelector(
  selectAll,
  (entities: ProgramIncrement[]) =>
    entities.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    )
)
