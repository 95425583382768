import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { ProgramIncrement } from './program-increment.model'

export const loadProgramIncrements = createAction(
  '[ProgramIncrement/API] Load ProgramIncrements'
)
export const successLoadProgramIncrements = createAction(
  '[ProgramIncrement/API] Success Load ProgramIncrements',
  props<{ programIncrements: ProgramIncrement[] }>()
)

export const addProgramIncrement = createAction(
  '[ProgramIncrement/API] Add ProgramIncrement',
  props<{ programIncrement: ProgramIncrement }>()
)

export const successAddProgramIncrement = createAction(
  '[ProgramIncrement/API] Success Add ProgramIncrement',
  props<{ programIncrement: ProgramIncrement }>()
)

export const upsertProgramIncrement = createAction(
  '[ProgramIncrement/API] Upsert ProgramIncrement',
  props<{ programIncrement: ProgramIncrement }>()
)
export const successUpdateProgramIncrement = createAction(
  '[ProgramIncrement/API] Success Update ProgramIncrement',
  props<{ programIncrement: ProgramIncrement }>()
)

export const addProgramIncrements = createAction(
  '[ProgramIncrement/API] Add ProgramIncrements',
  props<{ programIncrements: ProgramIncrement[] }>()
)

export const upsertProgramIncrements = createAction(
  '[ProgramIncrement/API] Upsert ProgramIncrements',
  props<{ programIncrements: ProgramIncrement[] }>()
)

export const updateProgramIncrement = createAction(
  '[ProgramIncrement/API] Update ProgramIncrement',
  props<{ programIncrement: Update<ProgramIncrement> }>()
)

export const updateProgramIncrements = createAction(
  '[ProgramIncrement/API] Update ProgramIncrements',
  props<{ programIncrements: Update<ProgramIncrement>[] }>()
)

export const deleteProgramIncrement = createAction(
  '[ProgramIncrement/API] Delete ProgramIncrement',
  props<{ id: string }>()
)

export const deleteProgramIncrements = createAction(
  '[ProgramIncrement/API] Delete ProgramIncrements',
  props<{ ids: string[] }>()
)
export const successDeleteProgramIncrement = createAction(
  '[ProgramIncrement/API] Sucess Delete ProgramIncrement',
  props<{ id: string }>()
)

export const clearProgramIncrements = createAction(
  '[ProgramIncrement/API] Clear ProgramIncrements'
)

export const errorCatch = createAction(
  '[ProgramIncrement/API] Error Program Increment',
  props<{ error: string }>()
)
