import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Objective } from './objective.model'

export const loadObjectives = createAction('[Objective/API] Load Objectives')
export const successLoadObjectives = createAction(
  '[Objective/API] Success Load Objectives',
  props<{ objectives: Objective[] }>()
)

export const successUpsertObjective = createAction(
  '[Objective/API] Success Upsert Objectives',
  props<{ objective: Objective }>()
)

export const successAddObjective = createAction(
  '[Objective/API] Success Add Objective',
  props<{ objective: Objective }>()
)

export const addObjective = createAction(
  '[Objective/API] Add Objective',
  props<{ objective: Objective }>()
)

export const upsertObjective = createAction(
  '[Objective/API] Upsert Objective',
  props<{ objective: Objective }>()
)

export const addObjectives = createAction(
  '[Objective/API] Add Objectives',
  props<{ objectives: Objective[] }>()
)

export const upsertObjectives = createAction(
  '[Objective/API] Upsert Objectives',
  props<{ objectives: Objective[] }>()
)

export const updateObjective = createAction(
  '[Objective/API] Update Objective',
  props<{ objective: Update<Objective> }>()
)

export const updateObjectives = createAction(
  '[Objective/API] Update Objectives',
  props<{ objectives: Update<Objective>[] }>()
)

export const deleteObjective = createAction(
  '[Objective/API] Delete Objective',
  props<{ id: string }>()
)

export const deleteObjectives = createAction(
  '[Objective/API] Delete Objectives',
  props<{ ids: string[] }>()
)
export const successDeleteObjective = createAction(
  '[Objective/API] Success Delete Objective',
  props<{ id: string }>()
)

export const clearObjectives = createAction('[Objective/API] Clear Objectives')
