/* eslint-disable @typescript-eslint/naming-convention */
import { KanbanitemBase, RequirementType } from './item/requirement.model'
import { Task, TaskStatus } from '../../portfolio-store/portfolio/Task'

import { Id } from '../../arts-store/art.model'
import { PortfolioKanban } from '../../../kanban/store/kanban.model'
import {
  KeyResult,
  StrategicTheme
} from '../../strategic-themes-store/store/strategic-themes.model'
import {
  DevelopmentValueStreamWithSolutions,
  ValuestreamWithSolutions
} from '../../value-stream-store/store/valuestream.model'
import { Solution } from '../../solutions-store/store/solutions.model'
import { HistoryAudit } from '../../history-audit-store/history-audit.model'
import { ProgramIncrement } from '../../program-increment-store/store/pi/program-increment.model'
export type ProgramKanbanStates =
  | 'Funnel'
  | 'Analyzing'
  | 'Program Backlog'
  | 'Implementing'
  | 'Validation on Stage'
  | 'Deploying to production'
  | 'Releasing'
  | ' Done'

export type DoReady = 'todo' | 'inProgress' | 'ready'

export type PatchOperations =
  | 'add'
  | 'remove'
  | 'replace'
  | 'copy'
  | 'move'
  | 'test'
export interface Patch<T = any> {
  op: PatchOperations
  path: string
  value: Partial<T>
}
// eslint-disable-next-line no-shadow
export enum EnablerStoryType {
  Spike = 'spike',
  Refactoring = 'refactoring',
  Performance = 'performance',
  Configuration = 'configuration',
  Execute = 'execution',
  DevOps = 'devOps',
  Defect = 'defect'
}

export enum Priority {
  maximum = 1,
  high = 2,
  medium = 3,
  low = 4
}
export type PriorityId =
  | Priority.high
  | Priority.medium
  | Priority.low
  | Priority.maximum

export type Type<T> = new (...args: any[]) => T
export class DomainConverter {
  static fromDto<T>(domain: Type<T>, dto: any) {
    const instance = Object.create(domain.prototype)
    instance.state = dto
    return instance as T
  }
  static toDto<T>(domain: any) {
    return domain.state as T
  }
}
export type Requirement =
  | 'userstory'
  | 'feature'
  | 'capability'
  | 'epic'
  | 'enablerstory'
  | 'portfolioEpic'
  | 'programEpic'
  | 'solutionEpic'
  | ''

export type FeatureContext =
  | 'continuous improvement'
  | 'technologic evolution'
  | 'others'

export type TypeRequirement = EpicC | FeatureC | UserStoryC

export type Epic = EpicI<FeatureI<StoryT>>
export type Feature = FeatureI<StoryT>
export type UserStory = UserStoryI<TaskI>
export type EnablerStory = EnablerStoryI<TaskI>

export type Story = UserStory | EnablerStory

export type RequirementI = Epic | UserStory | Feature | EnablerStory

export type RequirementForBudget = Epic & {
  strategicTheme: StrategicTheme
  valueStreamObjects: DevelopmentValueStreamWithSolutions[]
  solutions: Solution[][]
  strategicThemeDescription: string
  portfolioName: string
}

export function isFeatureI(item: RequirementI): item is Feature {
  return item.name === 'feature'
}
export function isEpicI(item: RequirementI): item is Epic {
  return item.name === 'epic'
}
export function isUserStoryI(item: RequirementI): item is UserStory {
  return item.name === 'userstory'
}
export function isEnablerStoryI(item: RequirementI): item is EnablerStory {
  return item.name === 'enablerstory'
}

export type EpicType = 'solutionEpic' | 'portfolioEpic' | 'programEpic'

export type FeatureWithPIAndThemes = Feature & {
  pi: ProgramIncrement
  theme: StrategicTheme
}

export interface EconomicalData {
  id: string

  /**
   *  Allocated budget
   */
  allocatedBudget?: number

  /**
   *  Actual cost
   */
  actualCost?: number

  /**
   *  @deprecated this property should not be used
   */
  productOrSolution?: string

  /**
   * Estimated total final cost of the Epic
   *  @deprecated this property should not be used
   */
  estimatedFinalCost?: number

  /**
   *  Estimated at completion
   */
  estimatedAtCompletion?: number
}

export interface LinkedFeature {
  ownerId?
  relationId?
  feature: RequirementI
  relationType: relationType
}

export type relationType = 'dependsOn' | 'isRelatedTo'
export interface Relation {
  relationId?
  relatedItemId: string
  relationType: relationType
}

export interface KanbanItemI extends KanbanitemBase {
  /**
   *  Owner / Creator of the requirement card with all privileges (edit/delete)
   */
  readonly ownerId: string

  /**
   *  Code of the requirement
   */
  readonly code: string

  /**
   *  Color of the requirement card
   */
  readonly color?: string

  /**
   *  Name of the column where item is hanged Kanban
   */
  readonly state?: string

  /**
   * Full key result
   */

  readonly keyResult?: KeyResult

  /**
   * reference to a key result
   *
   */

  readonly keyResultId?

  readonly history?: HistoryAudit[]
}
export interface KanbanItemFunctions {
  pendingTasks(): boolean
  numChilds(): number
}
export interface EpicI<T extends KanbanItemI> extends KanbanItemI {
  // (arg: T): T;
  readonly name: 'epic'

  /**
   * WSJF
   */
  readonly WSJF: string

  /**
   *  portfolioId .  Required property will be always informed
   */
  readonly portfolioId: string
  /**
   * Name of owner
   */
  readonly epicOwnerId: string

  /**
   * Forecasted Epic Cost
   */
  readonly forecastedEpicCost?: number

  /**
   * Expected Benefit
   */
  readonly expectedBenefit?: number

  /**
   * Forecasted Epic Duration In Story Points
   */
  readonly forecastedEpicDurationInStoryPoints?: number

  /**
   * Forecasted Epic Duration In time (number of pi's)
   */
  readonly forecastedEpicDurationInTime?: number

  /* 
  Clearly articulate the main issue or challenge that needs to be addressed by this epic.
  */

  readonly problemStatement?: string

  /* Business OutCome Hypothesis */
  readonly businessOutComeHypothesis?: string

  /* Leading Indicators */
  readonly leadingIndicators?: string

  /* Non functional requirements */
  readonly nonFunctionalRequirements?: string

  /**
   * Epic Type
   */
  readonly epicType: EpicType

  readonly children?: T[]

  readonly notes?: string

  /**
   *   Kanban Container
   *  @deprecated
   */
  readonly kanban?: PortfolioKanban

  /**
   *  Determines whether this Epic is assigned to a Value Stream or not
   */
  readonly assigned: boolean

  /**
   *  Determines whether this Epic is assigned to an ART or not
   */
  readonly assignedToArt: boolean

  /**
   *  Array of of assigneds Ids Value Stream
   */

  readonly valueStreams?: Id[]

  /**
   *  Epic Due Date
   */
  readonly dueDate?: Date

  /**
   *  Epic Start Date
   */
  readonly startDate?: Date

  /**
   *  Reference to strategic Theme
   */

  readonly strategicThemeId?

  /**
   *  Economical data of the Epic
   */
  readonly economicalData?: EconomicalData
}

export interface FeatureI<T extends KanbanItemI> extends KanbanItemI {
  // (arg: T): T;
  readonly name: 'feature'
  // description: string

  /**
   *  Acceptance Criteria
   */
  readonly acceptanceCriteria: string

  /**
   *  Benefit Hypothesis
   */
  readonly benefitHypothesis: string

  readonly children?: T[]

  /**
   *  Definition of ready
   */
  readonly dor?: DoReady

  /**
   *  List of Teams which have this Feature assigned
   */
  readonly teams?: Id[]

  /**
   *  Program Increment Id
   */
  readonly programIncrementId?: string

  /**
   *  Context of Feature
   */
  readonly context?: FeatureContext

  /**
   * Normalized Story Points
   */
  readonly normalizedStoryPoints?: string
  /**
   * Name of owner
   */
  readonly featureOwnerId?: string

  readonly notes?: string

  /**
   * whether is or not is a MVP feature
   */
  readonly isMvp?: boolean

  readonly relations?: Relation[]
}
export interface StoryI extends KanbanItemI {
  description: string
  acceptanceCriteria: string
  priorityId: PriorityId | null
  storyPoints: number
  notes?: string
  teamId?: any
}
export interface TaskI extends KanbanItemI {
  description: string
  hours: number
  storyPoints: number
}
export interface UserStoryI<T extends KanbanItemI> extends StoryI {
  name: 'userstory'
  children?: T[]
}
export interface EnablerStoryI<T extends KanbanItemI> extends StoryI {
  name: 'enablerstory'
  enablerType: EnablerStoryType
  children?: T[]
}
export type StoryT = EnablerStoryI<TaskI> | UserStoryI<TaskI>

const epicDto: EpicI<FeatureI<StoryI>> = {
  code: 'code',
  ownerId: '1112',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // MVPFeatures: '',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WSJF: '',
  columnId: '',
  creationDate: new Date(),
  description: '',
  id: '',
  itemName: '',
  kanbanId: '',
  lastUpdate: new Date(),
  locked: false,
  solutionId: '',
  type: 'business',
  toKanban: false,
  name: 'epic',
  portfolioId: '',
  assigned: false,
  valueStreams: [],
  assignedToArt: false,
  epicType: 'portfolioEpic',
  inRoadmap: false,
  epicOwnerId: ''
}
export abstract class Item<K extends KanbanItemI>
  implements KanbanItemI, KanbanItemFunctions {
  // protected state: T
  id: string
  code: string
  description: string
  name: Requirement
  ownerId: string
  itemName: string
  kanbanId: string
  columnId: string
  toKanban: boolean
  type: RequirementType
  locked: boolean
  children?: K[]
  solutionId: string
  creationDate?: Date
  lastUpdate?: Date
  parentId?: string
  tasks?: Task[]
  inRoadmap: boolean
  numeration?: string
  color?: string
  state?: string
  numChildren?: number
  teams?: Id[]
  dor?: DoReady

  constructor(
    id: string,
    code: string,
    name: Requirement,
    itemName: string,
    description: string,
    locked: boolean,
    solutionId: string,
    columnId: string,
    toKanban: boolean,
    type: RequirementType,
    ownerId: string,
    parentId: string,
    kanbanId: string,
    inRoadmap: boolean,
    numeration?: string,
    creationDate?: Date,
    lastUpdate?: Date,
    color?: string,
    children?: K[],
    state?: string,
    numChildren?: number,
    teams?: Id[],
    dor?: DoReady
  ) {
    this.setId(id)
    this.setCode(code)
    this.setItemName(name)
    this.setItemName(itemName)
    this.setDescription(description)
    this.setLocked(locked)
    this.setSolutionId(solutionId)
    this.setColumnId(columnId)
    this.setToKanban(toKanban)
    this.setType(type)
    this.setName(name)
    this.setOwnerId(ownerId)
    this.setParentId(parentId)
    this.setKanbanId(kanbanId)
    this.setNumeration(numeration)
    this.setState(state)
    this.lastUpdate = lastUpdate
    this.creationDate = creationDate
    this.inRoadmap = inRoadmap
    this.color = color
    this.children = children
    // this.state = state
    this.numChildren = numChildren
    this.teams = teams
    this.dor = dor
  }

  getDor() {
    return this.dor
  }

  setSolutionId(solutionId: string) {
    this.solutionId = solutionId
  }
  setKanbanId(kanbanId: string) {
    this.kanbanId = kanbanId
  }
  setOwnerId(ownerId: string) {
    this.ownerId = ownerId
  }
  setParentId(parentId: string) {
    this.parentId = parentId
  }
  setColumnId(columnId: string) {
    this.columnId = columnId
  }
  setToKanban(toKanban: boolean) {
    this.toKanban = toKanban
  }
  setType(type: RequirementType) {
    this.type = type
  }
  setName(name: Requirement) {
    this.name = name
  }
  setId(id: string) {
    this.id = id
  }
  setItemName(itemName: string) {
    this.itemName = itemName
  }
  setInRoadmap(inRoadmap: boolean) {
    this.inRoadmap = inRoadmap
  }
  setCode(code: string) {
    this.code = code
  }
  setNumeration(numeration: string) {
    this.numeration = numeration
  }
  setChildren(children) {
    this.children = children
  }
  setState(state) {
    this.state = state
  }
  getCode() {
    return this.code
  }
  getinRoadmap() {
    return this.inRoadmap
  }
  getId() {
    return this.id
  }
  getParentId() {
    return this.parentId
  }

  getItemName() {
    return this.itemName
  }
  getSolutionId() {
    return this.solutionId
  }
  getDescription() {
    return this.description
  }

  isLocked() {
    return this.locked
  }
  isToKanban() {
    return this.toKanban
  }
  getType() {
    return this.type
  }

  getColumnId() {
    return this.columnId
  }
  getName() {
    return this.name
  }
  getChildren(): K[] {
    return this.children as K[]
  }
  getNumberofChildren() {
    if (this.children) return this.children.length
    else return 0
  }
  getNumChildren() {
    if (this.numChildren) return this.numChildren
    else return 0
  }

  setLocked(locked: boolean) {
    this.locked = locked
  }
  setDescription(description: string) {
    this.description = description
  }

  /*
  setChildren(children: Kanbanitem[]) {
    //console.log('childer', this.state.children);
    if (this.children === undefined) {
         //this = { ...this, children };
    }else{
      this.children.push({...children});
    }
    //this.state.children.push(...children);
  }
  */
  pendingTasks(): boolean {
    if (
      this.getNumPending() > 0 ||
      this.getNumProgress() > 0 ||
      this.getCompleted() > 0
    )
      return true
    else return false
    // let pending:Boolean=true;
    /*
    let it = 0
    this.tasks?.forEach((element) => {
      if (
        (element.status === TaskStatus.inProgress ||
          element.status === TaskStatus.todo) &&
        element.idColumn === this.columnId
      ) {
        it++
      }
    })

    if (it > 0) {
      return true
    } else {
      return false
    }*/
  }
  numChilds(): number {
    return this.children?.length
  }
  getNumeration() {
    return this.numeration
  }
  getLastUpdate() {
    return this.lastUpdate
  }

  getCreationDate() {
    return this.creationDate
  }

  getColor() {
    return this.color
  }
  getNumPending() {
    if (this.children)
      return this.children.filter(
        (element) =>
          element.state === 'Funnel' ||
          element.state === 'Analyzing' ||
          element.state === 'Program Backlog'
      ).length
    else return 0
  }
  getNumProgress() {
    // let it = 0
    if (this.children)
      return this.children.filter(
        (element) =>
          element.state === 'Implementing' ||
          element.state === 'Validating on stage' ||
          element.state === 'Deploying to' ||
          element.state === 'Integrate and test' ||
          element.state === 'Deploying to production'
      ).length
    else return 0

    // return this.children.filter(element => element.code==='1').length

    // return it
  }
  getCompleted() {
    // let it = 0
    if (this.children)
      return this.children.filter(
        (element) => element.state === 'Done' || element.state === 'Accepted'
      ).length
    else return 0

    // return this.children.filter(element => element.code==='1').length

    // return it
  }
  getState() {
    return this.state
  }
  showProgress() {
    if (
      this.state === 'Review' ||
      this.state === 'Build' ||
      this.state === 'Implementing' ||
      this.state === 'Validating on Stage' ||
      this.state === 'Deploying to Production' ||
      this.state === 'Done'
    ) {
      return true
    } else return false
  }
  getKanbanId() {
    return this.kanbanId
  }
}

export type RequirementClass = EpicC | UserStoryC | FeatureC | EnablerStoryC
export type StoryClass = UserStoryC | EnablerStoryC

export function isFeature(item: RequirementClass): item is FeatureC {
  return item.getName() === 'feature'
}
export function isUserStory(item: RequirementClass): item is UserStoryC {
  return item.getName() === 'userstory'
}
export function isEpic(item: RequirementClass): item is EpicC {
  return item.getName() === 'epic'
}
export function isEnablerStory(item: RequirementClass): item is EnablerStoryC {
  return item.getName() === 'enablerstory'
}

function displayKanbanItem(item: RequirementClass) {
  if (isFeature(item)) {
    item.getDescription()
  } else if (isUserStory(item)) item.getStoryPoints()
}

/*
const epicR: EpicR = {
  WSJF: '',
  MVPFeatures: '',
  assigned: true,
  assignedToArt: true,
  epicOwner: 'dasd',
  epicType: 'portfolioEpic',
  valueStreamId: ''
}
*/
export class EpicC
  extends Item<FeatureI<StoryT>>
  implements EpicI<FeatureI<StoryT>> {
  // children?: FeatureI<UserStoryI<TaskI>>[];
  name: 'epic'

  // eslint-disable-next-line @typescript-eslint/naming-convention
  // MVPFeatures: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WSJF: string
  problemStatement?: string
  businessOutComeHypothesis?: string
  portfolioId: string
  assigned: boolean
  valueStreams: Id[]
  forecastedEpicDurationInTime: number
  forecastedEpicCost: number
  expectedBenefit: number
  assignedToArt: boolean
  forecastedEpicDurationInStoryPoints?: number
  epicType: EpicType
  kanban: PortfolioKanban
  epicOwnerId: string
  ownerId: string
  numeration: string
  strategicThemeId
  dueDate?: Date
  startDate?: Date
  economicalData?: EconomicalData
  keyResult?: KeyResult

  constructor({
    id,
    code,
    itemName,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    // MVPFeatures,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    WSJF,
    businessOutComeHypothesis,
    problemStatement,
    description,
    locked,
    solutionId,
    columnId,
    toKanban,
    type,
    ownerId,
    parentId,
    portfolioId,
    kanbanId,
    assigned,
    valueStreams,
    forecastedEpicDurationInTime,
    forecastedEpicCost,
    expectedBenefit,
    assignedToArt,
    epicType,
    inRoadmap,
    kanban,
    epicOwnerId,
    numeration,
    creationDate,
    lastUpdate,
    color,
    children,
    numChildren,
    state,
    strategicThemeId,
    startDate,
    dueDate,
    economicalData,
    keyResult
  }: EpicI<FeatureI<StoryT>>) {
    super(
      id,
      code,
      'epic',
      itemName,
      description,
      locked,
      solutionId,
      columnId,
      toKanban,
      type,
      ownerId,
      parentId,
      kanbanId,
      inRoadmap,
      numeration,
      creationDate,
      lastUpdate,
      color,
      children,
      state,
      numChildren
    )
    Object.assign(this, {
      id,
      itemName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // MVPFeatures,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      WSJF,
      businessOutComeHypothesis,
      problemStatement,
      portfolioId,
      assigned,
      valueStreams,
      forecastedEpicDurationInTime,
      forecastedEpicCost,
      expectedBenefit,
      assignedToArt,
      epicType,
      kanban,
      epicOwnerId,
      strategicThemeId,
      startDate,
      dueDate,
      economicalData,
      keyResult
    })
  }

  isCoherent() {
    return true
  }

  isAssigned(entity: string) {
    if (entity === 'ART') {
      return this.assignedToArt
    } else if (entity === 'VA') {
      return this.assigned
    }
  }

  getWSJF() {
    return this.WSJF
  }
  setChildren(children: FeatureI<StoryT>[]) {
    this.children = children
  }

  public getDueDate(): Date {
    return new Date(this.dueDate)
  }
  public isOverdue() {
    // this.dueDateExpired =
    //   this.itemC.getDueDate().getTime() > new Date().getTime() ? false : true
    const currentDate = new Date()
    if (currentDate > new Date(this.dueDate)) return true
    else return false
  }

  getValueStreams() {
    return this.valueStreams
  }
}

export class FeatureC extends Item<StoryT> implements FeatureI<StoryT> {
  name: 'feature'
  numeration: string
  acceptanceCriteria: string
  benefitHypothesis: string
  teams?: Id[]
  dor?: DoReady
  context?: FeatureContext
  normalizedStoryPoints?: string
  notes: string

  constructor({
    id,
    code,
    itemName,
    description,
    acceptanceCriteria,
    benefitHypothesis,
    locked,
    solutionId,
    columnId,
    toKanban,
    type,
    ownerId,
    parentId,
    kanbanId,
    inRoadmap,
    numeration,
    creationDate,
    lastUpdate,
    color,
    children,
    numChildren,
    state,
    teams,
    dor,
    context,
    normalizedStoryPoints,
    notes
  }: FeatureI<StoryT>) {
    super(
      id,
      code,
      'feature',
      itemName,
      description,
      locked,
      solutionId,
      columnId,
      toKanban,
      type,
      ownerId,
      parentId,
      kanbanId,
      inRoadmap,
      numeration,
      creationDate,
      lastUpdate,
      color,
      children,
      state,
      numChildren
    )
    Object.assign(this, {
      acceptanceCriteria,
      benefitHypothesis,
      teams,
      dor,
      context,
      normalizedStoryPoints,
      notes
    })
  }

  getAcceptanceCriteria() {
    return this.acceptanceCriteria
  }

  getBenefitHypothesis() {
    return this.benefitHypothesis
  }
  setChildren(children: UserStoryC[] | EnablerStoryC[]) {
    this.children = children
  }
  getItemName() {
    return this.description
  }
}

export class UserStoryC extends Item<TaskI> implements UserStoryI<TaskI> {
  name: 'userstory'
  priorityId: number
  storyPoints: number
  acceptanceCriteria: string
  numeration: string
  // benefitHypothesis: string
  // children?: TaskI[];

  constructor({
    id,
    code,
    itemName,
    description,
    acceptanceCriteria,
    priorityId,
    storyPoints,
    locked,
    solutionId,
    columnId,
    toKanban,
    type,
    ownerId,
    parentId,
    kanbanId,
    inRoadmap,
    numeration,
    creationDate,
    lastUpdate,
    color,
    children,
    numChildren,
    state
  }: UserStoryI<TaskI>) {
    super(
      id,
      code,
      'userstory',
      itemName,
      description,
      locked,
      solutionId,
      columnId,
      toKanban,
      type,
      ownerId,
      parentId,
      kanbanId,
      inRoadmap,
      numeration,
      creationDate,
      lastUpdate,
      color,
      children,
      state,
      numChildren
    )
    Object.assign(this, {
      acceptanceCriteria,
      priorityId,
      storyPoints
    })
  }

  getAcceptanceCriteri() {
    return this.acceptanceCriteria
  }

  getStoryPoints() {
    return this.storyPoints
  }
  getPriorityId() {
    return this.priorityId
  }
}

type EnablerStoryConstructor = new ({
  id,
  itemName,
  description,
  acceptanceCriteria,
  priorityId,
  storyPoints,
  locked,
  solutionId,
  columnId,
  toKanban,
  type,
  ownerId,
  parentId,
  kanbanId,
  inRoadmap,
  numeration,
  creationDate,
  lastUpdate,
  enablerType
}: EnablerStoryI<TaskI>) => EnablerStoryC

export function enablerStoryCreator(
  ctor: EnablerStoryConstructor,
  enabler: EnablerStoryI<TaskI>
) {
  return new ctor(enabler)
}

const x = {
  acceptanceCriteria: '',
  columnId: '',
  description: '',
  enablerType: EnablerStoryType.Spike,
  id: '',
  inRoadmap: true,
  itemName: 'itemName',
  kanbanId: '',
  locked: false,
  name: 'enablerstory',
  ownerId: 'jordi',
  priorityId: 0,
  storyPoints: 1,
  solutionId: '',
  toKanban: false,
  type: 'business'
}

export class EnablerStoryC extends Item<TaskI> implements EnablerStoryI<TaskI> {
  enablerType: EnablerStoryType
  name: 'enablerstory'
  priorityId: number
  storyPoints: number
  acceptanceCriteria: string
  benefitHypothesis: string
  numeration: string

  constructor({
    id,
    code,
    itemName,
    description,
    acceptanceCriteria,
    priorityId,
    storyPoints,
    locked,
    solutionId,
    columnId,
    toKanban,
    type,
    ownerId,
    parentId,
    kanbanId,
    inRoadmap,
    creationDate,
    numeration,
    lastUpdate,
    enablerType,
    color,
    children,
    state,
    numChildren
  }: EnablerStoryI<TaskI>) {
    super(
      id,
      code,
      'enablerstory',
      itemName,
      description,
      locked,
      solutionId,
      columnId,
      toKanban,
      type,
      ownerId,
      parentId,
      kanbanId,
      inRoadmap,
      numeration,
      creationDate,
      lastUpdate,
      color,
      children,
      state,
      numChildren
    )
    Object.assign(this, {
      acceptanceCriteria,
      priorityId,
      storyPoints,
      enablerType
    })
  }

  getAcceptanceCriteri() {
    return this.acceptanceCriteria
  }

  getStoryPoints() {
    return this.storyPoints
  }
  getPrioritzacionId() {
    return this.storyPoints
  }
}
/*
export class Story implements KanbanitemBase {
  description: string
  acceptanceCriteria: string
  priorityId: number
  storyPoints: number
  featureId: string
  columnId: string
  solutionId: string
  storyName: string
  inRoadmap: boolean

  creationDate: Date
  lastUpdate: Date
  id: string
  name: Requirement
  itemName: string
  kanbanId: string
  toKanban: boolean
  type: RequirementType
  locked: boolean
  children?: KanbanitemBase[]
  parentId?: string
  tasks?: Task[]

  constructor(
    id: string,
    name: Requirement,
    storyName: string,
    description: string,
    acceptanceCriteria: string,
    type: RequirementType,
    storyPoints: number,
    featureId: string,
    columnId: string,
    kanbanId: string,
    toKanban: boolean,
    solutionId: string,
    inRoadmap: boolean,
    priorityId: number
  ) {
    this.name = name
    this.id = id
    this.storyName = storyName
    this.kanbanId = kanbanId
    this.solutionId = solutionId
    this.toKanban = toKanban
    this.description = description
    this.storyPoints = storyPoints
    this.featureId = featureId
    this.columnId = columnId
    this.acceptanceCriteria = acceptanceCriteria
    this.inRoadmap = inRoadmap
    this.type = type
    this.priorityId = priorityId
  }

  pendingTasks(): boolean {
    return true
  }
  numChilds(): number {
    return 0
  }
}
*/
/*
export class UserStory extends Story {
  constructor(
    id: string,
    storyName: string,
    description: string,
    acceptanceCriteria: string,
    type: RequirementType,
    storyPoints: number,
    featureId: string,
    columnId: string,
    kanbanId: string,
    backlogId: string,
    order: number,
    toKanban: boolean,
    solutionId: string,
    inRoadmap: boolean,
    priorityId: number
  ) {
    super(
      id,
      'userstory',
      storyName,
      description,
      acceptanceCriteria,
      type,
      storyPoints,
      featureId,
      columnId,
      kanbanId,
      toKanban,
      solutionId,
      inRoadmap,
      priorityId
    )
  }
}

export class EnablerStory extends Story {
  enablertype: EnablerStoryType
  order: number
  constructor(
    id: string,
    storyName: string,
    description: string,
    acceptanceCriteria: string,
    type: RequirementType,
    storyPoints: number,
    featureId: string,
    columnId: string,
    kanbanId: string,
    order: number,
    toKanban: boolean,
    solutionId: string,
    inRoadmap: boolean,
    enablertype: EnablerStoryType,
    priorityId: number
  ) {
    super(
      id,
      'enablerstory',
      storyName,
      description,
      acceptanceCriteria,
      type,
      storyPoints,
      featureId,
      columnId,
      kanbanId,
      toKanban,
      solutionId,
      inRoadmap,
      priorityId
    )
    this.enablertype = enablertype
    this.order = order
  }
}
*/
export class Capability implements KanbanitemBase {
  description: string

  acceptanceCriteria: string

  benefitHypothesis: string

  epicId: string

  features?: FeatureC[]

  columnId: string

  inRoadmap: boolean
  id: string
  name: Requirement
  itemName: string
  kanbanId: string
  toKanban: boolean
  locked: boolean
  solutionId: string
  creationDate: Date
  lastUpdate: Date
  type: RequirementType
  children?: KanbanitemBase[]
  parentId?: string
  tasks?: Task[]

  constructor(
    id: string,
    itemName: string,
    description: string,
    acceptanceCriteria: string,
    benefitHypothesis: string,
    epicId: string,
    kanbanId: string,
    columnId: string,
    toKanban: boolean,
    solutionId: string,
    features?: FeatureC[]
  ) {
    this.itemName = itemName
    this.name = 'capability'
    this.description = description
    this.kanbanId = kanbanId
    this.solutionId = solutionId
    this.toKanban = toKanban
    this.id = id
    this.description = description
    this.acceptanceCriteria = acceptanceCriteria
    this.benefitHypothesis = benefitHypothesis
    this.id = id
    this.epicId = epicId
    // this.features = features;
    this.kanbanId = kanbanId
    this.columnId = columnId
    this.toKanban = toKanban
    this.itemName = description
  }

  pendingTasks(): boolean {
    return true
  }
  numChilds(): number {
    return 0
  }
}
