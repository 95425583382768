<div class="main-wrapper p-1">
    <form [formGroup]="form" class="default-form" (ngSubmit)="save()">
      <div class="form-fields">
        <div class="d-flex flex-column flex-md-row px-0 form-fields-inner  justify-content-between">
          <div class="d-flex flex-column px-0 flex-grow-1">
            <h2 class="px-1">Move to other Kanban</h2>
            <div *ngIf="kanbans$ |async as kanbans">
              <div class="px-0">
                <mat-form-field appearance="fill" class="px-0">
                  <mat-select formControlName="kanbanId" (selectionChange)="changeKanban($event.value)">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let kanban of kanbans" [value]="kanban.id">
                      {{kanban.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('kanbanId').invalid">
                    {{ 'anms.kanban-change.form.placeholder.kanbanId' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column px-0 flex-grow-1">

            <h2 class="px-1">Select Destination State </h2>
            <div *ngIf="states$ |async as states">
              <div class="px-0">
                <mat-form-field appearance="fill" class="px-0">
                  <mat-select formControlName="columnId" (selectionChange)="updateItem($event.value)">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let state of states" [value]="state.id">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('columnId').invalid">
                    {{ 'anms.kanban-change.form.placeholder.columnId' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


        </div>


        </div>



      </div>
      <div class="form-actions">
        <div class="action-col secondary">
          <button (click)=" closeDialog()" mat-button color="primary" type="button" class="btn-save" id="close">
            <span class="material-icons">close</span>
            Cancel
          </button>


        </div>
        <div class="action-col primary">
          <button  mat-raised-button color="primary" type="submit" class="btn-save" id="save">
            <span class="material-icons">check</span>
            Move
          </button>
          </div>

      </div>

    </form>


</div>
