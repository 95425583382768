<div class="main-wrapper p-1">
  <form [formGroup]="form" (ngSubmit)="onSubmit($event)" class="default-form" [ngClass]="{'update-form' : isUpdate}">
    <div class="form-fields">
      <div class="form-fields-inner d-flex flex-column flex-lg-row">

          <div class="left-section form-main px-md-2">
            <div>
             
              <div class="main-heading mb-0 d-flex flex-column flex-xl-row mx-0">
                <ng-container *ngIf="isUpdate; else isNew">
                  <div class="flex-grow-1">
                  <anms-breadcrum-requirement [requirement]="feature" (updateDescription)="updateDescriptionFeature($event)" [isUpdate]="isUpdate"></anms-breadcrum-requirement>
                </div>
                </ng-container>
                <ng-template   #isNew>
                  <span class="align-self-center px-2">
                    {{'anms.feature.form.title' | translate }}
                  </span>
                  <p class="description align-self-center px-2">{{ 'anms.feature.form.additionalinfo' | translate }}
                  </p>
                </ng-template>
                <div class="d-flex flex-column flex-xl-row">
                <ng-container *ngIf="solutions$| async as solutions">
                  
                  <mat-form-field appearance="fill" class="px-0 w-100 flex-xl-grow-1">
                    <mat-label>Solution</mat-label>
                    <mat-select formControlName="solutionId" (selectionChange)="onChangeSolution($event)">
                      <mat-option value="">
                        None
                      </mat-option>
                      <mat-option *ngFor="let solution of solutions" [value]="solution.id">
                      {{solution.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>Solution to which this feature belongs.</mat-hint>
                    <mat-error *ngIf="form.get('solutionId').invalid">
                      {{ 'anms.requirements.feature.form.soutionId' | translate }}
                      {{ 'anms.examples.form.error1' | translate }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>

                <ng-container *ngIf="epics$ | async as epics">
                  <mat-form-field appearance="fill" class="px-0 w-100 flex-xl-grow-1">
                    <mat-label>Epic</mat-label>
                    <mat-select formControlName="parentId">
                      <mat-option value="">
                        None
                      </mat-option>
                      <mat-option *ngFor="let epic of epics" [value]="epic.id">
                        {{epic.numeration}} - {{epic.itemName}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>Epic Parent</mat-hint>
                    <mat-error *ngIf="form.get('parentId').invalid">
                      {{ 'anms.requirements.feature.form.parentId' | translate }}
                      {{ 'anms.examples.form.error1' | translate }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
              </div>
            </div>

            <div class="contextual-menu">
              <button *ngIf="isUpdate" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container *ngIf="epics$ | async as epics ">
                  <button mat-menu-item (click)="changeParentDialogRef(origin1, epics)" #origin1>
                    <mat-icon>change_circle</mat-icon>
                    <span>Change Parent</span>
                  </button>
                </ng-container>
                  <button mat-menu-item (click)="changeKanbanDialogRef(origin2)" #origin2>
                    <mat-icon>change_circle</mat-icon>
                    <span>Change Kanban</span>
                  </button>
                </mat-menu>
            </div>

            <div class="d-flex justify-content-between flex-column flex-lg-row">
              <div *ngIf="isUpdate" class="d-flex justify-content-start">
                <div  class="upload-file-field mb-2">
                  <input class="upload-file-input" id="fileUpload" placeholder="upload a file" type="file" formControlName="fileSource"
                  (change)="onHandleFile($event)">
                  <span class="material-icons selected-file">
                    attach_file
                  </span>
                </div>
                <div  class="upload-file-field mb-2">
                  <div class="link-feature-button" (click)="linkFeature(feature)"> </div>
                  <span class="material-icons link-feature">
                    hub
                    </span>
                </div>
              </div>
              <ng-container *ngIf="isUpdate">
                <ng-container *ngIf="states$ | async as states">
                  <mat-form-field appearance="fill" class="px-0">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="columnId" (selectionChange)="changeState($event)">
                      <mat-option *ngFor="let state of states" [value]="state.id">
                        {{state.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>State</mat-hint>
                    </mat-form-field>
                </ng-container>
              </ng-container>
            </div>
            <div class="mb-3">

            <mat-slide-toggle formControlName="isMvp" (change)="changeisMVP($event)">is MVP Feature</mat-slide-toggle>
          </div>

            <mat-form-field *ngIf="!isUpdate" appearance="fill" class="default-full-width">
              <mat-label>{{ 'anms.feature.form.description' | translate }}</mat-label>
              <input matInput placeholder="{{'anms.feature.form.description.placeholder' | translate }}"
                formControlName="description">
              <mat-hint>{{'anms.feature.form.description.placeholder' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('description').invalid">
                {{ 'anms.feature.form.description' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="default-full-width">
              <mat-label>{{ 'anms.feature.form.benefitHypothesis' | translate }}</mat-label>
              <textarea matInput placeholder="{{'anms.feature.form.benefitHypothesis.placeholder' | translate }}"
                formControlName="benefitHypothesis"
                     cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"  [mentionConfig]="mentionsConfig"></textarea>
              <mat-hint>{{ 'anms.feature.form.benefitHypothesis.placeholder' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('benefitHypothesis').invalid">
                {{ 'anms.feature.form.benefitHypothesis' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
            <div  class="acceptance-criteria-textarea-section mt-lg-3">
            <mat-form-field appearance="fill" class="default-full-width acceptance-criteria-field">
              <mat-label>{{ 'anms.feature.form.acceptanceCriteria' | translate }}</mat-label>
              <textarea id="froala-editor" #froalaEditor (froalaInit)="initFroala($event)" [froalaEditor]="froalaOptions"
                [(froalaModel)]="htmlTemplate" class="textarea-form" matInput
                formControlName="acceptanceCriteria" [mentionConfig]="mentionsConfig"></textarea>
              <mat-hint>{{ 'anms.feature.form.acceptanceCriteria.placeholder' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('acceptanceCriteria').invalid">
                {{ 'anms.feature.form.acceptanceCriteria' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
             
            </mat-form-field>
            <a  class="improve-acceptance-criteria-ai"
              
            (click)="generateAcceptanceCriteria(originI, form.get('benefitHypothesis').value)"
            #originI>
            Generate Acceptance Criteria
        </a>
            </div>

            <div class="attachment-wrapper" *ngIf="filesOfItem!==undefined && filesOfItem.length>0">
                <anms-attachment-card  *ngFor="let file of filesOfItem" class="mt-1 pr-1 mb-2 " [file]="file" (downloadFileEmmiter)="downLoadFile($event)"
                  (deleteFileEmmiter)="deleteFile($event)">
                </anms-attachment-card>
            </div>
            <div class="d-flex flex-column flex-md-row mt-3">
              <mat-form-field class="px-0 pb-2" appearance="fill">
                <mat-select formControlName="artId">
                  <mat-option *ngIf="currentArt$  | async as art" [value]="art.id">
                    {{art.name}}
                  </mat-option>
                  <mat-option value="">
                    None
                  </mat-option>
                </mat-select>
                <mat-hint>Art that this Feature belongs</mat-hint>
              </mat-form-field>
              <div class="px-0 py-0 mat-form-field team-selector-feature-form">
                <ng-container *ngIf="currentArt$ | async as art">
              

                  <anms-teams-select [teams]="art.teams" [value]="{artId:art.id}" formControlName="teams"
                    [disableCheck]="arrayOfChildrenWithTeams"
                    (patchTeams)="patchTeams($event)"></anms-teams-select>
                  <!--mat-error *ngIf="form.get('teams').invalid">
                          {{ 'anms.form.placeholder.team' | translate }}
                          {{ 'anms.examples.form.error1' | translate }}
                        </mat-error-->
                       
                </ng-container>
              </div>
            </div>

            <ng-container *ngIf="isUpdate">
              <div class="children-wrapper">
                <ng-container *ngIf="childrenItemList$ | async as children">
                  <div class="children-list">
                    <div class="d-flex px-0 mt-4 children-main">
                      <h3>Child Stories</h3>
                      <a (click)="addItem(feature.id)" class="btn-new"><span class="material-icons">add</span></a>
                    </div>
                    <ng-container *ngFor="let story of children; index as i">
                        <anms-story-row [input]="story" [teamsIds]=teamsIds (selectedTeamOut)="selectTeamForChildStory($event)">
                        </anms-story-row>
                    </ng-container>
                  </div>
                </ng-container>
                <div *ngIf="showAddFeatureMessage" class="mb-4">
                  <a (click)="addItem(feature.id)" class="px-2" style="cursor: pointer;color:#283593 !important;">
                   +  Add new Story
                  </a>
                </div>
                <div class="d-flex flex-column">
                  <div class="children-new">
                    <ng-template #dynamic></ng-template>
                  </div>
                </div>
              </div>

            <div class="children-wrapper">
              <ng-container *ngIf="linkedFeatures$ | async as linkedFeatures">
                <div class="children-list">
                  <div class="d-flex px-0 children-main">
                    <h3>Linked Features</h3>
                    <a (click)="linkFeature(feature)" class="btn-new"><span class="material-icons">add</span></a>
                  </div>
                  <ng-container *ngFor="let link of linkedFeatures; index as i">
                    <anms-linked-feature-row [link]="link"></anms-linked-feature-row>
                  </ng-container>
                </div>
              </ng-container>
              <div *ngIf="showLinkFeatureMessage" class="mb-4">
                <a (click)="linkFeature(feature)" class="px-2" style="cursor: pointer;color:#283593 !important;">
                 +  Link Feature
                </a>
              </div>
              
                <div class="d-flex flex-column">
                  <div class="children-new">
                    <ng-template #relatedDynamic></ng-template>
                  </div>
                </div>
            </div>
          </ng-container>
          <ng-container *ngIf="parent$ | async as parent; else showLink">
            <a class="add-stories-ai"  
               [ngClass]="{'disabled': description.invalid || acceptanceCriteria.invalid}"
               (click)="openBreakDownFeatureForm(originfeature, parent)"
               #originfeature>
               Add Stories with AI <mat-icon svgIcon="ai"></mat-icon>
            </a>
          </ng-container>
          <ng-template #showLink>
            <a class="add-stories-ai"  
               [ngClass]="{'disabled': description.invalid || acceptanceCriteria.invalid}"
               (click)="openBreakDownFeatureForm(originfeature, null)"
               #originfeature>
               Add Stories with AI <mat-icon svgIcon="ai"></mat-icon>
            </a>
          </ng-template>
          <mat-form-field appearance="fill" class="default-full-width acceptance-criteria-field">
            <mat-label>{{ 'anms.solution.feature.form.notes' | translate }}</mat-label>
            <textarea class="textarea-form" matInput formControlName="notes" [mentionConfig]="mentionsConfig" cdkTextareaAutosize
            #autosize2="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10" ></textarea>
            <mat-hint> Notes </mat-hint>
            <mat-error *ngIf="form.get('notes').invalid">
              {{ 'anms.feature.form.acceptanceCriteria' | translate }}
              {{ 'anms.examples.form.error1' | translate }}
            </mat-error>
          </mat-form-field>
          
          
          
         
        </div>

        <div class="right-section form-main px-md-2 d-flex flex-column ">
          <div class="d-flex flex-column flex-nowrap px-0 mb-4 ">
            <mat-form-field *ngIf="form.value.parentId === ''" appearance="fill" class="px-0">
              <mat-label>Context</mat-label>
              <mat-select formControlName="context">
                <mat-option *ngFor="let context of featureContextList" [value]="context.value">
                  {{context.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>


          </div>

          <div class="d-flex flex-column flex-xl-row flex-nowrap px-0 mb-4">
            <ng-container *ngIf="objectives$| async as strategicThemes">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label> Key Result</mat-label>
                <mat-select formControlName="keyResultId" (selectionChange)="onChangeKeyResult($event)">
                  <!-- <mat-option  value=""></mat-option> -->
                  <ng-container *ngFor="let strategicTheme of strategicThemes">
                    <mat-optgroup *ngIf="strategicTheme.keyResults"
                      label=" {{strategicTheme.description}}">
                      <ng-container *ngFor="let keyResult of strategicTheme.keyResults;index as i">
                        <mat-option [value]="keyResult.id">
                          {{keyResult.description}}
                        </mat-option>
                      </ng-container>
                    </mat-optgroup>
                  </ng-container>
                </mat-select>
                <mat-hint>Key Result</mat-hint>
                <mat-error *ngIf="form.get('keyResultId').invalid">
                    {{ 'anms.epic.form.placeholder.keyResult' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>

          <div class="d-flex flex-column flex-xl-row flex-nowrap px-0 mb-4">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of featureTypes" [value]="type.value">
                  {{type.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="usersList$ | async as usersList">
              <mat-form-field class="px-0" appearance="fill">
                <mat-label>Feature Owner</mat-label>
                <mat-select formControlName="featureOwnerId" (selectionChange)="updateFeatureOwner($event.value)">
                  <mat-option *ngFor="let user of usersList" [value]="user.user.id">
                    {{user.user.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('featureOwnerId').invalid">
                  {{ 'anms.requirements.feature.form.featureOwnerId' | translate }}
                  {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>

          <div class="d-flex flex-column flex-xl-row flex-nowrap px-0 mb-4">
             <!-- DOR -->
             <mat-form-field appearance="fill" class="px-0 ">
              <mat-label>Definition of Ready</mat-label>
              <mat-select formControlName="dor" (selectionChange)="changeDoR($event)">
                <mat-option *ngFor="let dor of featureDor" [value]="dor.value">
                  {{dor.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf="pi$ | async as pis">
              <mat-form-field class="px-0" appearance="fill">
                <mat-label>Program Increment</mat-label>
                <mat-select formControlName="programIncrementId" (selectionChange)="changePI($event)">
                  <mat-option></mat-option> <!-- Empty option -->
                    <!-- Grouping Program Increments based on current date -->
                    <ng-container *ngFor="let group of groupedProgramIncrements">
                      <mat-optgroup *ngIf="group.programIncrements.length > 0" [label]="group.label">
                        <mat-option *ngFor="let pi of group.programIncrements" [value]="pi.id">
                          {{pi.name}}
                        </mat-option>
                      </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="form.get('programIncrementId').invalid">
                  {{ 'anms.requirements.feature.form.programIncrementId' | translate }}
                  {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>

          <div class="px-0 mb-4">
            <ng-container *ngIf=isUpdate>
             <mat-form-field appearance="fill" class="px-0">
                <mat-label>Normalized Story Points</mat-label>
                <input matInput placeholder="{{ 'anms.requirements.feature.form.placeholder.normalizedStoryPoints' | translate }}"
                  formControlName="normalizedStoryPoints" type="number" min="0" value="epic.normalizedStoryPoints">
                <mat-hint>Normalized Story Points</mat-hint>
                <mat-error *ngIf="form.get('normalizedStoryPoints').invalid">
                  {{ 'anms.requirements.feature.form.placeholder.normalizedStoryPoints' | translate }}
                  {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
              </mat-form-field>
              <ng-container *ngIf="owners$ | async as owners">
                <mat-form-field appearance="fill" class="px-0">
                  <mat-label>Owner</mat-label>
                  <mat-select formControlName="ownerId">
                    <mat-option *ngFor="let owner of owners" [value]="owner.user.id">
                      {{owner.user.name}}
                    </mat-option>
                  </mat-select>
                  <mat-hint>User that has ownership of the record</mat-hint>
                  <mat-error *ngIf="form.get('ownerId').invalid">
                    <!--{{ 'anms.organization.form.placeholder.user' | translate }}
                    {{ 'anms.examples.form.error1' | translate }} -->
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="isUpdate" class="item-meta mt-5">
            <p>Created: <span>{{created | date}}</span></p>
            <p>Last update:<span>{{ updated | date}}</span></p>
          </div>

          <div *ngIf="isUpdate" class="item-meta item-meta-bottom" style="overflow: auto !important;">
            <anms-audit-history-changes [auditHistoryChanges]="auditHistoryChanges" *ngIf="auditHistoryChanges$ | async as auditHistoryChanges"></anms-audit-history-changes>
              
          </div>

          </div>
        </div>
      </div>

      <div class="form-actions">
        <div class="action-col secondary">
          <button *ngIf="isUpdate" mat-flat-button (click)="delete()" type="button" class="btn-delete">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon> Delete
          </button>
          <button *ngIf="!isUpdate" mat-flat-button (click)="closeDialog()" type="button" class="btn-delete">
           Cancel
          </button>
        </div>
        <div class="action-col primary">
          <button *ngIf="isUpdate" mat-raised-button color="primary" type="submit" id="save" class="btn-save"><span
              class="material-icons" >check</span>Save</button>
          <button *ngIf="!isUpdate" mat-raised-button color="primary" type="submit" id="saveNew" class="btn-save"  [disabled]='!form.valid'><span
              class="material-icons">check</span>Save New</button>
        </div>
      </div>

  </form>
</div>
