import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators'
import { AuthState } from './auth.models'
import * as fromAuthSelectors from './auth.selectors'
import * as fromAuthActions from './auth.actions'
import { AuthServiceGeneric } from './auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  state: Observable<AuthState>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private AUTH_HEADER = 'Authorization'
  private token = 'secrettoken'
  private refreshTokenInProgress = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  )
  constructor(
    private store: Store, //  private storageService: LocalStorageService
    readonly authService: AuthServiceGeneric
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.isLoggedIn().pipe(
      first(),
      exhaustMap((loggedId) => {
        if (loggedId)
          this.authService.getAccessToken().subscribe(
            (accessToken) =>
              (req = req.clone({
                headers: req.headers.set(
                  'Authorization',
                  'Bearer ' + accessToken
                )
              }))
          )

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401) {
              // 401 errors are most likely going to be because we have an expired token that we need to refresh.
              if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject.pipe(
                  filter((result) => result !== null),
                  take(1),
                  switchMap(() => next.handle(this.addAuthenticationToken(req)))
                )
              } else {
                this.refreshTokenInProgress = true

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null)

                return this.authService.refreshAccessToken().pipe(
                  switchMap((token: any) => {
                    this.refreshTokenInProgress = false
                    this.refreshTokenSubject.next(token)
                    this.token = token.access_token
                    return next.handle(this.addAuthenticationToken(req))
                  }),
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  finalize(() => (this.refreshTokenInProgress = false)),
                  catchError((e) => of(fromAuthActions.authLogout()))
                )
              }
            } else {
              return throwError(error)
            }
          })
        ) as Observable<HttpEvent<any>>
      })
    )
  }

  private refreshAccessToken(refreshAccessToken: string): Observable<any> {
    return of(refreshAccessToken)
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!this.token) {
      return request
    }
    // If you are calling an outside domain then do not add the token.
    // if (!request.url.match(/www.mydomain.com\//)) {
    //   return request
    // }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + this.token)
    })
  }
}
