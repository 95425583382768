import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core'

import { NavItem } from '../../store/menu.store'

@Component({
  selector: 'anms-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements OnInit {
  @Input() item: NavItem

  @Output() clickMenuEvent: EventEmitter<NavItem> = new EventEmitter()

  constructor() {}

  ngOnInit(): void {
    // console.log('Item', this.item)
    /* this.menuStore.setState({
      items: this.service.getDefaultNavigationMenu()
    })
    */
  }
  onNavigationClick(item: NavItem) {
    this.clickMenuEvent.emit(item)
  }
}
