import { Inject, Injectable, NgZone } from '@angular/core'
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material/snack-bar'
import { CustomSnackbarComponent } from '../../shared/custom-snackbar-component/custom-snackbar.component'
import { RequirementI } from '../../features/requirements-store/store/requirements.model'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {}

  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    })
  }

  info(message: string, data?: { item: RequirementI; message; linkMessage }) {
    this.show(message, {
      duration: 4000,
      panelClass: 'info-notification-overlay',
      data
    })
  }

  success(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay'
    })
  }

  warn(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    })
  }

  error(message: string) {
    this.show(message, {
      duration: 3000,
      panelClass: 'error-notification-overlay'
    })
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => {
      if (configuration.data)
        this.snackBar.openFromComponent(CustomSnackbarComponent, configuration)
      else this.snackBar.open(message, null, configuration)
    })

    // this.snackBar.open(message, null, configuration))
  }
}
