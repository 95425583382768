import { createSelector } from '@ngrx/store'
import { selectFeatures, FeaturesState } from '../../features.state'
import { adapter } from './kpiinstance.reducer'

export const selectKPIInstances = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.kpisinstances
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectKPIInstances)

export const getKPIInstanceById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])
