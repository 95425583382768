<form [formGroup]="form" (ngSubmit)="saveNew()" class="default-form p-0">
  <div class="input-wrapper">
    <mat-form-field appearance="fill" class="default-full-width">
      <mat-label>New Story</mat-label>
      <input  matInput placeholder="New Story"
      formControlName="itemName" #newChild (keydown.enter)="onEnter($event)">
      <mat-error *ngIf="form.get('itemName').invalid">
        {{ 'The child must have a name' | translate }}
      </mat-error>
      <mat-hint>{{'Child name' | translate }}</mat-hint>
    </mat-form-field>
    <button mat-flat-button style="cursor: pointer;" type="button" (click)="closeDialog()"><span class="material-icons">close</span></button>
    <button mat-flat-button color="primary"  style="width: 43px" type="submit" class="btn-secondary"><span class="material-icons">check</span></button>

  </div>
 </form>
