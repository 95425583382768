 <span>
        <button mat-menu-item>
          <mat-icon *ngIf="item.icon" svgIcon="{{item.type}}" id="{{item.type}}"></mat-icon>
            <p class="child-info">
              <a class="label accesible-link pt-2 pt-lg-0"  (click)="onNavigationClick(item)">
                {{item.label}}
                <div class="d-flex d-flex-column">
                  <span *ngIf="item.portfolio" class="portfolio">
                    {{item.portfolio}}
                  </span>
                  <span *ngIf="item.organization" class="portfolio">
                    {{item.organization}}
                  </span>
                </div>

              </a>

            </p>

        </button>
      </span>
