import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Team } from './team.model'

export const loadTeam = createAction(
  '[Team/API] LOAD Team',
  props<{ id: string }>()
)
export const successLoadTeam = createAction(
  '[Team/API] Success LOAD Team',
  props<{ team: Team }>()
)

export const loadTeams = createAction('[Team/API] Load Teams')

export const loadTeamsByPortfolio = createAction(
  '[Team/API] LOAD Teams By Portfolio',
  props<{ portfolioId: string }>()
)

export const loadTeamsByART = createAction(
  '[Team/API] LOAD Teams By Art',
  props<{ artId: string }>()
)

export const successLoadTeams = createAction(
  '[Team/API] Success Load Teams',
  props<{ teams: Team[] }>()
)
export const successLoadTeamsSet = createAction(
  '[Valuestream/API] Success Load  Teams Set',
  props<{ teams: Team[] }>()
)

export const addTeam = createAction(
  '[Team/API] Add Team',
  props<{ team: Team }>()
)
export const successAddTeam = createAction(
  '[Team/API] Success Add Team',
  props<{ team: Team }>()
)
export const successUpdateTeam = createAction(
  '[Team/API] Success Update Team',
  props<{ team: Team }>()
)
export const patchTeam = createAction(
  '[Team/API] Patch Team',
  props<{ team: Update<Team> }>()
)
export const successPatchTeam = createAction(
  '[Team/API] Success Patch Team',
  props<{ team: Team }>()
)

export const upsertTeam = createAction(
  '[Team/API] Upsert Team',
  props<{ team: Team }>()
)

export const addTeams = createAction(
  '[Team/API] Add Teams',
  props<{ teams: Team[] }>()
)

export const upsertTeams = createAction(
  '[Team/API] Upsert Teams',
  props<{ teams: Team[] }>()
)

export const updateTeam = createAction(
  '[Team/API] Update Team',
  props<{ team: Update<Team> }>()
)

export const updateTeams = createAction(
  '[Team/API] Update Teams',
  props<{ teams: Update<Team>[] }>()
)

export const deleteTeam = createAction(
  '[Team/API] Delete Team',
  props<{ id: string }>()
)
export const successDeleteTeam = createAction(
  '[Team/API] Success Delete Team',
  props<{ id: string }>()
)

export const deleteTeams = createAction(
  '[Team/API] Delete Teams',
  props<{ ids: string[] }>()
)
export const successDeleteTeams = createAction(
  '[Team/API] Sucess Delete Teams',
  props<{ ids: string[] }>()
)

export const clearTeams = createAction('[Team/API] Clear Teams')

export const error = createAction(
  '[Team/API] Error Teams petition',
  props<{ error }>()
)
