import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import {
  RequirementI,
  isEpicI,
  isFeatureI,
  isUserStoryI
} from '../../features/requirements-store/store/requirements.model'
import { EpicOverlayFormComponent } from '../requirement-forms/epic/epic-overlay-form.component'
import { OverlayService } from '../../overlay-forms/overlay/overlay.service'
import { OverlayFormRef } from '../../overlay-forms/overlay/overlay-form-ref'
import { FeatureFormComponent } from '../requirement-forms/feature/feature-form/feature-form.component'
import { UserStoryFormComponent } from '../requirement-forms/user-story-form/user-story-form.component'

@Component({
  selector: 'anms-custom-snackbar-component',
  templateUrl: './custom-snackbar-component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSnackbarComponent implements OnInit {
  message: string
  linkMessage: string

  private dialogRef: OverlayFormRef

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { item: RequirementI; message; linkMessage },
    private previewDialog: OverlayService
  ) {}

  ngOnInit(): void {
    this.message = this.data.message
    this.linkMessage = this.data.linkMessage
  }

  performAction(origin: HTMLElement) {
    this.openItem(origin)
  }

  openItem(origin: HTMLElement) {
    if (isEpicI(this.data.item)) {
      this.dialogRef = this.previewDialog.open(
        {
          height: window.innerHeight * 0.95,
          width: window.innerWidth * 0.95
        },
        {
          origin,
          data: {
            epic: this.data.item
          },
          content: EpicOverlayFormComponent
        }
      )
    }

    if (isFeatureI(this.data.item)) {
      this.dialogRef = this.previewDialog.open(
        {
          height: window.innerHeight * 0.95,
          width: window.innerWidth * 0.95
        },
        {
          origin,
          data: {
            feature: this.data.item
          },
          content: FeatureFormComponent
        }
      )
    }
    if (isUserStoryI(this.data.item)) {
      this.dialogRef = this.previewDialog.open(
        {
          height: window.innerHeight * 0.95,
          width: window.innerWidth * 0.95
        },
        {
          origin,
          data: {
            story: this.data.item
          },
          content: UserStoryFormComponent
        }
      )
    }
  }
}
