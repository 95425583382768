import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { KanbanitemBase } from '../../../../features/requirements-store/store/item/requirement.model'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import { OverlayService } from '../../../../overlay-forms/overlay/overlay.service'
import { FeatureFormComponent } from '../feature-form/feature-form.component'

@Component({
  selector: 'anms-dynamic-requirement-form',
  templateUrl: './dynamic-requirement-form.component.html',
  styleUrls: ['./dynamic-requirement-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicRequirementFormComponent implements OnInit {
  @Input() data: any
  @ViewChild('newChild', { static: true }) newChild: any
  form: FormGroup
  parentComponent: FeatureFormComponent

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private service: OverlayService
  ) {
    this.form = this.formBuilder.group({
      name: [''],
      description: '',
      id: '',
      parentId: ['', [Validators.required]],
      type: ['', [Validators.required]],
      columnId: '',
      kanbanId: '',
      toKanban: '',
      locked: '',
      itemName: ['', [Validators.required]],
      portfolioId: '',
      teamId: ''
    })
  }

  ngOnInit(): void {
    this.parentComponent = this.data.parentComponent as FeatureFormComponent
    this.form.controls['parentId'].setValue(this.data.parentId)
    this.form.controls['type'].setValue('business')
    this.form.controls['name'].setValue('userstory')

    // Focus always better in timeout
    setTimeout(() => {
      this.newChild.nativeElement.focus()
    }, 25)
  }
  saveNew() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()

      this.store.dispatch(
        fromActionsKanbanItems.addKanbanitem({
          kanbanitem: entity
        })
      )
      this.form.controls['itemName'].setValue('')
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).setErrors(null)
      })
      this.newChild.nativeElement.focus()
    }
  }

  resetForm() {
    this.form.controls['description'].setValue('')

    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null)
    })
  }
  closeDialog() {
    this.parentComponent.deleteItem()
  }

  onEnter(event: any) {
    event.stopPropagation()
    this.saveNew()
    setTimeout(() => {
      this.parentComponent.addItem(this.data.parentId)
    }, 25)
  }
}
