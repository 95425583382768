import { Action, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { ART } from './art.model'
import * as ARTActions from './art.actions'

export const stateARTFeatureKey = 'arts'

/* export interface State {
  [fromValuestream.valuestreamsFeatureKey]: fromValuestream..State
}
*/

export type ARTState = EntityState<ART>

export const adapter: EntityAdapter<ART> = createEntityAdapter<ART>()

export const initialState: ARTState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  // REMOVE TO AVOID DOUBLE INSERT
  // on(ARTActions.addART, (state, action) => adapter.addOne(action.aRT, state)),
  /* on(ARTActions.upsertART, (state, action) =>
    adapter.upsertOne(action.aRT, state)
  ),

  */
  on(ARTActions.addARTs, (state, action) =>
    adapter.addMany(action.aRTs, state)
  ),
  /*
  on(ARTActions.upsertARTs, (state, action) =>
    adapter.upsertMany(action.aRTs, state)
  ),
  */

  on(ARTActions.successUpsertART, (state, action) =>
    adapter.upsertOne(action.aRT, state)
  ),
  /*
  on(ARTActions.patchART, (state, action) =>
    adapter.updateOne(action.aRT, state)
  ),
  */
  on(ARTActions.updateARTs, (state, action) =>
    adapter.updateMany(action.aRTs, state)
  ),

  on(ARTActions.deleteARTs, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ARTActions.sucessAddART, (state, action) =>
    adapter.addOne(action.aRT, state)
  ),
  on(ARTActions.successAddTeamtoART, (state, action) =>
    adapter.setOne(action.aRT, state)
  ),
  on(ARTActions.successRemoveTeamFromART, (state, action) =>
    adapter.setOne(action.aRT, state)
  ),
  on(ARTActions.successRemoveValueStreamFromART, (state, action) =>
    adapter.setOne(action.aRT, state)
  ),
  on(ARTActions.successAddValuestreamToART, (state, action) =>
    adapter.setOne(action.aRT, state)
  ),
  on(ARTActions.successLoadART, (state, action) =>
    adapter.upsertOne(action.aRT, state)
  ),

  on(ARTActions.successDeleteART, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ARTActions.successAddSolutionToART, (state, action) =>
    adapter.setOne(action.aRT, state)
  ),
  on(ARTActions.successAddSolutionsToART, (state, action) =>
    adapter.setOne(action.art, state)
  ),
  on(ARTActions.successRemoveSolutionFromART, (state, action) =>
    adapter.setOne(action.aRT, state)
  ),

  on(
    ARTActions.successLoadARTs,
    (state, action) =>
      // console.log('Here', action.aRTs)
      // console.log('State', state)
      adapter.setAll(action.aRTs, state)
    // console.log('Merged', a)
    // console.log(a)

    // return a
  ),
  on(ARTActions.successLoadARTsSet, (state, action) =>
    adapter.upsertMany(action.aRTs, state)
  ),
  on(ARTActions.clearARTs, (state) => adapter.removeAll(state))
)
export function artReducer(state: ARTState | undefined, action: Action) {
  return reducer(state, action)
}
