import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { OrganizationMember, UserInvite } from './organization-member.model'

export const loadOrganizationMembers = createAction(
  '[OrganizationMember/API] Load OrganizationMembers'
)
export const successLoadOrganizationMembers = createAction(
  '[OrganizationMember/API] Success Load OrganizationMembers',
  props<{ organizationMembers: OrganizationMember[] }>()
)

export const successLoadOrganizationMembersByOrganization = createAction(
  '[OrganizationMember/API] Success Load OrganizationMembers by Organization',
  props<{ organizationMembers: OrganizationMember[] }>()
)
export const loadOrganizationMembersByOrganization = createAction(
  '[OrganizationMember/API] Load OrganizationMembers by Organization',
  props<{ organizationId }>()
)
export const addOrganizationMember = createAction(
  '[OrganizationMember/API] Add OrganizationMember',
  props<{ organizationMember: OrganizationMember }>()
)
export const successAddOrganizationMember = createAction(
  '[Organization/API] Success Add OrganizationMember',
  props<{ organizationMember: OrganizationMember }>()
)
export const successRemoveOrganizationMember = createAction(
  '[Organization/API] Success Remove OrganizationMember',
  props<{ id: string }>()
)

export const upsertOrganizationMember = createAction(
  '[OrganizationMember/API] Upsert OrganizationMember',
  props<{ organizationMember: OrganizationMember }>()
)
export const successPatchOrganizationMember = createAction(
  '[OrganizationMember/API] Success Patch OrganizationMember',
  props<{ organizationMember: OrganizationMember }>()
)

export const addOrganizationMembers = createAction(
  '[OrganizationMember/API] Add OrganizationMembers',
  props<{ organizationMembers: OrganizationMember[] }>()
)

export const upsertOrganizationMembers = createAction(
  '[OrganizationMember/API] Upsert OrganizationMembers',
  props<{ organizationMembers: OrganizationMember[] }>()
)

export const updateOrganizationMember = createAction(
  '[OrganizationMember/API] Update OrganizationMember',
  props<{ organizationMember: Update<OrganizationMember> }>()
)

export const updateOrganizationMembers = createAction(
  '[OrganizationMember/API] Update OrganizationMembers',
  props<{ organizationMembers: Update<OrganizationMember>[] }>()
)

export const deleteOrganizationMember = createAction(
  '[OrganizationMember/API] Delete OrganizationMember',
  props<{ id: string }>()
)

export const deleteOrganizationMembers = createAction(
  '[OrganizationMember/API] Delete OrganizationMembers',
  props<{ ids: string[] }>()
)

export const clearOrganizationMembers = createAction(
  '[OrganizationMember/API] Clear OrganizationMembers'
)

export const inviteOrganizationMember = createAction(
  '[OrganizationMember/API] Invite OrganizationMember',
  props<{
    email: string
    organizationId: string
    role: string
  }>()
)
export const resendVerificationTicket = createAction(
  '[OrganizationMember/API] Resent Verification Ticket',
  props<{
    email: string
  }>()
)
export const successInviteOrganizationMember = createAction(
  '[Organization/API] Success Invite OrganizationMember',
  props<{ response: UserInvite }>()
)
export const successResendInviteOrganizationMember = createAction(
  '[Organization/API] Success Resend Invite OrganizationMember',
  props<{ response: any }>()
)

export const failureInviteOrganizationMember = createAction(
  '[Organization/API] Failure Invite OrganizationMember',
  props<{ error: any }>()
)
