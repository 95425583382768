<mat-toolbar color="primary" [@toolbarState]="toolbarState" class="accesible-buttons">
  <nav aria-label="Main navigation">
      <ul id="main-nav">
        <li
          mat-button class="nav-button"
          *ngFor="let item of navigation"
          routerLinkActive="active">
          <span *ngIf="item.children!==undefined && item.children.length > 0">
            <button mat-button [matMenuTriggerFor]="childMenu" (click)="!showSubmenu = showSubmenu" [ngClass]="{'active' : showSubmenu}" >
              {{item.label | translate}}
              <span class="material-icons">expand_more</span>
            </button>
            <mat-menu
              #childMenu="matMenu" [overlapTrigger]="false">
              <anms-child-menu (clickMenuEvent)="onNavigationClick($event)" [type]=item.type></anms-child-menu>
              <p class="view-all-label"><a [routerLink]="[item.link]"
                (click)="onNavigationClick()">
                View all
                {{item.label | translate}}
              </a></p>
            </mat-menu>
          </span>
          <span
            (click)="onNavigationClick()"
            *ngIf="!item.children || item.children.length === 0">
            <button mat-button  [routerLink]="item.link">
              {{item.label | translate}}
            </button>
          </span>
        </li>
      </ul>

  </nav>
</mat-toolbar>
