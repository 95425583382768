import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'
import {
  OpenAIStore,
  ImproveRequest,
  BreakDownRequest
} from '../../features/openai/open-ai.model'
import { OverlayFormRef } from '../../overlay-forms/overlay/overlay-form-ref'
import {
  OverlayService,
  INIT_DATA1
} from '../../overlay-forms/overlay/overlay.service'
import { FeatureFormComponent } from '../requirement-forms/feature/feature-form/feature-form.component'
import * as fromOpenAIActions from '../../features/openai/open-ai.actions'
import { UserStoryFormComponent } from '../requirement-forms/user-story-form/user-story-form.component'
import { Subscription } from 'rxjs'
import { Story } from '../../features/requirements-store/store/requirements.model'
import { EnablerStoryFormComponent } from '../requirement-forms/enabler-story-form/enabler-story-form.component'

@Component({
  selector: 'anms-break-down-feature-form',
  templateUrl: './break-down-feature-form.component.html',
  styleUrls: ['./break-down-feature-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakDownFeatureFormComponent implements OnInit, OnDestroy {
  form: FormGroup

  descriptions$

  subscription: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private dialogRef: OverlayFormRef,

    private previewDialog: OverlayService,

    @Inject(INIT_DATA1)
    public data: { featureDescription; parentId; context }
  ) {
    this.form = this.formBuilder.group({
      featureDescription: this.data.featureDescription,
      stories: ''
    })
  }

  ngOnInit(): void {
    this.descriptions$ = this.openAIStore.filterItemsByType<Story>('story')
    this.subscription = this.descriptions$.subscribe((suggestions) => {
      suggestions.forEach((story) => {
        // Use a unique id for each feature, assuming feature has an 'id'
        this.form.addControl(story.id, this.formBuilder.control(false)) // Initialize checkbox as unchecked
      })
    })
    /* this.subscription = this.descriptions$.subscribe((val) =>
      this.form.get('stories').setValue(val[0])
    )*/
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  handleTextSelection(): void {
    const selectedText = (document.getElementById(
      'stories-input'
    ) as HTMLInputElement).value.substring(
      (document.getElementById('stories-input') as HTMLInputElement)
        .selectionStart,
      (document.getElementById('stories-input') as HTMLInputElement)
        .selectionEnd
    )

    this.dialogRef = this.previewDialog.open(
      {},
      {
        origin: document.getElementById('stories-input') as HTMLInputElement,
        data: {
          description: selectedText,
          parentId: this.data.parentId
        },
        content: UserStoryFormComponent
      }
    )
  }

  onSubmit(buttonType): void {
    if (buttonType === 'load') {
      this.breakDownFeatureRequest()
    }
  }

  breakDownFeatureRequest() {
    if (this.form.valid) {
      const form = this.form.getRawValue()
      const req: BreakDownRequest = {
        context: '',
        description: form.featureDescription,
        role: 'user',
        n: 1
      }
      this.store.dispatch(
        fromOpenAIActions.breakDownFeatureStructured({ data: req })
      )
    }
  }
  openStoryForm(event: { story: Story; origin: HTMLElement }) {
    const { story, origin } = event

    const modifiedStory = {
      ...story,
      parentId: this.data.parentId // Set the parentId here
    }

    const componentToOpen =
      story.name === 'enablerstory'
        ? EnablerStoryFormComponent
        : UserStoryFormComponent // Choose component based on story.name

    this.dialogRef = this.previewDialog.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.95
      },
      {
        origin,
        data: {
          story: modifiedStory
        },
        content: componentToOpen // Dynamically load the component
      }
    )
  }
  onStoryChange(story: Story, isChecked: boolean) {
    // Handle checkbox change logic if needed
    console.log(`Feature: ${story.description}, Checked: ${isChecked}`)
  }
}
