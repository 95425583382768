import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { AuthGuardService } from './core/core.module'
import { LogoutComponent } from './features/authentication/logout/logout.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'organizations',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'organizations',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/organization/organization.module').then(
        (m) => m.OrganizationModule
      )
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./features/registration/registration.module').then(
        (m) => m.RegistrationModule
      )
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./features/about/about.module').then((m) => m.AboutModule)
  },
  {
    path: 'feature-list',
    loadChildren: () =>
      import('./features/feature-list/feature-list.module').then(
        (m) => m.FeatureListModule
      )
  },
  {
    path: 'settings',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/settings/settings.module').then(
        (m) => m.SettingsModule
      )
  },
  {
    path: 'examples',
    loadChildren: () =>
      import('./features/examples/examples.module').then(
        (m) => m.ExamplesModule
      )
  },
  {
    path: 'portfolios',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/portfolio/portfolio.module').then(
        (m) => m.PortfolioModule
      )
  },
  {
    path: 'admin',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'value-streams',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/value-stream/value-stream.module').then(
        (m) => m.ValueStreamModule
      )
  },
  {
    path: 'solutions',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/solutions/solutions.module').then(
        (m) => m.SolutionsModule
      )
  },
  {
    path: 'teams',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/teams/teams.module').then((m) => m.TeamsModule)
  },
  {
    path: 'requirements',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/requirements/requirements.module').then(
        (m) => m.RequirementsModule
      )
  },

  {
    path: 'program-increments',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/program-increment/program-increment.module').then(
        (m) => m.ProgramIncrementModule
      )
  },
  {
    path: 'arts',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/arts/arts.module').then((m) => m.ARTSModule)
  },
  {
    path: 'kanban',
    canLoad: [AuthGuardService],

    loadChildren: () =>
      import('./features/kanban-board/kanban-board.module').then(
        (m) => m.KanbanBoardModule
      )
  },
  {
    path: 'user',
    canLoad: [AuthGuardService],
    loadChildren: () =>
      import('./features/user/user.module').then((m) => m.UserModule)
  },
  {
    path: '**',
    redirectTo: 'login'
  }
]

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
