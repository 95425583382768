import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import {
  ImproveRequest,
  OpenAIStore
} from '../../features/openai/open-ai.model'
import { OverlayFormRef } from '../../overlay-forms/overlay/overlay-form-ref'
import { Store } from '@ngrx/store'
import {
  INIT_DATA1,
  OverlayService
} from '../../overlay-forms/overlay/overlay.service'
import * as fromOpenAIActions from '../../features/openai/open-ai.actions'
import { FeatureFormComponent } from '../requirement-forms/feature/feature-form/feature-form.component'
import { Observable, Subscription } from 'rxjs'
import { Feature } from '../../features/requirements-store/store/requirements.model'

@Component({
  selector: 'anms-break-down-epic-form',
  templateUrl: './break-down-epic-form.component.html',
  styleUrls: ['./break-down-epic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakDownEpicFormComponent implements OnInit, OnDestroy {
  form: FormGroup

  descriptions$: Observable<Feature[]>

  subscription: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private dialogRef: OverlayFormRef,

    private previewDialog: OverlayService,

    @Inject(INIT_DATA1)
    public data: { epicDescription; parentId; nonFunctionalRequirements }
  ) {
    this.form = this.formBuilder.group({
      epicDescription: this.data.epicDescription,
      nonFunctionalRequirements: this.data.nonFunctionalRequirements,
      features: ''
    })
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  ngOnInit(): void {
    this.descriptions$ = this.openAIStore.filterItemsByType<Feature>('feature')
    this.subscription = this.descriptions$.subscribe((suggestions) => {
      suggestions.forEach((feature) => {
        // Use a unique id for each feature, assuming feature has an 'id'
        this.form.addControl(feature.id, this.formBuilder.control(false)) // Initialize checkbox as unchecked
      })
    })
  }

  handleTextSelection(): void {
    const selectedText = (document.getElementById(
      'myInput'
    ) as HTMLInputElement).value.substring(
      (document.getElementById('myInput') as HTMLInputElement).selectionStart,
      (document.getElementById('myInput') as HTMLInputElement).selectionEnd
    )

    this.dialogRef = this.previewDialog.open(
      {},
      {
        origin: document.getElementById('myInput') as HTMLInputElement,
        data: {
          description: selectedText,
          parentId: this.data.parentId
        },
        content: FeatureFormComponent
      }
    )
  }

  onSubmit(buttonType): void {
    if (buttonType === 'load') {
      this.breakDownEpicRequest()
    }
  }

  breakDownEpicRequest() {
    if (this.form.valid) {
      let nfReq = ''
      if (this.data.nonFunctionalRequirements) {
        nfReq =
          '\nInclude the following non functional requirements and include them in the appropriate Feature description or acceptance criteria:' +
          this.data.nonFunctionalRequirements
      }

      const form = this.form.getRawValue()
      const req: ImproveRequest = {
        epicRequest: form.epicDescription + nfReq,

        n: 1
      }
      // this.store.dispatch(fromOpenAIActions.breakDownEpic({ data: req }))
      this.store.dispatch(fromOpenAIActions.fetchStructuredEpic({ data: req }))
    }
  }
  onFeatureChange(feature: Feature, isChecked: boolean) {
    // Handle checkbox change logic if needed
    console.log(`Feature: ${feature.description}, Checked: ${isChecked}`)
  }
  openFeatureForm(event: { feature: Feature; origin: HTMLElement }) {
    const { feature, origin } = event

    const modifiedFeature = {
      ...feature,
      parentId: this.data.parentId // Set the parentId here
    }

    this.dialogRef = this.previewDialog.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.95
      },
      {
        origin,
        data: {
          feature: modifiedFeature
        },
        content: FeatureFormComponent
      }
    )
  }
}
