import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { KPI } from './kpi.model'
import * as fromKPIActions from './kpis.actions'

export type KPIState = EntityState<KPI>

export const adapter: EntityAdapter<KPI> = createEntityAdapter<KPI>()
export const initialState: KPIState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  on(fromKPIActions.successAddKPI, (state, action) =>
    adapter.addOne(action.kpi, state)
  ),
  on(fromKPIActions.successLoadKPIs, (state, action) =>
    adapter.setAll(action.kpis, state)
  ),
  on(fromKPIActions.successLoadKPI, (state, action) =>
    adapter.upsertOne(action.kpi, state)
  ),

  on(fromKPIActions.successDeleteKPI, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(fromKPIActions.successPutKPI, (state, action) =>
    adapter.upsertOne(action.kpi, state)
  ),
  on(fromKPIActions.successPatchKPI, (state, action) =>
    adapter.setOne(action.kpi, state)
  )
)

// eslint-disable-next-line @typescript-eslint/naming-convention
export function KPIReducer(state: KPIState | undefined, action: Action) {
  return reducer(state, action)
}
