import { Action, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { Objective } from './objective.model'
import * as ObjectiveActions from './objective.actions'

export const objectivesFeatureKey = 'objectives'

export type ObjectiveState = EntityState<Objective>

export const adapter: EntityAdapter<Objective> = createEntityAdapter<Objective>()

export const initialState: ObjectiveState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  // on(ObjectiveActions.addObjective, (state, action) =>
  //   adapter.addOne(action.objective, state)
  // ),
  on(ObjectiveActions.successUpsertObjective, (state, action) =>
    adapter.upsertOne(action.objective, state)
  ),
  on(ObjectiveActions.addObjectives, (state, action) =>
    adapter.addMany(action.objectives, state)
  ),
  on(ObjectiveActions.upsertObjectives, (state, action) =>
    adapter.upsertMany(action.objectives, state)
  ),
  on(ObjectiveActions.updateObjective, (state, action) =>
    adapter.updateOne(action.objective, state)
  ),
  on(ObjectiveActions.updateObjectives, (state, action) =>
    adapter.updateMany(action.objectives, state)
  ),
  on(ObjectiveActions.successDeleteObjective, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ObjectiveActions.deleteObjectives, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ObjectiveActions.successLoadObjectives, (state, action) =>
    adapter.setAll(action.objectives, state)
  ),
  on(ObjectiveActions.successUpsertObjective, (state, action) =>
    adapter.upsertOne(action.objective, state)
  ),
  on(ObjectiveActions.successAddObjective, (state, action) =>
    adapter.addOne(action.objective, state)
  ),
  on(ObjectiveActions.clearObjectives, (state) => adapter.removeAll(state))
)
