import { createAction, props, Action } from '@ngrx/store'
import { Solution, SolutionRelation } from './solutions.model'
import { Update } from '@ngrx/entity'
import {
  Step,
  StepWithSolutions
} from '../../value-stream-store/store/valuestream.model'

export const loadSolutions = createAction('[Solutions/API] Load Solutions')

export const loadSolutionsByPortfolio = createAction(
  '[Solutions/API] Load SolutionsBy PortfolioSAFE',
  props<{ portfolioId: string }>()
)

export const loadSolutionsByOrganization = createAction(
  '[Solutions/API] Load SolutionsBy Organization',
  props<{ organizationId: string }>()
)

export const successLoadSolutionsSet = createAction(
  '[Solutions/API] Success Load Solutions Set',
  props<{ solutions: Solution[] }>()
)

export const successLoadSolutions = createAction(
  '[Solutions/API] Success Load Solutions',
  props<{ solutions: Solution[] }>()
)

export const addSolution = createAction(
  '[Solutions/API] Add Solution',
  props<{
    solution: Solution
    selectedStep?: StepWithSolutions
    valueStreamId?: string
  }>()
)

export const successUpdateSolution = createAction(
  '[Solutions/API] Success Update Solution',
  props<{ solution: Solution }>()
)

export const upsertSolution = createAction(
  '[Solutions/API]  Upsert Solution',
  props<{ solution: Solution }>()
)

export const addSolutions = createAction(
  '[Solutions/API]  Add Solutions',
  props<{ solutions: Solution[] }>()
)

export const addValueStreamToSolution = createAction(
  '[Solutions/API]  Add Value Stream To Solution',
  props<{ valueStreamId: string; solutionId: string }>()
)

export const successAddValueStreamToSolution = createAction(
  '[Solutions/API]  Success Add Value Stream To Solution',
  props<{ solution: Solution }>()
)

export const loadSolution = createAction(
  '[Solutions/API] Load Solution',
  props<{ solutionId: string }>()
)

export const successAddSolution = createAction(
  '[Solutions/API] Success Add Solution',
  props<{ solution: Solution }>()
)

export const removeValueStreamFromSolution = createAction(
  '[Solutions/API] Remove Value Stream From Solution',
  props<{ solutionId: string; valueStreamId: string }>()
)

export const successRemoveValueStreamFromSolution = createAction(
  '[Solutions/API] Success Remove Value Stream From Solution',
  props<{ solution: Solution }>()
)

export const upsertSolutions = createAction(
  '[Solutions/API] Upsert Solutions',
  props<{ solutions: Solution[] }>()
)

export const updateSolution = createAction(
  '[Solutions/API] Update Solution',
  props<{ solution: Update<Solution> }>()
)

export const patchSolution = createAction(
  '[Solutions/API] Patch Solution',
  props<{ solution: Update<Solution> }>()
)

export const updateSolutions = createAction(
  '[Solutions/API] Update Solutions',
  props<{ solutions: Update<Solution>[] }>()
)

export const deleteSolution = createAction(
  '[Solutions/API] Delete Solution',
  props<{ id: string }>()
)

export const successDeleteSolution = createAction(
  '[Solutions/API] Success Delete Solution',
  props<{ id: string }>()
)
export const errorDeleteSolution = createAction(
  '[Solutions/API] Error Delete Solution',
  props<{ error: string }>()
)

export const deleteSolutions = createAction(
  '[Solutions/API]  Delete Solutions',
  props<{ ids: string[] }>()
)

export const addRelatedSolution = createAction(
  '[Solutions/API]  Add related Solution',
  props<{ relation: SolutionRelation; id: string }>()
)

export const actionError = createAction(
  '[Solutions/API] Action Error Solution',
  props<{ error: string }>()
)
export const clearSolutions = createAction('[Solutions/API]  Clear Solutions')

export const setSolution = createAction(
  '[Solutions/API]  Set Solution',
  props<{ selectedSolutionId: string | null }>()
)

export const removeLinkedSolution = createAction(
  '[Solutions/API] Remove linked Solution from a Solution ',
  props<{ id: string; relationId: string }>()
)
