import {
  animate,
  trigger,
  transition,
  style,
  query,
  group,
  animateChild
} from '@angular/animations'

export const mobileDialogAnimation = trigger('mobileDialogAnimation', [
  transition(':enter', [
    group([
      query(
        '.d1',
        [
          style({
            transform: 'translate3d(0, 100%, 0)', // Use 3D for GPU acceleration
            opacity: 0,
            'will-change': 'transform, opacity' // Browser optimization hint
          }),
          animate(
            '300ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 1
            })
          )
        ],
        { optional: true }
      ),
      // Add backdrop fade animation
      query(
        '.overlay-background',
        [
          style({ opacity: 0 }),
          animate('300ms ease-out', style({ opacity: 1 }))
        ],
        { optional: true }
      ),
      animateChild() // For any child animations
    ])
  ]),
  transition(':leave', [
    group([
      query(
        '.d1',
        [
          animate(
            '250ms cubic-bezier(0.55, 0, 0.55, 0.2)',
            style({
              transform: 'translate3d(0, 100%, 0)',
              opacity: 0
            })
          )
        ],
        { optional: true }
      ),
      query(
        '.overlay-background',
        [animate('250ms ease-in', style({ opacity: 0 }))],
        { optional: true }
      ),
      animateChild()
    ])
  ])
])

export const noneAnimation = trigger('none', [
  transition('* <=> *', [animate('0ms', style({}))])
])
