import {
  EventEmitter,
  Injectable,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { noop, of } from 'rxjs'
import localeEs from '@angular/common/locales/es'
import localeFr from '@angular/common/locales/fr'
import localeEn from '@angular/common/locales/en'
import localePt from '@angular/common/locales/pt'
import localeZhCn from '@angular/common/locales/zh-Hans'
import localeSk from '@angular/common/locales/sk'
import { registerLocaleData } from '@angular/common'

/*
type ShouldReuseRoute = (
  future: ActivatedRouteSnapshot,
  curr: ActivatedRouteSnapshot
) => boolean
*/

@Injectable({
  providedIn: 'root'
})
export class LocaleService implements OnInit {
  private initialized = false
  private _locale: string
  private language: string
  private reload: boolean
  private availableLanguages = [
    'en',
    'de',
    'sk',
    'fr',
    'es',
    'pt-br',
    'zh-cn',
    'he'
  ]

  get currentLocale(): string {
    return this._locale
  }
  setLocaleValue(value: string) {
    this._locale = value
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private router: Router,
    private translate: TranslateService,
    @Optional()
    @SkipSelf()
    otherInstance: LocaleService
  ) {
    // eslint-disable-next-line no-throw-literal
    if (otherInstance) throw 'LocaleService should have only one instance.'
    const navigatorLanguage = navigator.language.substring(2, 0)
    if (this.availableLanguages.includes(navigatorLanguage)) {
      this.initLocale(navigatorLanguage)
    } else {
      this.initLocale('en')
    }
    this.setLocaleData(this.currentLocale)
  }
  ngOnInit(): void {}
  /*
  private setRouteReuse(reuse: ShouldReuseRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = reuse
  }
  */

  private subscribeToLangChange() {
    this.translate.onLangChange.subscribe(({ lang }) => {
      //  const { shouldReuseRoute } = this.router.routeReuseStrategy
      this.language = lang
      if (this.currentLocale !== this.language) {
        this.setLocale(this.language)
      }

      /* const currentRoute = this.router.url
      console.log("current",currentRoute)
      await this.router
        .navigateByUrl('organizations')
        .catch(noop)
        .then(() => this.setRouteReuse(shouldReuseRoute))
        .finally(() => this.router.navigateByUrl(currentRoute))*/
    })
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initLocale(localeId: string, defaultLocaleId = localeId) {
    if (this.initialized) return

    this.setDefaultLocale(defaultLocaleId)
    this.setLocale(localeId)
    this.subscribeToLangChange()

    this.initialized = true
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  setDefaultLocale(localeId: string) {
    this.translate.setDefaultLang(localeId)
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  setLocale(localeId: string) {
    this.setLocaleData(localeId)
    this.setLocaleValue(localeId)
    this.translate.use(localeId)
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  setLocaleData(localeId: string) {
    switch (localeId) {
      case 'es':
        registerLocaleData(localeEs)

        break
      case 'en':
        registerLocaleData(localeEn)
        break
      case 'fr':
        registerLocaleData(localeFr)
        break
      case 'pt-BR':
        registerLocaleData(localePt)
        break
      case 'zh-cn':
        registerLocaleData(localeZhCn, 'zh-cn')
        break
      case 'sk':
        registerLocaleData(localeSk)
        break

      default:
        break
    }
  }
}
