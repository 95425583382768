/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import {
  ofType,
  createEffect,
  Actions,
  OnInitEffects,
  concatLatestFrom
} from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { Observable, interval, of, throwError, from } from 'rxjs'
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mapTo,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators'
import { selectAuthState } from '../core.state'

import { LocalStorageService } from '../local-storage/local-storage.service'

import * as fromAuthActions from './auth.actions'
import { AuthResponse } from './auth.model'
import { selectExpirationDate, selectIsAuthenticated } from './auth.selectors'
import { AuthServiceGeneric } from './auth.service'
import { environment } from '../../../environments/environment'
import { NotificationService } from '../notifications/notification.service'
import { isIntegrationEnviroment } from '../../features/authentication/helpers/isIntegration'
import { TranslateService } from '@ngx-translate/core'
import { ProfilingService } from '../profiling/profiling.service'

export const AUTH_KEY = 'AUTH'
export const MILISECONDS_TO_EXPIRE = 8015000

@Injectable()
export class AuthEffects {
  updateToken = createEffect(
    () =>
      interval(21_600).pipe(
        startWith(0),
        mapTo(new Date().getHours()),
        distinctUntilChanged(),
        concatLatestFrom(() => this.store.select(selectIsAuthenticated)),
        filter(([action, authenticate]) => authenticate === true),
        map(() => fromAuthActions.refreshToken())
      ),
    { dispatch: true }
  )

  persistLoginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromAuthActions.successLogin,
          fromAuthActions.successRefreshToken,
          fromAuthActions.authSuccessLogout
        ),
        // map((val) => val['response']),
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        tap(([action, authState]) => {
          this.localStorageService.setItem(AUTH_KEY, authState)
        })
      ),
    { dispatch: false }
  )

  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.authLogin),

        exhaustMap(
          (action) =>
            /* if (this.authService.getIsAuthChecker() === true) {
        return of(fromAuthActions.keep())
      } else { */
            this.authService.login(action.username, action.password).pipe(
              map(
                (data) => {
                  const data1 = this.jtwHelper.decodeToken(data.access_token)
                  const response: AuthResponse = {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    access_token: data.access_token,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    refresh_token: data.refresh_token,
                    expiresIn: data1.exp,
                    exp: data1.exp,
                    name: data1.name,
                    username: data1.email,
                    id: data1.sub,
                    isAuthenticated: true,
                    issuedDate: data1.iat
                  }

                  return fromAuthActions.successLogin({ response })
                }
                // catchError(error => of(fromAuthActions.loginFailure({ error })))
              ),
              catchError((error) => of(fromAuthActions.loginFailure({ error })))
            )
          /* }*/
        )
      ),
    { dispatch: true }
  )

  // @Effect({ dispatch: true, useEffectsErrorHandler: false })
  // renewToken = this.actions$.pipe(
  //   ofType(fromAuthActions.refreshToken),
  //   exhaustMap((action) =>
  //     this.authService.refreshAccessToken().pipe(
  //       map((data) =>
  //         fromAuthActions.successRenewToken({
  //           response: data
  //         })
  //       ),
  //       catchError((error) => of(fromAuthActions.failedRenewToken(error)))
  //     )
  //   )
  // )

  failedRenewToken = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.failedRenewToken),

        tap((action) => {
          console.log('Failed to renew token')
        }),
        map(() => fromAuthActions.authLogout())
      ),
    { dispatch: true }
  )

  /*  loginFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.loginFailure),

        tap((action: any) => {
          const message: string = action.error.message

          this.notificationService.error(this.translateService.instant(message))
        })
      ),
    { dispatch: false }
  )*/

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.authLogout),
        exhaustMap(() =>
          this.authService.logout().pipe(
            map(() => fromAuthActions.authSuccessLogout())

            // EL LOGOUT TE DA UN RESPOSNE REDIRECT A LOGIN
            // this.router.navigate(['logout'])
          )
        )
      ),
    { dispatch: true }
  )

  successLogin$ = createEffect(
    // ANTES HABIA EL  ANTES DEL COMANDO DE ROUTER this.authService.setisAuthChecker(true)
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(fromAuthActions.successLogin),
        // eslint-disable-next-line arrow-body-style
        // map(() => fromAuthActions.refreshToken()),
        tap((action) => {
          if (action.response.name) this.profileService.markProfileAsCompleted()

          this.router.navigate(['organizations'])
        })
      )
    },
    { dispatch: false }
  )
  successLogout$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(fromAuthActions.authSuccessLogout),
        // eslint-disable-next-line arrow-body-style
        tap(() => {
          localStorage.removeItem('showHelp')
          localStorage.removeItem('ANMS-AUTH')
          localStorage.removeItem('menuStoreData')
        }),
        tap(() => this.router.navigate(['login']))
      )
    },
    { dispatch: false }
  )

  errorLogin$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(fromAuthActions.loginFailure),
        tap(() => {
          localStorage.removeItem('showHelp')
          localStorage.removeItem('ANMS-AUTH')
          localStorage.removeItem('menuStoreData')
        }),

        // eslint-disable-next-line arrow-body-style
        tap((action) => {
          this.notificationService.error(
            `There was an error while trying logging. Status: ${action.error.status}:  ${action.error.message} `
          )
        })
      )
    },
    { dispatch: false }
  )

  refreshToken$ = createEffect(
    // eslint-disable-next-line arrow-body-style
    () => {
      return this.actions$.pipe(
        ofType(fromAuthActions.refreshToken),

        switchMap(() =>
          interval(MILISECONDS_TO_EXPIRE).pipe(
            exhaustMap(() =>
              this.authService.refreshAccessToken().pipe(
                map((token) => {
                  if (!token) return
                  let tokenToDecode
                  if (!isIntegrationEnviroment()) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const { access_token } = (token as unknown) as AuthResponse
                    tokenToDecode = access_token
                  } else {
                    tokenToDecode = token
                  }

                  const data = this.jtwHelper.decodeToken(tokenToDecode)

                  const response: AuthResponse = {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    access_token: tokenToDecode,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    expiresIn: data.exp,
                    exp: data.exp,
                    name: data.name,
                    username: data.email,
                    id: data.sub.slice(6),
                    isAuthenticated: true,
                    issuedDate: data.iat
                  }

                  return fromAuthActions.successRefreshToken({ response })
                }),

                catchError(() => {
                  const error1: AuthResponse = {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    access_token: '',
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    expiresIn: null,
                    exp: null,
                    name: '',
                    username: '',
                    id: '',
                    isAuthenticated: false,
                    issuedDate: null
                  }
                  return throwError(error1)
                })
              )
            )
          )
        )
      )
    },
    { dispatch: true }
  )

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private authService: AuthServiceGeneric,
    private router: Router,
    private store: Store,
    private jtwHelper: JwtHelperService,
    public notificationService: NotificationService,
    public translateService: TranslateService,
    private profileService: ProfilingService
  ) {}
  /* ngrxOnInitEffects(): Action {
    return fromAuthActions.renewToken()
  }*/
}
