<div class="main-wrapper">
  <div class="form-fields">
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit($event.submitter.id)"
      class="default-form"
    >
    <div class="d-flex justify-content-center"> <h2>{{description}}</h2></div>
        <div
          class="improve_epic_form_container form-fields-inner d-flex flex-column flex-lg-row align-items-center justify-content-center mb-0 pb-0"
        >
       
          <div class="box-left px-0">
  
            <mat-form-field appearance="fill" class="default-full-width mb-0">
              <mat-label>Acceptance Criteria</mat-label>
              <mat-quill
              #matEditor 
              [modules]="quillConfig"
             
              formControlName="acceptanceCriteria">
            </mat-quill>
              <mat-hint>Save this acceptance criteria to the Feature</mat-hint>
              <mat-error *ngIf="form.get('acceptanceCriteria').invalid">
                {{ 'anms.organization.form.placeholder.name' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <button
              class="arrow-btn"
              (click)="moveTextToLeft(form.get('improvedAcceptanceCriteria').value)"
            >
              <fa-icon [icon]="['fas', 'arrow-up']" size="2x"></fa-icon>
            </button>
          </div>
  
          <div class="box-right px-0">
            <ng-container  *ngIf="descriptions$ | async as descriptions">
              <ng-container *ngFor="let description of descriptions.slice(1)">
                <div>
                  <p>{{description}}
                  </p>
                </div>
                <mat-divider></mat-divider>
  
              </ng-container>
              </ng-container>
            <mat-form-field
              appearance="fill"
              class="default-full-width mb-0 mt-3 mt-lg-0"
            >
              <mat-label>AI Generated Acceptance Criteria</mat-label>
              <!--quill-editor 
             #editor
              name="description"
              formControlName="improvedAcceptanceCriteria"
    
            [modules]="quillConfig">
          </quill-editor-->
          <mat-quill
          #matEditor 
          [modules]="quillConfig"
         
          formControlName="improvedAcceptanceCriteria">
        </mat-quill>
  <!--anms-quill-editor
              
    formControlName="improvedAcceptanceCriteria"
    
    
    
  ></anms-quill-editor-->
              <mat-hint>Hint</mat-hint>
              <mat-error *ngIf="form.get('improvedAcceptanceCriteria').invalid">
                {{ 'anms.organization.form.placeholder.name' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
      </div>
  
      <div class="form-actions d-flex flex-column flex-column-reverse flex-md-row mb-2 mb-md-0">
        <div class="action-col secondary mb-2 mb-md-0 mr-md-4">
          <button
            mat-raised-button
            color="secondary"
            class="btn-save"
            (click)="copyContentToParent()"
            [disabled]="!form.valid"
          >
            Save Acceptance Criteria to Feature
          </button>
        </div>
        <div class="action-col primary mb-2 mb-md-0">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="btn-load"
            [disabled]="!form.valid"
            id="load"
          >
          Generate
          </button>
        </div>
        
      </div>
    </form>
    </div>
  </div>
  
