import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { ProgramIncrement } from './program-increment.model'
import * as ProgramIncrementActions from './program-increment.actions'

export const programIncrementsFeatureKey = 'programincrements'

export interface ProgramIncrementState extends EntityState<ProgramIncrement> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ProgramIncrement> = createEntityAdapter<ProgramIncrement>()

export const initialState: ProgramIncrementState = adapter.getInitialState({
  // additional entity state properties
  ids: ['1'],
  entities: {
    '1': {
      id: '1',
      startDate: new Date(),
      endDate: new Date(),
      name: 'Program Increment 1',
      ownerId: '1',
      programId: '1'
    }
  }
})

export const reducer = createReducer(
  initialState,

  // on(ProgramIncrementActions.addProgramIncrement, (state, action) =>
  //  adapter.addOne(action.programIncrement, state)
  // ),
  on(ProgramIncrementActions.successAddProgramIncrement, (state, action) =>
    adapter.addOne(action.programIncrement, state)
  ),
  /*
  on(ProgramIncrementActions.upsertProgramIncrement, (state, action) =>
    adapter.upsertOne(action.programIncrement, state)
  ),
  */
  on(ProgramIncrementActions.addProgramIncrements, (state, action) =>
    adapter.addMany(action.programIncrements, state)
  ),
  on(ProgramIncrementActions.upsertProgramIncrements, (state, action) =>
    adapter.upsertMany(action.programIncrements, state)
  ),
  on(ProgramIncrementActions.updateProgramIncrement, (state, action) =>
    adapter.updateOne(action.programIncrement, state)
  ),
  on(ProgramIncrementActions.updateProgramIncrements, (state, action) =>
    adapter.updateMany(action.programIncrements, state)
  ),
  on(ProgramIncrementActions.successDeleteProgramIncrement, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ProgramIncrementActions.successUpdateProgramIncrement, (state, action) =>
    adapter.upsertOne(action.programIncrement, state)
  ),
  on(ProgramIncrementActions.deleteProgramIncrements, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(ProgramIncrementActions.successLoadProgramIncrements, (state, action) =>
    adapter.setAll(action.programIncrements, state)
  ),
  on(ProgramIncrementActions.clearProgramIncrements, (state) =>
    adapter.removeAll(state)
  )
)

/*
export const FEATURE_NAME = 'examples';
export const selectExamples = createFeatureSelector<State, ExamplesState>(
  FEATURE_NAME
);
*/
