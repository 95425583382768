import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { User } from './user.model'

export interface CommonResponse {
  message: string
  data: any
}

export const loadUsers = createAction('[User/API] Load Users')
export const successLoadUsers = createAction(
  '[User/API] Success Load Users',
  props<{ users: User[] }>()
)

export const addUser = createAction(
  '[User/API] Add User',
  props<{ user: User }>()
)
export const registerUser = createAction(
  '[User/API] Register User',
  props<{ user: User }>()
)

export const registerUserSuccess = createAction(
  '[User/API] Register User Success',
  props<{ user: User }>()
)

export const upsertUser = createAction(
  '[User/API] Upsert User',
  props<{ user: User }>()
)

export const addUsers = createAction(
  '[User/API] Add Users',
  props<{ users: User[] }>()
)

export const upsertUsers = createAction(
  '[User/API] Upsert Users',
  props<{ users: User[] }>()
)

export const updateUser = createAction(
  '[User/API] Update User',
  props<{ user: Update<User> }>()
)

export const updateUsers = createAction(
  '[User/API] Update Users',
  props<{ users: Update<User>[] }>()
)

export const deleteUser = createAction(
  '[User/API] Delete User',
  props<{ id: string }>()
)

export const unblockUser = createAction(
  '[User/API] unblock User',
  props<{ email: string }>()
)
export const getApiToken = createAction('[User/API] Get API token')

export const successGetAPIToken = createAction(
  '[User/API] success Get API Token',
  props<{ token: string }>()
)
export const successLoadUser = createAction(
  '[User/API] success Load User',
  props<{ user: User }>()
)

export const deleteUsers = createAction(
  '[User/API] Delete Users',
  props<{ ids: string[] }>()
)

export const clearUsers = createAction('[User/API] Clear Users')

/* *****  CHANGE PASSWORD ACTIONS ****** */
export const changeUserPassword = createAction(
  '[User/API] change password',
  props<{
    currentPassword: string
    newPassword: string
    confirmPassword: string
  }>()
)

/* ***** CHANGE NAME ACTIONS INCLUDING SUCCESS AND FAILURE ****** */
export const changeName = createAction(
  '[User/API] change name',
  props<{
    newName: string
    redirect?: boolean
  }>()
)

export const changeNameSuccess = createAction(
  '[User/API] Change Name Success',
  props<{ response: CommonResponse; redirect?: boolean }>()
)

export const changeNameError = createAction(
  '[User/API] User Name Update Failure',
  props<{ error: any }>()
)

/* ***** GENERIC ACTIONS OF SUCCESS AND FAILURE OF CHANGE CREDENTIALS ****** */
export const changeCredentialsSuccess = createAction(
  '[User/API] Change Credentials Success',
  props<{ response: any }>()
)

export const error = createAction(
  '[User/API] User Settings update Failure',
  props<{ error: any }>()
)
