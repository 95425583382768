import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { HistoryAudit } from './history-audit.model'
import * as HistoryAuditActions from './history-audit.actions'

export const historyAuditFeatureKey = 'historyaudit'

export type HistoryAuditState = EntityState<HistoryAudit>

export const adapter: EntityAdapter<HistoryAudit> = createEntityAdapter<HistoryAudit>()

export const initialState: HistoryAuditState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,

  on(HistoryAuditActions.loadHistoryAuditsSuccess, (state, action) =>
    adapter.setAll(action.historyaudit, state)
  ),
  on(HistoryAuditActions.loadHistoryAuditSetSuccess, (state, action) =>
    adapter.upsertMany(action.historyaudit, state)
  )
)
