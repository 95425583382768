<div>
  <button
    routerLink="/user/current"
    mat-menu-item
    (click)="onNavigationClick()">
    <mat-icon>account_box</mat-icon>
    <span>{{ 'anms.user.manage' | translate }}</span>
  </button>
  <button
    routerLink="/user/billing"
    mat-menu-item
    (click)="onNavigationClick()">
    <mat-icon>payment</mat-icon>
    <span>{{ 'anms.user.billing' | translate }}</span>
  </button>
  <button
    routerLink="/user/subscriptions"
    mat-menu-item
    (click)="onNavigationClick()">
    <mat-icon>card_membership</mat-icon>
    <span>{{ 'anms.user.plans' | translate }}</span>
  </button>
  <button
  routerLink="/user/integrations"
  mat-menu-item
  (click)="onNavigationClick()">
  <mat-icon>integration_instructions</mat-icon>
  <span>{{ 'anms.user.integrations' | translate }}</span>
  </button>
  <button
  routerLink="/user/api"
  mat-menu-item
  (click)="onNavigationClick()">
  <mat-icon>code</mat-icon>
  <span>{{ 'anms.user.api' | translate }}</span>
  </button>


  <button
    mat-menu-item routerLink="settings"
    (click)="onNavigationClick()">
    <mat-icon>settings</mat-icon>
    <span>Settings</span>
  </button>

  <button mat-menu-item *ngIf="language$ | async as language">
    <mat-icon>language</mat-icon>
    <span>{{ 'anms.user.language' | translate }}:</span>
    <mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)">
      <mat-option *ngFor="let l of languages" [value]="l">
        {{ l.toUpperCase() }}
      </mat-option>
    </mat-select>
  </button>

  <button
    class="logout-btn"
    mat-menu-item (click)="onLogoutClick()">
    <mat-icon
      class="logout-icon">
      <fa-icon icon="power-off"></fa-icon>
    </mat-icon>
    <span>{{ 'anms.menu.logout' | translate }}</span>
  </button>
</div>
