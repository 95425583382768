import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Step, Valuestream, ValuestreamI } from './valuestream.model'
import { ART, Id } from '../../arts-store/art.model'
import {
  IdSolution,
  Solution
} from '../../solutions-store/store/solutions.model'

export const loadValuestreams = createAction(
  '[Valuestream/API] Load Valuestreams'
)
export const loadValuestreamsByPortfolio = createAction(
  '[Valuestream/API] Load Valuestreams By Portfolio',
  props<{ portfolioId: string }>()
)
export const successLoadValueStreamsSet = createAction(
  '[Valuestream/API] Success Load  Valuestreams Set',
  props<{ valuestreams: Valuestream[] }>()
)
export const successAddValuestream = createAction(
  '[Valuestream/API] Success Add Valuestream',
  props<{ valuestream: Valuestream }>()
)

export const successLoadValueStreams = createAction(
  '[Valuestream/API] Success Load Valuestreams',
  props<{ valuestreams: Valuestream[] }>()
)

export const addValuestream = createAction(
  '[Valuestream/API] Add Valuestream',
  props<{ valuestream: Valuestream }>()
)

export const upsertValuestream = createAction(
  '[Valuestream/API] Upsert Valuestream',
  props<{ valuestream: Valuestream }>()
)

export const addValuestreams = createAction(
  '[Valuestream/API] Add Valuestreams',
  props<{ valuestreams: Valuestream[] }>()
)

export const upsertValuestreams = createAction(
  '[Valuestream/API] Upsert Valuestreams',
  props<{ valuestreams: Valuestream[] }>()
)

export const updateValuestream = createAction(
  '[Valuestream/API] Update Valuestream',
  props<{ valuestream: Update<Valuestream> }>()
)

export const successUpdateValuestream = createAction(
  '[Valuestream/API] Success Update Valuestream',
  props<{ valuestream: Valuestream }>()
)
export const successPatchValuestream = createAction(
  '[Valuestream/API] Success Patch Valuestream',
  props<{ valuestream: Valuestream }>()
)
export const patchValuestream = createAction(
  '[Valuestream/API] Patch Valuestream',
  props<{ valuestream: Update<Valuestream> }>()
)

export const patchValuestreamStage = createAction(
  '[Valuestream/API] Patch Valuestream Stage',
  props<{
    valuestreamId: string
    stage: Step
  }>()
)

export const successPatchValuestreamStage = createAction(
  '[Valuestream/API] Success Patch Valuestream Stage',
  props<{ valuestream: Valuestream }>()
)

export const failurePatchValuestreamStage = createAction(
  '[Valuestream/API] Failure Patch Valuestream Stage',
  props<{ error: any }>()
)
export const updateValuestreams = createAction(
  '[Valuestream/API] Update Valuestreams',
  props<{ valuestreams: Update<Valuestream>[] }>()
)

export const deleteValuestream = createAction(
  '[Valuestream/API] Delete Valuestream',
  props<{ id: string }>()
)
export const successDeleteValuestream = createAction(
  '[Valuestream/API] Success Delete Valuestream',
  props<{ id: string }>()
)

export const deleteValuestreams = createAction(
  '[Valuestream/API] Delete Valuestreams',
  props<{ ids: string[] }>()
)

export const clearValuestreams = createAction(
  '[Valuestream/API] Clear Valuestreams'
)

export const addARTtoValueStream = createAction(
  '[ART/API] Add ART to Valuestream',
  props<{ vaId: string; art: ART }>()
)
export const addSolutionstoValueStream = createAction(
  '[ART/API] Add Solutions to Valuestream',
  props<{ vaId: string; solutions: Id[] }>()
)
export const successAddSolutionstoValueStream = createAction(
  '[ART/API] Success Add Solutions to ValueStream',
  props<{ va: Valuestream }>()
)

export const removeARTFromValueStream = createAction(
  '[ART/API] Remove ART from ValueStream',
  props<{ vaId: string; artId: string }>()
)
export const removeSolutionFromValueStream = createAction(
  '[ART/API] Remove Solution from ValueStream',
  props<{ vaId: string; solutionId: string }>()
)
export const successRemoveARTFromValueStream = createAction(
  '[ART/API] Success Remove ART from ValueStream',
  props<{ va: Valuestream }>()
)
export const successAddARTToValueStream = createAction(
  '[ART/API] Success Add ART to ValueStream',
  props<{ va: Valuestream }>()
)

/**
 * Add Step to Valuestream
 */
export const addStagetoValuestream = createAction(
  '[Valuestream/API] Add Stage to ValueStream',
  props<{ va: Valuestream; stage: Step }>()
)

export const successAddStageToValuestream = createAction(
  '[ART/API] Success Add Stage to ValueStream',
  props<{ va: Valuestream }>()
)

/**
 * Update Steps of Valuestream
 */
export const updateValuestreamStages = createAction(
  '[Valuestream/API] Update Valuestreams Stages',
  props<{ va: Valuestream; stages: Step[] }>()
)

export const successUpdateValuestreamStages = createAction(
  '[Valuestream/API] Success Update Valuestreams Stages',
  props<{ va: Valuestream }>()
)

export const deleteValuestreamStage = createAction(
  '[Valuestream/API] Delete Valuestream Stage',
  props<{ va: Valuestream; stage: Step }>()
)

export const successDeleteValuestreamStage = createAction(
  '[Valuestream/API] Success Delete Valuestream Stage',
  props<{ id: string }>()
)

export const loadValuestream = createAction(
  '[Valuestream/API] Load Valuestream',
  props<{ vaId: string }>()
)
export const successLoadValuestream = createAction(
  '[Valuestream/API] Sucess Load Valuestream',
  props<{ va: Valuestream }>()
)
export const copyValuestream = createAction(
  '[Valuestream/API] Copy ValueStream to organization',
  props<{ valueStreamId: string; organizationId: string }>()
)
export const error = createAction(
  '[Valuestream/API] Error Action',
  props<{ error }>()
)
