import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { KPIInstance } from '../kpis/kpi.model'

import * as fromKPIActions from './kpiinstance.actions'

export type KPIInstanceState = EntityState<KPIInstance>

export const adapter: EntityAdapter<KPIInstance> = createEntityAdapter<KPIInstance>()
export const initialState: KPIInstanceState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  on(fromKPIActions.successLoadKPIInstances, (state, action) =>
    adapter.setAll(action.kpiInstances, state)
  ),
  on(fromKPIActions.successAddKPIInstance, (state, action) =>
    adapter.addOne(action.kpiInstance, state)
  ),
  on(fromKPIActions.successLoadKPIInstance, (state, action) =>
    adapter.upsertOne(action.kpiInstance, state)
  ),
  on(fromKPIActions.successPatchKPIInstance, (state, action) =>
    adapter.upsertOne(action.kpiInstance, state)
  ),
  on(fromKPIActions.successPutKPIInstance, (state, action) =>
    adapter.upsertOne(action.kpiInstance, state)
  ),

  on(fromKPIActions.successDeleteKPIInstance, (state, action) =>
    adapter.removeOne(action.id, state)
  ),

  on(fromKPIActions.successPatchKPIInstance, (state, action) =>
    adapter.setOne(action.kpiInstance, state)
  )
)

// eslint-disable-next-line @typescript-eslint/naming-convention
export function KPIReducer(
  state: KPIInstanceState | undefined,
  action: Action
) {
  return reducer(state, action)
}
