<div class="main-wrapper">
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit($event.submitter.id)"
      class="default-form"
    >
      <div class="form-fields">
        <div
          class="improve_epic_form_container form-fields-inner d-flex flex-column flex-lg-row mb-0 pb-0"
        >
          <div class="box-left px-0 flex-grow-1">
  
            <mat-form-field appearance="fill" class="default-full-width mb-0">
              <mat-label>Leading Indicators Description</mat-label>
              <textarea
                matInput
                placeholder="{{
                  'anms.epic.form.placeholder.leadingIndicators' | translate
                }}"
                formControlName="leadingIndicators" class="taller-textarea"
              ></textarea>
              <mat-hint>Hint</mat-hint>
              <mat-error *ngIf="form.get('leadingIndicators').invalid">
                {{ 'anms.epic.form.placeholder.leadingIndicators' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <button
              class="arrow-btn"
              (click)="moveTextToLeft(form.get('leadingIndicatorsFound').value)"
            >
              <fa-icon [icon]="['fas', 'arrow-up']" size="2x"></fa-icon>
            </button>
          </div>
  
          <div class="box-right px-0 flex-grow-1">
            <ng-container  *ngIf="descriptions$ | async as descriptions">
              <ng-container *ngFor="let description of descriptions.slice(1)">
                <div>
                  <p>{{description}}
                  </p>
                </div>
                <mat-divider></mat-divider>
  
              </ng-container>
              </ng-container>
            <mat-form-field
              appearance="fill"
              class="default-full-width mb-0 mt-3 mt-lg-0"
            >
              <mat-label>Find leading Indicators</mat-label>
              <textarea matInput formControlName="leadingIndicatorsFound" class="taller-textarea">
              </textarea>
              <mat-hint>Hint</mat-hint>
              <mat-error *ngIf="form.get('leadingIndicatorsFound').invalid">
                {{ 'anms.epic.form.placeholder.leadingIndicators' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="form-actions">
        <div class="action-col secondary">
          <button
            mat-raised-button
            color="secondary"
            class="btn-save"
            (click)="copyContentToParent()"
            [disabled]="!form.valid"
          >
            Save Leading Indicators to Epic
          </button>
        </div>
        <div class="action-col primary">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="btn-load"
            [disabled]="!form.valid"
            id="load"
          >
          Use AI
          </button>
        </div>
       
      </div>
    </form>
  </div>
  
