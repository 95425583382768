<div class="main-wrapper p-1">
    <form [formGroup]="form" class="default-form" (ngSubmit)="save()">
      <div class="form-fields">
        <div class="d-flex flex-column flex-md-row px-0 form-fields-inner  justify-content-between">
          <div class="d-flex flex-column px-0 flex-grow-1">
            <h2 class="px-1">Copy Epic to other Organization</h2>
            <div *ngIf="organizations$ |async as organizations">
              <div class="px-0">
                <mat-form-field appearance="fill" class="px-0">
                  <mat-select formControlName="organizationId" (selectionChange)="changeOrganization($event.value)">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let organization of organizations" [value]="organization.id">
                      {{organization.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('organizationId').invalid">
                    {{ 'anms.kanban-change.form.placeholder.kanbanId' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column px-0 flex-grow-1">

            <h2 class="px-1">Select Destination Portfolio </h2>
            <div *ngIf="portfolios$ |async as portfolios">
              <div class="px-0">
                <mat-form-field appearance="fill" class="px-0">
                  <mat-select formControlName="portfolioId" (selectionChange)="updatePortfolio($event.value)">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let portfolio of portfolios" [value]="portfolio.id">
                      {{portfolio.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('portfolioId').invalid">
                    {{ 'anms.kanban-change.form.placeholder.columnId' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


        </div>

        <div class="d-flex flex-column px-0 flex-grow-1">

            <h2 class="px-1">Select User Owner</h2>
            <div *ngIf="users$ |async as users">
              <div class="px-0">
                <mat-form-field appearance="fill" class="px-0">
                  <mat-select formControlName="ownerId" (selectionChange)="updateOwner($event.value)">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let user of users" [value]="user.id">
                      {{user.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('ownerId').invalid">
                    {{ 'anms.kanban-change.form.placeholder.columnId' | translate }}
                    {{ 'anms.examples.form.error1' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


        </div>


        </div>



      </div>
      <div class="form-actions">
        <div class="action-col secondary">
          <button (click)="closeDialog()" mat-button color="primary" type="button" class="btn-save" id="close">
            <span class="material-icons">close</span>
            Cancel
          </button>


        </div>
        <div class="action-col primary">
          <button  mat-raised-button color="primary" type="submit" class="btn-save" id="save">
            <span class="material-icons">check</span>
            COPY
          </button>
          </div>

      </div>

    </form>


</div>

