import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { OverlayService } from './overlay/overlay.service'
import { OverlayComponent } from './overlay/overlaycomponent/overlaycomponent.component'

import { OverlayModule } from '@angular/cdk/overlay'
import { SharedModule } from '../shared/shared.module'
import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome'

import {
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook,
  faGripLines,
  faMoneyCheck
} from '@fortawesome/free-solid-svg-icons'
import {
  faMediumM,
  faGithub,
  faHouzz
} from '@fortawesome/free-brands-svg-icons'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LayoutModule } from '@angular/cdk/layout'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

@NgModule({
  declarations: [OverlayComponent],
  imports: [
    CommonModule,
    // FormsModule,
    // ReactiveFormsModule,
    // SharedModule,
    // TranslateModule,
    // ReactiveFormsModule,
    // FormsModule,
    // MatInputModule,
    // MatFormFieldModule,
    // MatDividerModule,
    FontAwesomeModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlayModule
    // PortfolioFormsModule,
    //  ARTFormsModule,
    // RequirementFormsModule,
    // SolutionFormsModule
    // ARTFormsModule
    // ProgramIncrementFormModule,
    // OrganizationFormsModule

    /*
    SolutionFormsModule,
    TeamFormsModule,
    ARTFormsModule,
    RequirementFormsModule,
    ValueStreamFormsModule,
    ProgramIncrementFormModule,
    OrganizationFormsModule*/
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: []
})
export class OverlayFormsModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(
      faGithub,
      faMediumM,
      faPlus,
      faEdit,
      faTrash,
      faTimes,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faStream,
      faBook,
      faHouzz,
      faGripLines,
      faMoneyCheck
    )
  }
  static forRoot(): ModuleWithProviders<OverlayFormsModule> {
    return { ngModule: OverlayFormsModule, providers: [OverlayService] }
  }
  static forChild(): ModuleWithProviders<OverlayFormsModule> {
    return { ngModule: OverlayFormsModule, providers: [OverlayService] }
  }
}
