import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromOpenAIActions from '../../features/openai/open-ai.actions'
import { OverlayFormRef } from '../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../overlay-forms/overlay/overlay.service'
import { FormBuilder, FormGroup } from '@angular/forms'
import {
  ImproveRequest,
  OpenAIStore
} from '../../features/openai/open-ai.model'
import { EpicOverlayFormComponent } from '../requirement-forms/epic/epic-overlay-form.component'
import { Observable, Subscription } from 'rxjs'

@Component({
  selector: 'anms-improve-epic-form',
  templateUrl: './improve-epic-form.component.html',
  styleUrls: ['./improve-epic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImproveEpicFormComponent implements OnInit, OnDestroy {
  form: FormGroup

  parentComponent: EpicOverlayFormComponent

  descriptions$: Observable<string[]>

  subscription: Subscription

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: { epicDescription; parentComponent; nonFunctionalRequirements }
  ) {
    this.form = this.formBuilder.group({
      epicDescription: this.data.epicDescription,
      improvedEpicDescription: '',
      context: ''
    })
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.parentComponent = this.data.parentComponent as EpicOverlayFormComponent

    this.descriptions$ = this.openAIStore.filterItemsByType('epic')
    this.subscription = this.descriptions$.subscribe((val) => {
      if (val && val.length > 0) {
        const description = val[0]
        if (description) {
          const filteredDescription = description
          this.form.get('improvedEpicDescription').setValue(filteredDescription)
        }
      }
    })
  }
  onSubmit(buttonType): void {
    if (buttonType === 'load') {
      this.improveEpicRequest()
    }
  }

  improveEpicRequest() {
    if (this.form.valid) {
      const form = this.form.getRawValue()
      const req: ImproveRequest = {
        epicRequest: form.epicDescription,
        context: form.context,
        n: 1
      }
      this.store.dispatch(fromOpenAIActions.improveEpic({ data: req }))
    }
  }

  moveTextToLeft(text: string): void {
    this.form.get('epicDescription').setValue(text)
  }

  copyContentToParent() {
    const description = this.form.get('epicDescription').value
    // this.dialogRef.
    this.parentComponent.setDescription(description)
    this.dialogRef.close()
  }
  closeDialog() {
    this.dialogRef.close()
  }
}
