import { Injectable } from '@angular/core'
import { NavItem } from '../store/menu.store'

/* @Injectable({
  providedIn: 'root'
})
*/

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  showSubmenu = false
  constructor() {}

  getDefaultNavigationMenu() {
    const navigation: NavItem[] = [
      // { link: 'about', label: 'anms.menu.about', isroot: true },
      // { link: 'login', label: 'anms.menu.login' },
      {
        link: 'requirements',
        label: 'anms.menu.requirements',
        isroot: true
      },
      {
        link: 'organizations',
        label: 'anms.menu.organizations',
        isroot: true,
        type: 'organization',
        children: [
          {
            label: 'Centro Control',
            link: '#',
            portfolio: 'KVASAR Portfolio',
            icon: 'system',
            type: 'solution',
            isroot: false
          }
        ]
      },
      // { link: 'value-streams', label: 'anms.menu.value-streams', isroot: true },
      {
        link: 'program-increments',
        label: 'anms.menu.program-increment',
        isroot: true
      },
      // { link: 'teams', label: 'anms.menu.teams', isroot: true },
      {
        link: 'solutions',
        label: 'anms.menu.solutions',
        type: 'solution',
        isroot: true,
        children: [
          {
            label: 'Centro Control',
            link: '#',
            portfolio: 'KVASAR Portfolio',
            icon: 'system',
            type: 'solution',
            isroot: false
          },
          {
            label: 'Contractació Pública',
            link: '#',
            portfolio: 'LotA4',
            icon: 'service',
            type: 'solution',
            isroot: false
          }
        ]
      },
      {
        link: 'arts',
        label: 'anms.menu.arts',
        type: 'art',
        isroot: true,
        children: [
          {
            label: 'KVASAR Portfolio',
            link: '#',
            icon: 'art',
            portfolio: 'KVASAR Portfolio'
          },
          { label: 'LotA4', link: '#', icon: 'art', portfolio: 'LotA4' }
        ]
      },
      {
        link: 'portfolios',
        type: 'portfolio',
        label: 'anms.menu.portfolios',
        isroot: true,
        children: [
          { label: 'KVASAR Application', link: '#', organization: 'KVASAR' },
          { label: 'LotA4', link: '#', organization: 'DLC Technologies' }
        ]
      }
    ]
    // return navigation
    const navigationSideMenu = [
      ...navigation
      // { link: 'settings', label: 'anms.menu.settings', isroot: true }
    ]
    return navigationSideMenu
  }
  getContextNavigationMenu() {
    const navigation: NavItem[] = [
      // { link: 'about', label: 'anms.menu.about', isroot: true },
      // { link: 'login', label: 'anms.menu.login' },
      {
        link: 'Dashboard',
        label: 'anms.menu.dashboard',
        isroot: true
      },
      {
        link: 'strategic-themes-list',
        label: 'anms.menu.strategy',
        isroot: true,
        type: 'organization',
        children: [
          {
            label: 'Centro Control',
            link: '#',
            portfolio: 'KVASAR Portfolio',
            icon: 'system',
            type: 'solution',
            isroot: false
          }
        ]
      },
      // { link: 'value-streams', label: 'anms.menu.value-streams', isroot: true },
      {
        link: 'program-increments',
        label: 'anms.menu.program-increment',
        isroot: true
      },
      // { link: 'teams', label: 'anms.menu.teams', isroot: true },
      {
        link: 'solutions',
        label: 'anms.menu.solutions',
        type: 'solution',
        isroot: true,
        children: [
          {
            label: 'Centro Control',
            link: '#',
            portfolio: 'KVASAR Portfolio',
            icon: 'system',
            type: 'solution',
            isroot: false
          },
          {
            label: 'Contractació Pública',
            link: '#',
            portfolio: 'LotA4',
            icon: 'service',
            type: 'solution',
            isroot: false
          }
        ]
      },
      {
        link: 'arts',
        label: 'anms.menu.arts',
        type: 'art',
        isroot: true,
        children: [
          {
            label: 'KVASAR Portfolio',
            link: '#',
            icon: 'art',
            portfolio: 'KVASAR Portfolio'
          },
          { label: 'LotA4', link: '#', icon: 'art', portfolio: 'LotA4' }
        ]
      },
      {
        link: 'portfolios',
        type: 'portfolio',
        label: 'anms.menu.portfolios',
        isroot: true,
        children: [
          { label: 'KVASAR Application', link: '#', organization: 'KVASAR' },
          { label: 'LotA4', link: '#', organization: 'DLC Technologies' }
        ]
      }
    ]
    // return navigation
    const navigationSideMenu = [
      ...navigation
      // { link: 'settings', label: 'anms.menu.settings', isroot: true }
    ]
    return navigationSideMenu
  }
}
