import { Action, createReducer, on, createFeatureSelector } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { Team } from './team.model'
import * as TeamActions from './team.actions'

export const teamsFeatureKey = 'teams'

export interface TeamState extends EntityState<Team> {
  selectedTeamId: string | null
  // additional entities state properties
}

export const adapter: EntityAdapter<Team> = createEntityAdapter<Team>()

export const initialState: TeamState = adapter.getInitialState({
  ids: ['1'],
  entities: {
    '1': {
      id: '1',
      name: 'Team Project One',
      moto: 'Killing projects',
      picture: ''
    }
  },
  selectedTeamId: null
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,

  //  on(TeamActions.addTeam, (state, action) =>
  //  adapter.addOne(action.team, state)
  // ),

  /* on(TeamActions.upsertTeam, (state, action) =>
    adapter.upsertOne(action.team, state)
  ),
  */
  on(TeamActions.successAddTeam, (state, action) =>
    adapter.upsertOne(action.team, state)
  ),
  on(TeamActions.successUpdateTeam, (state, action) =>
    adapter.upsertOne(action.team, state)
  ),

  on(TeamActions.addTeams, (state, action) =>
    adapter.addMany(action.teams, state)
  ),
  on(TeamActions.patchTeam, (state, action) =>
    adapter.updateOne(action.team, state)
  ),
  on(TeamActions.upsertTeams, (state, action) =>
    adapter.upsertMany(action.teams, state)
  ),
  on(TeamActions.updateTeam, (state, action) =>
    adapter.updateOne(action.team, state)
  ),
  on(TeamActions.updateTeams, (state, action) =>
    adapter.updateMany(action.teams, state)
  ),
  on(TeamActions.successDeleteTeam, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(TeamActions.successDeleteTeams, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(TeamActions.successLoadTeamsSet, (state, action) =>
    adapter.upsertMany(action.teams, state)
  ),

  // on(TeamActions.loadTeams, (state, action) =>
  //  adapter.setAll(action.teams, state)
  // ),
  on(TeamActions.successLoadTeams, (state, action) =>
    adapter.setAll(action.teams, state)
  ),
  on(TeamActions.successLoadTeam, (state, action) =>
    adapter.upsertOne(action.team, state)
  ),

  on(TeamActions.successPatchTeam, (state, action) =>
    adapter.upsertOne(action.team, state)
  ),
  on(TeamActions.clearTeams, (state) => adapter.removeAll(state))
)

export function teamReducer(state: TeamState | undefined, action: Action) {
  return reducer(state, action)
}
