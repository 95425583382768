import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Inject,
  ChangeDetectorRef
} from '@angular/core'
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms'

import { Store, select } from '@ngrx/store'
import { Observable, merge, of } from 'rxjs'
import * as fromActionsKanbanItems from '../../../features/requirements-store/store/item/requirement.actions'
import * as fromAuditSelectors from '../../../features/history-audit-store/history-audit.selectors'

import {
  EnablerStoryI,
  EnablerStoryType,
  Priority,
  RequirementI,
  TaskI
} from '../../../features/requirements-store/store/requirements.model'

import { KanbanItemService } from '../../../features/requirements/services/kanban-item.service'
import { Team } from '../../../features/teams-store/store/team.model'

import { OverlayFormRef } from '../../../overlay-forms/overlay/overlay-form-ref'
import * as fromFeatureSelectors from '../../../features/feature-selectors'
import { HistoryAudit } from '../../../features/history-audit-store/history-audit.model'
import { Update } from '@ngrx/entity'
import * as fromKanbanSelectors from '../../../kanban/store/kanban-selector'
import * as fromTeamSelectors from '../../../features/teams-store/store/team.selectors'
import { StoryState } from '../user-story-form/user-story-form.component'
import { INIT_DATA1 } from '../../../overlay-forms/overlay/overlay.service'
import { startWith, switchMap } from 'rxjs/operators'
import { AttachMentResponse, File } from '../../attachment/services/model'
import { AttachmentService } from '../../attachment/services/attachment.service'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'

interface EnablerType {
  viewValue
  value
}

@Component({
  selector: 'anms-enabler-story-form',
  templateUrl: './enabler-story-form.component.html',
  styleUrls: ['./enabler-story-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TranslatePipe,
      useFactory(service: TranslateService, ref: ChangeDetectorRef) {
        return new TranslatePipe(service, ref)
      },
      deps: [TranslateService, ChangeDetectorRef]
    }
  ]
})
export class EnablerStoryFormComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize
  @ViewChild('autosize1') autosize1: CdkTextareaAutosize
  story: EnablerStoryI<TaskI>
  form: FormGroup
  enablerStory$: Observable<EnablerStoryI<TaskI>>
  teams$: Observable<Team[]>
  features$: Observable<RequirementI[]>
  auditHistoryChanges$: Observable<HistoryAudit[]>

  types: EnablerType[] = [
    { value: EnablerStoryType.Configuration, viewValue: 'Configuration' },
    { value: EnablerStoryType.DevOps, viewValue: 'DevOps' },
    { value: EnablerStoryType.Execute, viewValue: 'Execution' },
    { value: EnablerStoryType.Performance, viewValue: 'Perfomance' },
    { value: EnablerStoryType.Refactoring, viewValue: 'Refactor' },
    { value: EnablerStoryType.Spike, viewValue: 'Spike' }
  ]
  formTitle: string
  key: string
  updated: Date
  created: Date

  isUpdate = false
  states$: Observable<StoryState[]>

  file = null
  filesOfItem: File[]
  newFile: File
  // private dialogRef: OverlayFormRef

  get team(): FormControl {
    return this.form.get('teamId') as FormControl
  }

  constructor(
    private formBuilder: FormBuilder,
    // private service: OverlayService,
    private dialogRef: OverlayFormRef,
    private itemService: KanbanItemService,
    private attachmentService: AttachmentService,
    private store: Store, // private service: FormsService,
    @Inject(INIT_DATA1)
    public data: {
      kanbanId?
      teamId?
      story?
      ownerId?
      description?
      parentId?
      columnId?
      solutionId?
    }
  ) {
    this.form = this.formBuilder.group({
      name: [''],
      itemName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      storyPoints: ['', [Validators.pattern(/^-?\d*$/)]],
      acceptanceCriteria: ['', [Validators.required]],
      id: '',
      parentId: [''],
      columnId: [''],
      kanbanId: [''],
      backlogId: [''],
      order: [''],
      toKanban: [''],
      locked: [''],
      type: '',
      enablerType: ['', [Validators.required]],
      fileSource: [''],
      files: [''],
      notes: [''],
      teamId: '',
      state: ''
    })
  }

  ngOnInit(): void {
    this.form.reset()

    if ('story' in this.data) {
      this.story = this.data.story

      this.auditHistoryChanges$ = this.store.select(
        fromAuditSelectors.getHistoryAuditByRequirement(this.story.id)
      )

      this.formTitle = this.story.itemName
      this.created = this.story.creationDate
      this.updated = this.story.lastUpdate
      this.key = this.story.numeration
      if (this.story.teamId !== null && this.story.teamId !== undefined) {
        this.form.get('teamId').disable()
      }
      if (this.story.id) {
        this.isUpdate = true
      }

      if (this.story.files) {
        this.filesOfItem = this.story.files
      } else {
        this.filesOfItem = []
      }

      if (this.story.parentId !== undefined && this.story.parentId !== null) {
        this.store.dispatch(
          fromActionsKanbanItems.loadKanbanitem({ id: this.story.parentId })
        )

        if (this.story.parentId !== null && this.story.parentId !== undefined) {
          this.form.get('parentId').disable()
        }

        this.teams$ = this.store.select(
          fromFeatureSelectors.getAvailableTeamsForStoriesByParent(
            this.story.parentId
          )
        )
        // Subscribe to changes in teamId form control value
        const teamIdChanges$ = this.form.get('teamId').valueChanges.pipe(
          startWith('') // Emit an initial value
        )
        if (this.story.id) {
          // Merge both observables
          this.states$ = merge(
            teamIdChanges$.pipe(
              switchMap((teamId) =>
                // Select team based on teamId
                this.store.select(fromTeamSelectors.getTeamByIdF(teamId)).pipe(
                  switchMap((team) =>
                    // Select states based on team's kanbanId
                    team
                      ? this.store.select(
                          fromKanbanSelectors.getStatesAndColumnIdsByKanbanId(
                            team.kanbanId
                          )
                        )
                      : of([])
                  )
                )
              )
            ),
            this.store.select(
              fromFeatureSelectors.getStatesAndColumnIdsByItemId(this.story.id)
            )
          )
        }

        this.features$ = this.itemService.getAssignedFeaturesByParent(
          this.story.parentId
        )
      } else {
        const teamId = this.data.story.teamId

        this.features$ = this.store.select(
          fromFeatureSelectors.selectFeaturesAssignedToTeam(teamId)
        )
        this.teams$ = this.itemService.getTeamsFromSameART(teamId)

        this.form.get('parentId').setValue('')
      }

      this.form.patchValue(this.story)
    } else {
      this.formTitle = 'New enabler story'
      const teamId = this.data.teamId

      if (teamId !== undefined && teamId !== null) {
        this.form.get('teamId').disable()
        this.features$ = this.store.select(
          fromFeatureSelectors.selectFeaturesAssignedToTeam(teamId)
        )

        this.teams$ = this.itemService.getTeamsFromSameART(teamId)
      }

      this.form.patchValue(this.createEnablerStory())
    }
  }
  save() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()

      this.store.dispatch(
        fromActionsKanbanItems.upsertKanbanitem({ kanbanitem: entity })
      )
    }
  }

  saveNew() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()

      const callbackFunction = () => {
        this.dialogRef.close()
      }

      this.store.dispatch(
        fromActionsKanbanItems.addKanbanitem({
          kanbanitem: entity,
          callback: callbackFunction
        })
      )
      /*      this.form.reset()
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].setErrors(null)
      })
      */
    }
  }

  delete() {
    const id = this.form.value.id

    this.store.dispatch(
      fromActionsKanbanItems.deleteKanbanitem({
        id
      })
    )

    this.dialogRef.close()
  }
  closeDialog() {
    this.dialogRef.close()
  }

  updateNameStory(event) {
    if (event !== null && event !== '' && event !== this.formTitle) {
      const update: Update<RequirementI> = {
        id: this.story.id,
        changes: { itemName: event }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
    }
  }
  changeState(event) {
    if (!this.isUpdate) return

    const id = event.value

    const teamId = this.form.get('teamId').value

    const changes: { columnId: string; teamId?: string } = { columnId: id }

    if (this.story.teamId === null || this.story.teamId === undefined) {
      changes.teamId = teamId
    }

    const update: Update<RequirementI> = {
      id: this.story.id,
      changes
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  onHandleFile(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0]
      this.form.patchValue({
        files: this.file
      })

      const formData = new FormData()
      formData.append('file', this.file)
      this.attachmentService
        .uploadFile(formData)
        .subscribe((data: AttachMentResponse) => {
          this.newFile = { ...data }
          this.filesOfItem = [...this.filesOfItem, this.newFile]

          this.form.patchValue({
            files: this.filesOfItem
          })
          const update: Update<RequirementI> = {
            id: this.story.id,
            changes: { files: this.filesOfItem }
          }

          this.store.dispatch(
            fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
          )
        })
    }
  }
  downLoadFile(url: string) {
    window.location.href = url
  }
  deleteFile(id) {
    const index = this.filesOfItem.findIndex((file) => file.id === id)
    const filesArray = [...this.filesOfItem]
    if (index !== -1) {
      filesArray.splice(index, 1)
      const update: Update<RequirementI> = {
        id: this.story.id,
        changes: { files: filesArray }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
      this.filesOfItem = filesArray
      this.form.patchValue({
        files: this.filesOfItem
      })
    }
  }

  private createEnablerStory(): EnablerStoryI<TaskI> {
    return {
      id: '',
      code: '',
      acceptanceCriteria: '',
      columnId: this.data.columnId ?? null,
      itemName: '',
      description: '',
      inRoadmap: false,
      enablerType: null,
      solutionId: this.data.solutionId,
      locked: false,
      toKanban: true,
      type: 'enabler',
      parentId: this.data.parentId,
      kanbanId: this.data.kanbanId,
      name: 'enablerstory',
      priorityId: Priority.low,
      storyPoints: null,
      notes: '',
      ownerId: '',
      teamId: this.data.teamId != null ? this.data.teamId : ''
    }
  }
}
