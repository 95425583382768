import { createFeatureSelector, createSelector } from '@ngrx/store'
import { adapter } from './organization.reducer'
import { FeaturesState, selectFeatures } from '../../features.state'
import { selectRouterState } from '../../../core/core.module'
import { selectCurrentRoute, selectRouteParam } from '../../../core/core.state'

/*
export const selectOrganizationsState = createFeatureSelector<State5, State>(
  stateFeatureKey
)
*/

export const selectOrganizationState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.organizations
)
/*
export const selectOrganizations = createSelector(
  selectOrganizationsState,
  (state: State) => state.organizations
)
*/
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectOrganizationState)

export const getOganizationById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

// Define a selector to extract the specific route segment
export const selectOrganizationRoute = createSelector(
  selectRouterState, // Use your router state selector
  (routerState) => {
    // Check if the router state matches your desired route pattern
    const urlPattern = /^\/organizations\/organization\/(.+)$/ // Regular expression for the pattern
    const match = routerState?.state?.url?.match(urlPattern)

    if (match) {
      // Extract the 'id' parameter value from the matched URL
      const id = match[1]

      // Return an object with the extracted 'id' and any other relevant data
      return id
      // Add any other data you need from the route
    } else {
      // Return a default or empty value if the route doesn't match
      return null
    }
  }
)

export const selectOrganizationId = selectRouteParam('id')
// Create a selector that accesses the nested 'id' parameter
// Create a selector to access the organization ID

export const selectNestedOrganizationId = createSelector(
  selectRouterState,
  (routerState) => {
    const segments = routerState.state.url.split('/')
    // Find the 'organization' segment and get the ID next to it
    const organizationIndex = segments.indexOf('organization')
    if (organizationIndex !== -1 && segments.length > organizationIndex + 1) {
      return segments[organizationIndex + 1]
    }
    return null // Return null if 'id' not found
  }
)

export const selectNestedOrganizationId2 = createSelector(
  selectCurrentRoute,
  (currentRoute) => {
    // Check if the current route matches the desired pattern
    const url = currentRoute.state.url
    if (url.startsWith('/organizations/organization/')) {
      // Extract the organization ID from the URL
      const segments = url.split('/')
      const organizationIndex = segments.indexOf('organization')
      if (organizationIndex !== -1 && segments.length > organizationIndex + 1) {
        return segments[organizationIndex + 1]
      }
    }
    return null // Return null if the route doesn't match the pattern
  }
)
