import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'

import {
  environment as env,
  environment
} from '../../../environments/environment'
import { AuthenticationRequest, AuthState } from './auth.models'

import { JwtHelperService } from '@auth0/angular-jwt'

import { select, Store } from '@ngrx/store'
import {
  combineLatest,
  interval,
  Observable,
  of,
  OperatorFunction,
  Subscription,
  throwError,
  zip
} from 'rxjs'
import { AuthResponse } from '../../core/auth/auth.model'
import { AuthService, User } from '@auth0/auth0-angular'
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  flatMap,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators'

import { selectAuthState } from '../core.state'

export interface AuthServiceI {
  login(username: string, password: string): Observable<AuthResponse>
  refreshAccessToken(): Observable<string>
  logout(): Observable<User>
  /**
   * METODO QUE DEVUELVE SI EL USER ESTA AUTENTIFICADO O NO, NO REALIZA ACCIONES.
   */
  isLoggedIn(): Observable<boolean>
  isLoggedOut(): Observable<boolean>
  isDateBeforeToday(date)
  getExpirationDate(): Observable<Date>
  // callBackMethod() {}
  setResponse(response: AuthResponse)
  getIsAuthChecker(): boolean
  setisAuthChecker(value: boolean)
}
export class AuthServiceGeneric implements AuthServiceI {
  constructor() {}
  login(username: string, password: string): Observable<AuthResponse> {
    return
  }
  refreshAccessToken(): Observable<any> {
    return
  }

  logout(): Observable<User> {
    return
  }

  getUser(): Observable<{ id: string; name: string }> {
    return
  }
  /**
   * METODO QUE DEVUELVE SI EL USER ESTA AUTENTIFICADO O NO, NO REALIZA ACCIONES.
   */
  isLoggedIn(): Observable<boolean> {
    return
  }
  isLoggedOut(): Observable<boolean> {
    return
  }
  isDateBeforeToday(date) {}
  getExpirationDate(): Observable<Date> {
    return
  }
  // callBackMethod() {}
  setResponse(response: AuthResponse) {}
  getIsAuthChecker(): boolean {
    return
  }
  setisAuthChecker(value: boolean) {}

  getAccessToken(): Observable<string> {
    return
  }
}

@Injectable()
export class AuthServiceLocaL implements AuthServiceGeneric {
  httpOptions = {
    headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')
  }

  today: Date

  apiurl = env.loginURL
  refreshTokenURL = env.refreshTokenURL
  logoutURL = env.logoutURL

  // eslint-disable-next-line ngrx/no-multiple-global-stores
  constructor(private http: HttpClient, private store: Store) {
    this.today = new Date()
  }
  getAccessToken(): Observable<string> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => auth.accessToken)
    )
  }

  // callBackMethod(): void {
  //   return null
  // }
  setResponse(response: AuthResponse): void {
    return null
  }
  setisAuthChecker(value: boolean) {}
  getIsAuthChecker(): boolean {
    return null
  }

  login(username: string, password: string): Observable<AuthResponse> {
    const authReq: AuthenticationRequest = { username, password }

    return this.http

      .post<AuthResponse>(this.apiurl, authReq, this.httpOptions)

      .pipe(take(1))
    // this is just the HTTP call,
    // we still need to handle the reception of the token
  }
  refreshAccessToken(): Observable<any> {
    return this.http.get<string>(this.refreshTokenURL, {}).pipe(take(1))
    // this is just the HTTP call,
    // we still need to handle the reception of the token
  }
  /*
  private setSession(authResult) {
    const expiresAt = new Date(
      this.today.getTime() + (1000 + authResult.expiresIn + 60 + 60)
    )

    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()))
  }
  */

  logout(): Observable<User> {
    return this.http.post<User>(this.logoutURL, {}).pipe(take(1))
  }

  public isLoggedIn(): Observable<boolean> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => {
        const today: Date = new Date()
        const isExpired: boolean =
          today.getTime() > new Date(auth.expirationDate).getTime()

        if (
          !auth ||
          !auth.isAuthenticated ||
          !auth.expirationDate ||
          isExpired === true
        ) {
          return false
        } else return true
      })
    )
  }
  getUser(): Observable<{ id: string; name: string }> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => ({ id: auth.id, name: auth.username }))
    )
  }

  isLoggedOut(): Observable<boolean> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => {
        if (!auth || !auth.isAuthenticated || !auth.expirationDate) {
          return true
        } else return false
      })
    )
  }
  isDateBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString())
  }

  getExpirationDate(): Observable<Date> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => auth.expirationDate)
    )
  }
}

@Injectable()
export class AuthServiceAuth0 implements AuthServiceGeneric {
  response: AuthResponse
  accessToken$: Observable<string>

  isAuthenticated$: Observable<boolean>

  localeAuth$: Observable<boolean>
  private subscription: Subscription

  private _isAuthChecker = false

  constructor(
    private auth0: AuthService,
    // eslint-disable-next-line ngrx/no-multiple-global-stores
    private readonly store: Store,
    private jtwHelper: JwtHelperService
  ) {
    this.localeAuth$ = this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth: AuthState) => {
        const today: Date = new Date()
        const isExpired: boolean =
          today.getTime() > new Date(auth.expirationDate).getTime()

        if (
          !auth ||
          !auth.isAuthenticated ||
          !auth.expirationDate ||
          isExpired === true
        ) {
          return false
        } else return true
      })
    )

    this.isAuthenticated$ = combineLatest([
      this.auth0.isAuthenticated$,
      this.localeAuth$
    ]).pipe(
      tap(([isAuth0Authenticated, isAuthenticated]) => {
        if (isAuth0Authenticated === true && isAuthenticated === true) {
          console.log({
            logueado: isAuth0Authenticated,
            msg: 'Estas logueado en Auth0 y  hay true en el local storage'
          })
        } else if (isAuth0Authenticated === true && isAuthenticated === false) {
          console.log({
            logueado: isAuth0Authenticated,
            msg:
              'Estas logueado en Auth0 pero no hay o ha expirado el token en el local storage'
          })
        }
      }),
      map(([isAuth0Authenticated, isAuthenticated]) => {
        //  this.setisAuthChecker(resp)

        if (isAuth0Authenticated === true && isAuthenticated === true) {
          // SE CHEQUEA QUE ESTA LOGGEADO Y TODO OK

          return true
        } else if (isAuth0Authenticated === true && isAuthenticated === false) {
          return false
        } else {
          // console.log({
          //   logueado: isAuth0Authenticated,
          //   msg: 'NO ESTAS LOGUEADO'
          // })

          return false
          // this.setisAuthChecker(false)
        }
      }),
      distinctUntilChanged(), // Add this line to ensure only distinct values are emitted,
      shareReplay(1)
    )
    // When we refresh
    this.subscription = this.isAuthenticated$.subscribe()
  }
  getAccessToken(): Observable<string> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => auth.accessToken)
    )
  }
  getExpirationDate(): Observable<Date> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => auth.expirationDate)
    )
  }
  getUser(): Observable<{ id: string; name: string }> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => ({ id: auth.id, name: auth.username }))
    )
  }

  isLoggedOut(): Observable<boolean> {
    return this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(selectAuthState),
      map((auth) => {
        if (!auth || !auth.isAuthenticated || !auth.expirationDate) {
          return true
        } else return false
      })
    )
  }
  login(username: string, password: string): Observable<AuthResponse> {
    return of(this.response)
  }

  isDateBeforeToday(date: any): void {
    throw new Error('Method not implemented.')
  }
  getExpiration(): void {
    throw new Error('Method not implemented.')
  }

  ngOnInit(): void {}

  // METODO QUE DEVUELVE SI EL USER ESTA AUTENTIFICADO O NO, NO REALIZA ACCIONES
  public isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated$
  }

  public logout(): Observable<User> {
    // this.setisAuthChecker(false)
    // if (this.subscription) this.subscription.unsubscribe()
    // NOT NECESSAY BECAUSE AUTH= logouts for default to returnTO
    // this.store.dispatch(fromAuthActions.authLogout())
    // localStorage.removeItem('ANMS-AUTH')

    this.auth0.logout({
      returnTo: window.location.origin + '/#/login',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.auth0.clientId
    })
    return of([])
    // this.auth0.logout({ returnTo: environment.logoutURL })
  }

  setisAuthChecker(value: boolean) {
    // this.store.dispatch(fromAuthActions.authCheck())
    this._isAuthChecker = value
  }
  getIsAuthChecker(): boolean {
    return this._isAuthChecker
  }

  setResponse(response: AuthResponse) {
    this.response = response
  }
  refreshAccessToken(): Observable<any> {
    const options = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        rotation_type: 'rotating',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        expiration_type: 'expiring',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        token_lifetime: '2592000',
        leeway: 3
      }
    }

    return this.auth0.getAccessTokenSilently(options)

    // return of(this.response)
  }
}
