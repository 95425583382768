
  <form [formGroup]="form" (ngSubmit)="saveNew()" class="default-form p-0">
    <div class="input-wrapper d-flex flex-column flex-md-row">
        <mat-form-field appearance="fill" class="px-0"  class="w-md-100 w-md-170">
          <mat-label>Type Of Relation</mat-label>

          <mat-select formControlName="relationType" #newLinkedFeature>
            <mat-option value="dependsOn">
              Depends On
            </mat-option>
            <mat-option value="isRelatedTo">
              Is Related To
            </mat-option>
          </mat-select>
        </mat-form-field>


            <mat-form-field appearance="fill" class="px-0 flex-grow-1 w-md-100">
              <mat-label>Dependency</mat-label>
              <input type="text" matInput  formControlName="relatedItem" [matAutocomplete]="linked">
              <mat-autocomplete #linked="matAutocomplete" [displayWith]="displayFn">
                <ng-container *ngIf="filtered$ | async as features">
                  <mat-option *ngFor="let feature of features" [value]="feature">
                   <small> {{feature.numeration}}</small> - {{feature.description}}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>


            </mat-form-field>
            <div class="d-flex flex-row flex-nowrap actions">
              <button mat-flat-button color="primary"   type="submit" class="btn-secondary"><span class="material-icons">check</span></button>
              <button mat-flat-button style="cursor: pointer;" type="button" (click)="closeDialog()"><span class="material-icons">close</span></button>

            </div>


    </div>
   </form>

