import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AuthServiceGeneric } from '../../../core/auth/auth.service'
import { authLogout, selectSettingsLanguage } from '../../../core/core.module'
import { actionSettingsChangeLanguage } from '../../../core/settings/settings.actions'
import { Observable } from 'rxjs'

@Component({
  selector: 'anms-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountMenuComponent implements OnInit {
  @Output() closeDialogEvent = new EventEmitter()
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he']
  language$: Observable<string>
  constructor(private store: Store, private authService: AuthServiceGeneric) {}

  ngOnInit(): void {
    this.language$ = this.store.pipe(select(selectSettingsLanguage))
  }

  onLogoutClick() {
    // this.router.navigate(['/logout'])
    this.store.dispatch(authLogout())

    this.closeDialogEvent.emit()
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }))
  }

  onNavigationClick() {
    this.closeDialogEvent.emit()
  }
}
