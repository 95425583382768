import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog'
import { ColorPickerControl } from '@iplab/ngx-color-picker'
import { select, Store } from '@ngrx/store'
import { DialogData } from '../../../road-map/lane-list/lane-row/bar-list-container/bar-row-container/bar-row-wrapper/item-container/draggable-wrapper.component'
import { EpicOverlayFormComponent } from '../epic-overlay-form.component'

@Component({
  selector: 'anms-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorpickerComponent implements OnInit {
  public githubControl = new ColorPickerControl().setValueFrom('#1273DE')
  colorpallete = false
  styleColor

  constructor(
    public dialogRef: MatDialogRef<EpicOverlayFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.githubControl.setColorPresets([
      '#000000',
      '#708090',
      '#4169e1',
      // '#795548',
      '#696969',

      // grises
      '#5e5e5e',
      '#6f6f6f',
      '#828282',
      '#aaaaaa',
      '#c6c6c6',
      // Azules
      '#060d96',
      '#0e1bb2',
      '#1529d0',
      '#4749fe',
      '#7360ff',
      '#9277ff',
      '#ac90ff',
      '#c4a8ff',
      // ROJOS
      '#c20000',
      '#e00000',
      '#ff0000',
      '#ff4040',
      '#ff7676',
      '#670f22',
      '#6a041a',
      '#9a0526',
      '#ab022f',
      '#cd0c36',
      // Verdes
      '#008000',
      '#258d19',
      '#4ea93b',
      // AMARILLOS
      '#bfb800',
      '#dad100',
      '#f6ea00',
      '#fbf451',
      '#ffff7d',
      '#ffff36',
      '#ff9800',

      '#ffc107',
      '#1e90ff',
      '#4caf50',
      '#333333',
      '#8d99a6',
      '#6787e7',
      '#94776d',
      '#878787',
      '#ffcd39',
      '#4ba6ff',
      '#70bf73',
      '#666666',
      '#a9b3bc',
      '#8da5ed',
      '#af9991',
      '#a5a5a5',
      '#ffda6a',
      '#78bcff',
      '#94cf96',
      '#999999',
      '#c6ccd3',
      '#b3c3f3',
      '#c9bbb6',
      '#c3c3c3',
      '#ffe69c',
      '#a5d3ff',
      '#b7dfb9'
    ])
    this.styleColor = `background-color: ${this.githubControl.value.toHexString()}, color: ${this.githubControl.value.toHexString()};`
  }

  openCloseColor(value?: ColorPickerControl) {
    this.dialogRef.close(value)
  }
}
