import { Injectable } from '@angular/core'
import { CanActivate, CanLoad, Router } from '@angular/router'

import { Observable, Subscription } from 'rxjs'

import { tap, take } from 'rxjs/operators'

import { AuthServiceGeneric } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {
  private authSubscription: Subscription
  constructor(
    private authService: AuthServiceGeneric,
    private router: Router
  ) {}

  // canActivate(): Observable<boolean> {
  //   return this.store.pipe(select(selectIsAuthenticated));
  // }

  canActivate(): Observable<boolean> {
    return this.checkAuthentication()
  }

  canLoad(): Observable<boolean> {
    return this.checkAuthentication()
  }
  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe()
    }
  }

  // Why use take(1) ? route guard based on a stream in an Angular application

  // To ensure that the route guard reacts to changes in the this.authService.isLoggedIn() observable even after the initial check, you
  // can modify the code to subscribe to the observable within the guard and update the route guard's behavior when the authentication
  // status changes
  private checkAuthentication(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authSubscription = this.authService
        .isLoggedIn()
        .pipe(
          tap((loggedIn) => {
            if (!loggedIn) {
              localStorage.removeItem('ANMS-AUTH')
              this.router.navigate(['login'])
            }
            observer.next(loggedIn)
            observer.complete()
          })
        )
        .subscribe()
    })
  }
}
