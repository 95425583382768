import { ElementRef, HostListener, Injectable } from '@angular/core'
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling'
import { Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ScrollDirectionService {
  private scrollDirection: 'up' | 'down' = 'down' // Initial scroll direction
  private prevScrollY = 0 // Track previous scroll position
  private scrollingThreshold = 100 // Adjust this threshold as needed
  private scrollSubject = new Subject<boolean>()

  constructor(private scrollDispatcher: ScrollDispatcher) {
    this.setupScrollObservables()
  }

  // @HostListener('window:scroll', ['$event'])

  getScrollDirection(): Observable<boolean> {
    return this.scrollSubject.asObservable()
  }
  private onWindowScroll(event) {
    const scrollY = window.scrollY

    if (scrollY > this.prevScrollY + this.scrollingThreshold) {
      this.prevScrollY = scrollY
      this.scrollSubject.next(true) // Scrolling up
    } else if (scrollY < this.prevScrollY - this.scrollingThreshold) {
      this.prevScrollY = scrollY
      this.scrollSubject.next(false) // Scrolling down
    }
  }

  private setupScrollObservables() {
    this.scrollDispatcher
      .scrolled()
      .pipe(
        map((cdk: CdkScrollable) => {
          if (cdk) {
            const containerOffset =
              cdk.getElementRef().nativeElement.scrollTop || 0

            if (containerOffset > this.prevScrollY + this.scrollingThreshold) {
              this.prevScrollY = containerOffset
              this.scrollSubject.next(true) // Scrolling up
            } else if (
              containerOffset <
              this.prevScrollY - this.scrollingThreshold
            ) {
              this.prevScrollY = containerOffset
              this.scrollSubject.next(false) // Scrolling down
            }
          }
        })
      )
      .subscribe()

    // Handle window:scroll event
    // this.onWindowScroll()
    // Handle window:scroll event
    window.addEventListener('scroll', this.onWindowScroll.bind(this))
  }
}
