import { createFeatureSelector, createSelector } from '@ngrx/store'

import { adapter, PortfolioState } from './portfolio-safe.reducer'

import { PortfolioSafe } from './portfolio-safe.model'

import { FeaturesState, selectFeatures } from '../../features.state'
import { Id } from '../../arts-store/art.model'

export const selectPortfoliosState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.portfolios
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectPortfoliosState)

export const selectPortfoliosArray = createSelector(
  selectEntities,
  (entities) => Object.values(entities)
)
/*
export const selectPortfolioSAFEEntities = createSelector(
  selectPortfolioSAFE,
  (state) => state.entities
)*/

// ENABLE MEMOIZATION FOR PROPS
export const getPortfolioSafe = () =>
  createSelector(selectEntities, (entities, props) => entities[props.id])

export const getPortfolioSafeById = (id) =>
  createSelector(selectEntities, (entities) => entities[id])

export const selectedPortfolio = (state: PortfolioState) =>
  state.selectedPortfolioId

export const getSelectedPortfolioSAFEFromStore = createSelector(
  selectPortfoliosState,
  (state: PortfolioState) => {
    // console.log('from storectx', state);
    const ctx: PortfolioSafe = {
      ...state.entities[state.selectedPortfolioId]
    }
    // console.log("from storectx",ctx);
    return ctx
  }
)
export const getSelectedEntityPortfolioSAFE = createSelector(
  selectPortfoliosState,
  (state: PortfolioState) => state.entities[state.selectedPortfolioId]
)

export const getPortfoliosByIds = (ids: String[]) =>
  createSelector(selectAll, (entities: PortfolioSafe[]) =>
    entities.filter((item) => ids.some((v) => v === item.id))
  )

/*
export const selectPortfolioId = selectRouteParam('id')

export const selectSelectedportfolioSAFE = createSelector(
  selectEntities,
  selectCurrentRoute,
  (entities, params) => {
    // console.log(params.routeConfig.path);
    if (params.routeConfig.path.indexOf('portfolio/:id') > -1)
      return entities[params.params.id]
    else return
  }
)
export const selectSelectedportfolioSAFEByParam = createSelector(
  selectEntities,
  selectPortfolioId,
  (entities, id) => entities[id]
)
*/
export const selectPortfoliosByOrganizationId = (organizationId: string) =>
  createSelector(
    selectAll,
    (entities: PortfolioSafe[]) =>
      entities.filter((item) => item.organizationId === organizationId)
    // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
    // return filteredEntities;
  )

export const selectPortfolioSAFEByKanbanId = (kanbanId: string) =>
  createSelector(selectAll, (entities: PortfolioSafe[]) =>
    entities.find((item) => item.kanbanId === kanbanId)
  )
