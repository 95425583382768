import { Injectable } from '@angular/core'
import { DexieService } from '../dexieService/dexie.service'

const APP_PREFIX = 'ANMS-'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private dexieService: DexieService) {}

  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          )
        let currentStateRef = state
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey))
            return
          }
          currentStateRef[key] = currentStateRef[key] || {}
          currentStateRef = currentStateRef[key]
        })
      }
      return state
    }, {})
  }

  setItem(key: string, value: any) {
    /**
     * We only add settings and Auth information to the localstorage
     */
    if (key === 'AUTH' || key === 'SETTINGS' || key === 'testKey') {
      localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value))
      return
    } else {
      /**
       * If the key is an entity we add it to the IdexedDb
       */

      this.dexieService.updateIndexedDb(key, value)
    }
  }

  /**
   * Add or update entities to the table. It is asynchronous.
   * @param key
   * @param value
   */

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`))
  }

  removeItem(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`)
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  testLocalStorage() {
    const testValue = 'testValue'
    const testKey = 'testKey'
    let retrievedValue: string
    const errorMessage = 'localStorage did not return expected value'
    this.setItem(testKey, testValue)
    retrievedValue = this.getItem(testKey)
    this.removeItem(testKey)
    if (retrievedValue !== testValue) {
      throw new Error(errorMessage)
    }
  }
}
