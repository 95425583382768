import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import { AuthService } from '@auth0/auth0-angular'
import { Store } from '@ngrx/store'
import { environment } from '../../../environments/environment'

import {
  AuthServiceLocaL,
  AuthServiceGeneric,
  AuthServiceAuth0
} from './auth.service'

export function authFactory(
  http: HttpClient,
  auth: AuthService,
  jtwHelper: JwtHelperService,
  store: Store
): AuthServiceGeneric {
  switch (environment.envName) {
    case 'INT': {
      return new AuthServiceAuth0(auth, store, jtwHelper)
    }

    case 'PROD': {
      return new AuthServiceAuth0(auth, store, jtwHelper)
    }
    case 'DEV': {
      return new AuthServiceLocaL(http, store)
    }
  }
}
