import { FormControl } from '@angular/forms'

export function numberValidator(arg: FormControl) {
  if (arg.value !== null && arg.value !== undefined) {
    const value: any = arg.value.toString()
    const regex = '^[0-9]*$'
    if (!value.match(regex)) {
      return { errorNaN: true, message: 'it must be a number' }
    } else {
      return null
    }
  } else {
    return null
  }
}
