import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata,
  query,
  animateChild,
  group
} from '@angular/animations'

/* export const mobileDialogAnimation = trigger('mobileDialogAnimation', [
  state(
    'void',
    style({
      transform: 'translateY(100%)',
      opacity: 0
    })
  ),
  state(
    '*',
    style({
      transform: 'translateY(0)',
      opacity: 1
    })
  ),
  state(
    'normal',
    style({
      transform: 'translateY(0)',
      opacity: 1
    })
  ),
  transition('void <=> *', animate('300ms ease-in-out')),
  transition('normal => *', animate('0ms'))
])
*/
export const mobileDialogAnimation = trigger('mobileDialogAnimation', [
  transition('* => *', [
    query(
      '.d1',
      [
        style({ transform: 'translateY(100%)', opacity: 0 }), // Initial state: component starts offscreen at the bottom with opacity 0
        animate(
          '150ms ease-in-out',
          style({ transform: 'translateY(0%)', opacity: 1 })
        ) // Final state: component moves to its original position with opacity 1
      ],
      { optional: true }
    )
  ])
])

export const noneAnimation: AnimationTriggerMetadata = trigger('none', [
  state('*', style({})), // Define an empty state to avoid any changes
  transition('* => *', animate(0)) // Define a transition with 0ms duration to prevent any animation
])
