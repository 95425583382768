import { LOCALE_ID, Provider } from '@angular/core'
import { LocaleService } from './locale.service'

export class LocaleId extends String {
  constructor(private localeService: LocaleService) {
    super()
  }

  toString(): string {
    return this.localeService.currentLocale
  }

  valueOf(): string {
    return this.toString()
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  // useValue: LocaleService.,
  deps: [LocaleService]
}
