import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Organization } from './organization.model'

export const loadOrganizations = createAction(
  '[Organization/API] Load Organizations'
)
export const loadPortfoliosByOrganization = createAction(
  '[Organization/API] Load Portfolios by Organization',
  props<{ organizationId: string }>()
)
export const loadOrganization = createAction(
  '[Organization/API] Load Organization',
  props<{ id: string }>()
)
export const successLoadOrganization = createAction(
  '[Organization/API] Success Load Organization',
  props<{ organization: Organization }>()
)

export const successLoadOrganizations = createAction(
  '[Organization/API] Success Load Organizations',
  props<{ organizations: Organization[] }>()
)
export const successUpdateOrganization = createAction(
  '[Organization/API] Success Update Organization',
  props<{ organization: Organization }>()
)
export const addOrganization = createAction(
  '[Organization/API] Add Organization',
  props<{ organization: Organization }>()
)
export const successAddOrganization = createAction(
  '[Organization/API] Success Add Organization',
  props<{ organization: Organization }>()
)

export const upsertOrganization = createAction(
  '[Organization/API] Upsert Organization',
  props<{ organization: Organization }>()
)
export const patchOrganization = createAction(
  '[Organization/API] Patch Organization',
  props<{ organization: Update<Organization> }>()
)
export const successPatchOrganization = createAction(
  '[Organization/API] Success Patch Organization',
  props<{ organization: Organization }>()
)

export const addOrganizations = createAction(
  '[Organization/API] Add Organizations',
  props<{ organizations: Organization[] }>()
)

export const upsertOrganizations = createAction(
  '[Organization/API] Upsert Organizations',
  props<{ organizations: Organization[] }>()
)

export const updateOrganization = createAction(
  '[Organization/API] Update Organization',
  props<{ organization: Update<Organization> }>()
)

export const updateOrganizations = createAction(
  '[Organization/API] Update Organizations',
  props<{ organizations: Update<Organization>[] }>()
)

export const deleteOrganization = createAction(
  '[Organization/API] Delete Organization',
  props<{ id: string }>()
)

export const successDeleteOrganization = createAction(
  '[Organization/API] Success Delete Organization',
  props<{ id: string }>()
)

export const deleteOrganizations = createAction(
  '[Organization/API] Delete Organizations',
  props<{ ids: string[] }>()
)

export const clearOrganizations = createAction(
  '[Organization/API] Clear Organizations'
)
export const error = createAction(
  '[Organization/API] Error',
  props<{ error }>()
)
