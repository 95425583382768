import { Solution } from './solutions.model'
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity'
import * as SolutionActions from './solutions.actions'
import { SolutionState } from './solution.selectors'
import { Action, createReducer, on } from '@ngrx/store'

export const FEATURE_NAME = 'solutions'
export function sortByName(a: Solution, b: Solution): number {
  return a.name.localeCompare(b.name)
}

export const solutionAdapter: EntityAdapter<Solution> = createEntityAdapter<Solution>(
  {
    sortComparer: sortByName
  }
)

export const initialState: SolutionState = solutionAdapter.getInitialState({
  ids: ['123', '124'],
  entities: {
    '123': {
      id: '123',
      name: 'Servei de manteniment d aplicacions',
      code: 'CODE1',
      type: 'Project',
      leader: 'javier corbacho',
      portfolioId: '1'
    },
    '124': {
      id: '124',
      name: 'TAULAT',
      code: 'TAU',
      type: 'System',
      leader: 'F Sants',
      portfolioId: '1'
    }
  },
  selectedSolutionId: null
})

export const reducer = createReducer(
  initialState,
  on(SolutionActions.successAddSolution, (state, action) =>
    solutionAdapter.addOne(action.solution, state)
  ),
  /*
  on(SolutionActions.upsertSolution, (state, action) =>
    solutionAdapter.upsertOne(action.solution, state)
  ),
  */
  on(SolutionActions.addSolutions, (state, action) =>
    solutionAdapter.addMany(action.solutions, state)
  ),
  on(SolutionActions.upsertSolutions, (state, action) =>
    solutionAdapter.upsertMany(action.solutions, state)
  ),
  /* on(SolutionActions.updateSolution, (state, action) =>
    solutionAdapter.upsertOne(action.solution, state)
  ),

  on(SolutionActions.patchSolution, (state, action) =>
    solutionAdapter.updateOne(action.solution, state)
  ),
  */
  on(SolutionActions.successUpdateSolution, (state, action) =>
    solutionAdapter.setOne(action.solution, state)
  ),
  /*
  on(SolutionActions.updateSolutions, (state, action) =>
    solutionAdapter.updateMany(action.solutions, state)
  ),
  */
  on(SolutionActions.successDeleteSolution, (state, action) =>
    solutionAdapter.removeOne(action.id, state)
  ),
  on(SolutionActions.successAddValueStreamToSolution, (state, action) =>
    solutionAdapter.upsertOne(action.solution, state)
  ),
  on(SolutionActions.successLoadSolutions, (state, action) =>
    solutionAdapter.setAll(action.solutions, state)
  ),
  on(SolutionActions.successLoadSolutionsSet, (state, action) =>
    solutionAdapter.upsertMany(action.solutions, state)
  ),
  on(SolutionActions.clearSolutions, (state) =>
    solutionAdapter.removeAll(state)
  ),
  on(SolutionActions.setSolution, (state, action) =>
    solutionAdapter.setOne(state.entities[action.selectedSolutionId], state)
  )
)

export function solutionReducer(
  state: SolutionState | undefined,
  action: Action
) {
  return reducer(state, action)
}
