import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete'
import {
  MatSelect,
  MAT_SELECT_SCROLL_STRATEGY_PROVIDER
} from '@angular/material/select'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { debounceTime, map, startWith, withLatestFrom } from 'rxjs/operators'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import * as fromKanbanItemSelectors from '../../../../features/requirements-store/store/item/requirement.selectors'
import {
  FeatureI,
  Relation,
  RequirementI,
  StoryI
} from '../../../../features/requirements-store/store/requirements.model'
import { FeatureFormComponent } from '../feature-form/feature-form.component'

export interface ItemRelation {
  relationId
  relatedItemId
}

export interface Data {
  feature: FeatureI<StoryI>
  parentComponent: Component // You can replace 'Component' with the specific type of Angular component if available
}

@Component({
  selector: 'anms-link-features-dynamic-form',
  templateUrl: './link-features-dynamic-form.component.html',
  styleUrls: ['./link-features-dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkFeaturesDynamicFormComponent implements OnInit, AfterViewInit {
  @Input() data: Data
  @ViewChild('newLinkedFeature', { static: true }) newLinkedFeature: MatSelect

  form: FormGroup
  parentComponent: FeatureFormComponent
  featuresList$: Observable<RequirementI[]>
  filtered$: Observable<RequirementI[]>
  currentLinkedFeautres = []
  constructor(private formBuilder: FormBuilder, private store: Store) {
    this.form = this.formBuilder.group({
      relatedItem: [''],
      relationType: ['']
    })
  }

  ngOnInit(): void {
    this.parentComponent = this.data.parentComponent as FeatureFormComponent
    // this.form.controls['parentId'].setValue(this.data.parentId)
    // this.form.controls['type'].setValue('business')
    // this.form.controls['name'].setValue('userstory')
    if (this.data.feature.parentId) {
      this.featuresList$ = this.store.select(
        fromKanbanItemSelectors.getChildrenKanbanItems(
          this.data.feature.parentId
        )
      )
    } else {
      // SI NO TIENE PADRE
      this.featuresList$ = this.store.select(
        fromKanbanItemSelectors.getItemsByKanbanIdWithColumnId(
          this.data.feature.kanbanId
        )
      )
    }

    const obs$: Observable<string> = this.form
      .get('relatedItem')
      .valueChanges.pipe(
        debounceTime(500)

        // map(value => typeof value === 'string' ? value : value.name),
        // map(name => name ? this._filter(name) : this.options.slice())
      )

    /*    this.filtered$ = this.featuresList$.pipe(
      withLatestFrom(obs$),
      map(([features, filter]) =>
        features.filter(
          (feature) =>
            feature.description.search(new RegExp(filter as string, 'i')) !== -1
        )
      )
    )
    */

    this.filtered$ = combineLatest([
      this.featuresList$,
      obs$.pipe(startWith('')),
      this.parentComponent.linkedFeatures$
    ]).pipe(
      map(([features, filter, linkedFeatures$]) => {
        const linkedFeatureIds = linkedFeatures$.map((f) => f.feature.id)
        // DEBIDO AL EFECTO DE EMITIR UN BLANCO AL INICIAR
        if (filter !== '')
          return features.filter(
            (feature) =>
              (feature.numeration.search(new RegExp(filter, 'i')) !== -1 ||
                feature.description.search(
                  new RegExp(filter as string, 'i')
                ) !== -1) &&
              linkedFeatureIds.find((id) => id === feature.id) === undefined
          )
        else return features
      })
    )

    this.currentLinkedFeautres = this.data.feature.relations
      ? this.data.feature.relations
      : []
  }

  ngAfterViewInit(): void {
    // Focus always better in timeout
    setTimeout(() => {
      this.newLinkedFeature.focus()
    }, 125)
  }
  saveNew() {
    if (this.form.valid) {
      const newLikedFeature = this.form.getRawValue()
      // newLikedFeature.relatedFeature = {
      //   id: newLikedFeature.relatedFeature
      // }
      const relation: Relation = {
        relatedItemId: newLikedFeature.relatedItem.id,
        relationType: newLikedFeature.relationType
      }

      this.store.dispatch(
        fromActionsKanbanItems.addRelatedItem({
          id: this.data.feature.id,
          relation: relation
        })
      )
      this.closeDialog()
      /*
      // this.form.get('relatedItemId').setValue('')
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).setErrors(null)
      })
      setTimeout(() => {
        this.form.get('relatedItem').setValue('')
        this.newLinkedFeature.focus()
      }, 25)
      */
    }
  }

  resetForm() {
    this.form.controls['relatedItem'].setValue('')
    this.form.controls['relationType'].setValue('')

    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null)
    })
  }
  closeDialog() {
    this.parentComponent.closeRelatedFeatureForm()
  }

  onEnter(event: any) {
    event.stopPropagation()
    this.saveNew()
    setTimeout(() => {
      this.parentComponent.linkFeature(this.data.feature)
    }, 25)
  }
  displayFn(req: RequirementI): string {
    return req && req.numeration && req.description
      ? req.numeration + ' - ' + req.description
      : ''
  }
}
