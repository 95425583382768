import { SettingsState, NIGHT_MODE_THEME } from './settings.model'
import {
  actionSettingsChangeAnimationsElements,
  actionSettingsChangeAnimationsPage,
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeAutoNightMode,
  actionSettingsChangeHour,
  actionSettingsChangeLanguage,
  actionSettingsChangeStickyHeader,
  actionSettingsChangeTheme,
  actionSettingsChangePaginationPortfolioSAFE
} from './settings.actions'
import { Action, createReducer, on } from '@ngrx/store'

const languagesAvaiable = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he']
const initLanguage = languagesAvaiable.includes(
  navigator.language.substring(0, 2)
)
  ? navigator.language.substring(0, 2)
  : 'en'

export const initialState: SettingsState = {
  language: initLanguage,
  theme: 'DEFAULT-THEME',
  autoNightMode: false,
  nightTheme: NIGHT_MODE_THEME,
  stickyHeader: true,
  pageAnimations: true,
  pageAnimationsDisabled: false,
  elementsAnimations: true,
  hour: 0,
  pageSize: 10
}

const reducer = createReducer(
  initialState,
  on(
    actionSettingsChangeLanguage,
    actionSettingsChangeTheme,
    actionSettingsChangeAutoNightMode,
    actionSettingsChangeStickyHeader,
    actionSettingsChangeAnimationsPage,
    actionSettingsChangeAnimationsElements,
    actionSettingsChangeHour,
    actionSettingsChangePaginationPortfolioSAFE,
    (state, action) => ({ ...state, ...action })
  ),
  on(
    actionSettingsChangeAnimationsPageDisabled,
    (state, { pageAnimationsDisabled }) => ({
      ...state,
      pageAnimationsDisabled,
      pageAnimations: false
    })
  )
)

export function settingsReducer(
  state: SettingsState | undefined,
  action: Action
) {
  return reducer(state, action)
}
