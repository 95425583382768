import { Action, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { Valuestream } from './valuestream.model'
import * as ValuestreamActions from './valuestream.actions'

export const valuestreamsFeatureKey = 'valuestreams'

export type ValueStreamState = EntityState<Valuestream>

export const adapter: EntityAdapter<Valuestream> = createEntityAdapter<Valuestream>()

export const initialState: ValueStreamState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  on(ValuestreamActions.upsertValuestream, (state, action) =>
    adapter.upsertOne(action.valuestream, state)
  ),
  on(ValuestreamActions.addValuestreams, (state, action) =>
    adapter.addMany(action.valuestreams, state)
  ),
  on(ValuestreamActions.upsertValuestreams, (state, action) =>
    adapter.upsertMany(action.valuestreams, state)
  ),

  on(ValuestreamActions.updateValuestreams, (state, action) =>
    adapter.updateMany(action.valuestreams, state)
  ),

  on(ValuestreamActions.deleteValuestreams, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ValuestreamActions.successLoadValueStreams, (state, action) =>
    adapter.setAll(action.valuestreams, state)
  ),
  on(ValuestreamActions.successAddValuestream, (state, action) =>
    adapter.addOne(action.valuestream, state)
  ),
  on(ValuestreamActions.successUpdateValuestream, (state, action) =>
    adapter.upsertOne(action.valuestream, state)
  ),
  on(ValuestreamActions.successPatchValuestream, (state, action) =>
    adapter.upsertOne(action.valuestream, state)
  ),
  on(ValuestreamActions.successDeleteValuestream, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ValuestreamActions.successLoadValueStreamsSet, (state, action) =>
    adapter.upsertMany(action.valuestreams, state)
  ),
  on(ValuestreamActions.successAddStageToValuestream, (state, action) =>
    adapter.setOne(action.va, state)
  ),
  on(ValuestreamActions.successUpdateValuestreamStages, (state, action) =>
    adapter.setOne(action.va, state)
  ),
  on(ValuestreamActions.successAddSolutionstoValueStream, (state, action) =>
    adapter.setOne(action.va, state)
  ),
  on(ValuestreamActions.successLoadValuestream, (state, action) =>
    adapter.setOne(action.va, state)
  ),
  on(ValuestreamActions.successPatchValuestreamStage, (state, action) =>
    adapter.setOne(action.valuestream, state)
  ),

  on(ValuestreamActions.clearValuestreams, (state) => adapter.removeAll(state))
)

export function vaReducer(state: ValueStreamState | undefined, action: Action) {
  return reducer(state, action)
}
