import { createSelector } from '@ngrx/store'
import { FeaturesState, selectFeatures } from '../features.state'
import { HistoryAudit } from './history-audit.model'
import { adapter } from './history-audit.reducer'

export const selectHistoryAuditState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.history
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectHistoryAuditState)

export const getHistoryAuditByRequirement = (requirementId: string) =>
  createSelector(selectAll, (entities: HistoryAudit[]) =>
    entities
      .filter(
        (item) =>
          item.globalId.cdoId === requirementId && item.type !== 'INITIAL'
      )
      .sort((a, b) => {
        if (b.commitMetadata.commitDate > a.commitMetadata.commitDate) {
          return 1
        } else if (b.commitMetadata.commitDate < a.commitMetadata.commitDate) {
          return -1
        } else {
          return 0
        }
      })
  )
