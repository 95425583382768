import { AuthState } from './auth.models'
import * as fromAuthActions from './auth.actions'
import { createReducer, on, Action } from '@ngrx/store'

export const initialState: AuthState = {
  isAuthenticated: false,
  expirationDate: null,
  username: null,
  id: null,
  accessToken: null,
  expiresIn: null,
  issuedDate: null,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  refresh_token: null
}

const reducer = createReducer<AuthState>(
  initialState,
  // eslint-disable-next-line ngrx/on-function-explicit-return-type
  on(fromAuthActions.successLogin, (state, action) => ({
    ...state,
    isAuthenticated: true,
    username: action.response.username,
    name: action.response.name,
    id: action.response.id,
    accessToken: action.response.access_token,
    expiresIn: action.response.expiresIn,
    audience: action.response.audience,
    issuedDate: new Date(Date.now()),
    expirationDate: new Date(action.response.expiresIn * 1000)
    // expirationDate: new Date(action.response.exp * 1000 + Date.now())
  })),
  // on(authLogin, state => ({ ...state, isAuthenticated: true })),
  on(
    fromAuthActions.authSuccessLogout,
    (state): AuthState => ({
      isAuthenticated: false,
      username: '',
      id: '',
      accessToken: '',
      expiresIn: null,
      audience: '',
      expirationDate: null,
      issuedDate: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: ''
    })
  ),
  on(
    fromAuthActions.successRefreshToken,
    (state, action): AuthState => ({
      isAuthenticated: true,
      username: action.response.username,
      id: action.response.id,
      accessToken: action.response.access_token,
      expiresIn: action.response.expiresIn,
      audience: action.response.audience,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: '',
      issuedDate: new Date(Date.now()),
      expirationDate: new Date(action.response.expiresIn * 1000)
    })
  )
)

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action)
}
