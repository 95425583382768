import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core'
import { Update } from '@ngrx/entity'
import {
  Epic,
  Feature,
  RequirementI
} from '../../../../features/requirements-store/store/requirements.model'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { OverlayFormRef } from '../../../../overlay-forms/overlay/overlay-form-ref'

@Component({
  selector: 'anms-select-parent-form',
  templateUrl: './select-parent-form.component.html',
  styleUrls: ['./select-parent-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectParentFormComponent implements OnInit {
  feature: Feature
  epics: Epic[]
  currentEpic: Epic
  form: FormGroup
  currentParentId: string

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: OverlayFormRef
  ) {
    this.form = this.formBuilder.group({
      featureId: '',
      parentId: ['']
    })
  }

  ngOnInit(): void {
    if ('feature' in this.dialogRef.data) {
      this.feature = this.dialogRef.data.feature
      this.currentParentId = this.feature.parentId

      this.epics = this.dialogRef.data.epics
      if (this.feature.parentId) {
        this.currentEpic = this.epics.find(
          (epic) => epic.id === this.feature.parentId
        )

        this.form.get('parentId').setValue(this.dialogRef.data.feature.parentId)
      } else {
        this.form.get('parentId').setValue('')
      }
    }
  }

  updateParent(parentId) {
    const update: Update<RequirementI> = {
      id: this.feature.id,

      changes: { parentId }
    }
    this.currentParentId = parentId
    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }
  closeDialog() {
    this.dialogRef.close()
  }
}
