import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import { EpicOverlayFormComponent } from '../epic-overlay-form.component'

@Component({
  selector: 'anms-epic-dynamic-requirement-form',
  templateUrl: './epic-dynamic-requirement-form.component.html',
  styleUrls: ['./epic-dynamic-requirement-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EpicDynamicRequirementFormComponent implements OnInit {
  @Input() data: any
  @ViewChild('newChild', { static: true }) newChild: ElementRef
  form: FormGroup
  parentComponent: EpicOverlayFormComponent

  constructor(private formBuilder: FormBuilder, private store: Store) {
    this.form = this.formBuilder.group({
      name: [''],
      description: ['', [Validators.required]],
      id: '',
      parentId: ['', [Validators.required]],
      type: ['', [Validators.required]],
      columnId: '',
      kanbanId: '',
      toKanban: '',
      locked: '',
      itemName: '',
      portfolioId: '',
      dor: '',
      teams: []
    })
  }
  ngOnInit(): void {
    this.parentComponent = this.data.parentComponent as EpicOverlayFormComponent
    this.form.controls['parentId'].setValue(this.data.parentId)
    this.form.controls['type'].setValue('business')
    this.form.controls['name'].setValue('feature')
    this.form.controls['dor'].setValue('todo')
    // Focus always better in timeout
    /* setTimeout(() => {
      this.newChild.nativeElement.focus()
    }, 25)*/
  }
  saveNew() {
    if (this.newChild?.nativeElement?.value === '') {
      // If empty field, return
      return
    }

    if (this.form.valid) {
      const entity = this.form.getRawValue()

      this.store.dispatch(
        fromActionsKanbanItems.addKanbanitem({
          kanbanitem: entity
        })
      )
    }
    this.form.controls['description'].setValue('')
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setErrors(null)
    })
    this.newChild.nativeElement.focus()
  }
  closeDialog() {
    this.parentComponent.deleteItem()
  }
  ngAfterViewInit() {
    this.newChild.nativeElement.focus()
  }

  onEnter(event: any) {
    event.stopPropagation()
    this.saveNew()
    setTimeout(() => {
      this.parentComponent.addItem({ id: this.data })
    }, 25)
    event.preventDefault()
  }
}
