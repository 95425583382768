import { Action, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { User } from './user.model'
import * as UserActions from './user.actions'

export const usersFeatureKey = 'users'

export type UserState = EntityState<User>

export const adapter: EntityAdapter<User> = createEntityAdapter<User>()

export const initialState: UserState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  on(UserActions.addUser, (state, action) =>
    adapter.addOne(action.user, state)
  ),
  on(UserActions.registerUserSuccess, (state, action) =>
    adapter.addOne(action.user, state)
  ),
  on(UserActions.upsertUser, (state, action) =>
    adapter.upsertOne(action.user, state)
  ),
  on(UserActions.addUsers, (state, action) =>
    adapter.addMany(action.users, state)
  ),
  on(UserActions.upsertUsers, (state, action) =>
    adapter.upsertMany(action.users, state)
  ),
  on(UserActions.updateUser, (state, action) =>
    adapter.updateOne(action.user, state)
  ),
  on(UserActions.updateUsers, (state, action) =>
    adapter.updateMany(action.users, state)
  ),
  on(UserActions.deleteUser, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(UserActions.deleteUsers, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  on(UserActions.successLoadUsers, (state, action) =>
    adapter.setAll(action.users, state)
  ),
  on(UserActions.clearUsers, (state) => adapter.removeAll(state)),

  // New reducer for changeNameSuccess
  on(UserActions.changeNameSuccess, (state, action) =>
    adapter.updateOne(
      {
        id: action.response.data.id,
        changes: { name: action.response.data.name }
      },
      state
    )
  )
)
