import { Action, createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import {
  EconomicalData,
  KeyResult,
  Objective,
  StrategicTheme
} from './strategic-themes.model'
import { PatchOperation } from '../../organization/services/strategic-theme.service'

export const loadStrategicThemes = createAction(
  '[StrategicThemes/API] Load StrategicThemes'
)

export const loadStrategicTheme = createAction(
  '[StrategicThemes/API] Load StrategicTheme',
  props<{ id: string }>()
)

export const loadStrategicThemeByOrganization = createAction(
  '[StrategicThemes/API] Load StrategicTheme',
  props<{ payload: { organizationId: string } }>()
)

export const successLoadStrategicTheme = createAction(
  '[StrategicThemes/API] Success Load StrategicTheme',
  props<{ strategicTheme: StrategicTheme }>()
)

export const successLoadStrategicThemes = createAction(
  '[StrategicThemes/API] Success Load StrategicThemes',
  props<{ strategicThemes: StrategicTheme[] }>()
)

export const addStrategicTheme = createAction(
  '[StrategicThemes/API] Add StrategicTheme',
  props<{ strategicTheme: StrategicTheme }>()
)

export const successAddStrategicTheme = createAction(
  '[StrategicThemes/API] Success Add StrategicTheme',
  props<{ strategicTheme: StrategicTheme }>()
)

export const upsertStrategicTheme = createAction(
  '[StrategicThemes/API] Upsert StrategicThemes',
  props<{ strategicTheme: StrategicTheme }>()
)

export const addStrategicThemes = createAction(
  '[StrategicThemes/API] Add StrategicThemes',
  props<{ strategicThemes: StrategicTheme[] }>()
)
export const successAddStrategicThemes = createAction(
  '[StrategicThemes/API] Add StrategicThemess',
  props<{ strategicThemes: StrategicTheme[] }>()
)

export const upsertStrategicThemes = createAction(
  '[StrategicThemes/API] Upsert StrategicThemes',
  props<{ strategicThemes: StrategicTheme[] }>()
)

export const updateStrategicTheme = createAction(
  '[StrategicThemes/API] Update StrategicTheme',
  props<{ strategicTheme: Update<StrategicTheme> }>()
)

export const successUpdateStrategicTheme = createAction(
  '[StrategicThemes/API] Success Update StrategicTheme',
  props<{ strategicTheme: StrategicTheme }>()
)

export const updateStrategicThemes = createAction(
  '[StrategicThemes/API] Update StrategicThemes',
  props<{ strategicThemes: Update<StrategicTheme>[] }>()
)

export const deleteStrategicTheme = createAction(
  '[StrategicThemes/API] Delete StrategicTheme',
  props<{ id: string }>()
)
export const successDeleteStrategicTheme = createAction(
  '[StrategicTheme/API] Success Delete StrategicTheme',
  props<{ id: string }>()
)

export const deleteStrategicThemes = createAction(
  '[StrategicThemes/API] Delete StrategicThemes',
  props<{ ids: string[] }>()
)

export const clearStrategicThemes = createAction(
  '[StrategicThemes/API] Clear StrategicThemes'
)
export const patchStrategicTheme = createAction(
  '[StrategicThemes/API] Patch StrategicTheme',
  props<{
    strategicTheme: Update<StrategicTheme>
    operation?: PatchOperation
  }>()
)
export const successPatchStrategicTheme = createAction(
  '[StrategicTheme/API] Success Patch StrategicTheme',
  props<{ strategicTheme: StrategicTheme }>()
)

export const errorPatchStrategicTheme = createAction(
  '[StrategicTheme/API] Error Patch StrategicTheme',
  props<{ error: string }>()
)

export const addKeyResult = createAction(
  '[StrategicTheme/API] Add key result to StrategicTheme',
  props<{
    keyResult: KeyResult
    strategicThemeId: string
  }>()
)

export const addBudgetDistribution = createAction(
  '[StrategicTheme/API] Add Budget Distribution to StrategicTheme',
  props<{
    data: EconomicalData
    strategicThemeId: string
  }>()
)
export const deleteBudgetDistribution = createAction(
  '[StrategicTheme/API] Delete Budget Distribution from StrategicTheme',
  props<{
    dataId: string
    strategicThemeId: string
  }>()
)

export const patchBudgetDistribution = createAction(
  '[StrategicThemes/API] Patch Budget Distribution',
  props<{
    strategicThemeId: string
    data: Update<EconomicalData>
    isReplace?: boolean
  }>()
)

export const deleteKeyResult = createAction(
  '[StrategicTheme/API] Delete  Key Result',
  props<{ keyResultId: string; strategicThemeId: string }>()
)
export const patchKeyResult = createAction(
  '[StrategicThemes/API] Patch KeyResult',
  props<{
    strategicThemeId: string
    keyResult: Update<KeyResult>
  }>()
)

export const successDeleteKeyResult = createAction(
  '[StrategicTheme/API] Success Delete Key Result of a Strategic Theme',
  props<{
    strategicTheme: StrategicTheme
    keyResultId: string
  }>()
)
export const successDeleteBudgetAllocation = createAction(
  '[StrategicTheme/API] Success Delete Budget Allocation of a Strategic Theme',
  props<{
    strategicTheme: StrategicTheme
    dataId: string
  }>()
)
export const errorDeleteKeyResult = createAction(
  '[StrategicTheme/API] Error Delete Key Result of a Strategic Theme',
  props<{ error }>()
)
