<div class="main-wrapper d-flex flex-column">
  <div class="form-fields">
    

      <div class="list-stories-container flex-grow-1 overflow-auto p-3">
          <ng-container  *ngIf="descriptions$ | async as stories">

            <div *ngFor="let story of stories" class="feature-item">
        
              <mat-card class="p-3 shadow-sm" (click)="openStoryForm({ story: story, origin: $event })" style="cursor: pointer;">
                <mat-card-content>
                  <!-- Feature Type Badge -->
                  <mat-chip-list>
                    <mat-chip [ngClass]="story.type === 'business' ? 'chip-business' : 'chip-enabler'">
                      {{ story.type | titlecase }}
                    </mat-chip>
                  </mat-chip-list>
      
                  <!-- Description & Benefit Hypothesis -->
                  <p class="story-name">{{ story.itemName }}</p>
                  <p class="story-description">{{ story.description }}</p>
                </mat-card-content>
              </mat-card>
      
              <mat-divider class="my-2"></mat-divider>
            </div>
          
            </ng-container>

        

          </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event.submitter.id)" class="default-form">
    <div class="form-actions">
      <div class="action-col primary">
        <button mat-raised-button color="primary" type="submit" class="btn-load d-flex align-items-center" [disabled]='!form.valid' id="load"> <mat-icon svgIcon="ai" style="filter: brightness(0) invert(1);height:100%"></mat-icon><span class="pl-2">Load</span></button>
      </div>
      <div class="action-col secondary">
        <button mat-raised-button color="secondary" class="btn-save" [disabled]='!form.valid'>Save
          AI</button>
      </div>
    </div>
  </form>
</div>
