import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { environment as env } from '../../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  attachmentURL = env.attachment
  httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Access-Control-Allow-Origin': '*'
    })
  }
  constructor(private http: HttpClient) {}

  uploadFile(form: FormData) {
    return this.http
      .post(this.attachmentURL, form, this.httpOptions)
      .pipe(take(1))
  }
}
