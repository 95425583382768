import { createFeatureSelector, createSelector } from '@ngrx/store'
import { adapter } from './organization-member.reducer'
import { OrganizationMember } from './organization-member.model'
import { selectFeatures, FeaturesState } from '../../features.state'

/*
export const selectOrganizationMembersState = createFeatureSelector<
  State5,
  State
>(stateFeatureKey)

export const selectOrganizationMembers = createSelector(
  selectOrganizationMembersState,
  (state: State) => state.organizationmembers
)
*/

export const selectOrganizationMembersState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.organizationmembers
)
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors(selectOrganizationMembersState)

export const getMemberByIdFiltering = createSelector(
  selectAll,
  (entities, props) => entities.find((entity) => entity.id === props.id)
)
export const getMemberById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)
export const getOganizationMembersByOrganizationId = (organizationId: string) =>
  createSelector(selectAll, (entities: OrganizationMember[]) =>
    entities.filter((item) => item.organizationId === organizationId)
  )
