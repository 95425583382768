/* eslint-disable ngrx/on-function-explicit-return-type */
import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'

import * as KanbanActions from './kanban.actions'

import { Kanban, KanbanWithLoaded } from './kanban.model'

// sempre en minúscula"!!
export const kanbansFeatureKey = 'kanbans'

export type KanbanState = EntityState<Kanban>

export const adapter: EntityAdapter<Kanban> = createEntityAdapter<Kanban>()

export const initialState: KanbanState = adapter.getInitialState({})

export const reducer = createReducer(
  initialState,
  /* on(KanbanActions.addKanban, (state, action) =>
    adapter.addOne(action.kanban, state)
  ),*/
  on(KanbanActions.successAddKanban, (state, action) =>
    adapter.setOne(action.kanban, state)
  ),

  on(KanbanActions.successLoadKanban, (state, action) => ({
    ...state,
    entities: {
      ...state.entities,
      [action.kanban.id]: {
        ...action.kanban,
        loaded: true
      }
    }
  })),

  on(KanbanActions.upsertKanban, (state, action) =>
    adapter.upsertOne(action.kanban, state)
  ),
  on(KanbanActions.addKanbans, (state, action) =>
    adapter.addMany(action.kanbans, state)
  ),
  on(KanbanActions.upsertKanbans, (state, action) =>
    adapter.upsertMany(action.kanbans, state)
  ),
  on(KanbanActions.updateKanban, (state, action) =>
    adapter.updateOne(action.kanban, state)
  ),
  on(KanbanActions.updateKanbans, (state, action) =>
    adapter.updateMany(action.kanbans, state)
  ),
  on(KanbanActions.deleteKanban, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(KanbanActions.deleteKanbans, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  // on(KanbanActions.loadKanbans, (state, action) =>
  //  adapter.setAll(action.kanbans, state)
  // ),
  on(KanbanActions.successLoadKanbans, (state, action) => {
    const updatedEntities = action.kanbans.reduce(
      (entities, kanban) => ({
        ...entities,
        [kanban.id]: {
          ...kanban,
          loaded: true
        }
      }),
      {}
    )

    return adapter.setAll(action.kanbans, {
      ...state,
      entities: updatedEntities
    })
  }),
  on(KanbanActions.clearKanbans, (state) => adapter.removeAll(state))
)

export function kanbanReducer(state: KanbanState | undefined, action: Action) {
  return reducer(state, action)
}
