import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { LoadingBarModule, LOADING_BAR_CONFIG } from '@ngx-loading-bar/core'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'
import { CoreModule } from './core/core.module'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app/app.component'
import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome'
import {
  faSquare,
  faCheckSquare,
  faInfoCircle,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare as farSquare,
  faCheckSquare as farCheckSquare
} from '@fortawesome/free-regular-svg-icons'
import {
  faStackOverflow,
  faGithub,
  faMedium
} from '@fortawesome/free-brands-svg-icons'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'

import { ChildMenuComponent } from './app/menu/child-menu/child-menu.component'
import { MenuItemComponent } from './app/menu/child-menu/menu-item/menu-item.component'
import { TopMenuComponent } from './app/menu/top-menu/top-menu.component'

import localeEs from '@angular/common/locales/es'
import localeFr from '@angular/common/locales/fr'
import localeEn from '@angular/common/locales/en'
import localePt from '@angular/common/locales/pt'
import localeDe from '@angular/common/locales/de'
import localeZhCn from '@angular/common/locales/zh-Hans'
import localeSk from '@angular/common/locales/sk'

import { registerLocaleData } from '@angular/common'
import { LocaleProvider } from './core/locale/services/locale.provider'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular'
import { environment } from '../environments/environment'
import { from, Observable } from 'rxjs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BottomMenuComponent } from './app/menu/bottom-menu/bottom-menu.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { AccountMenuComponent } from './app/menu/account-menu/account-menu.component'
import { OrganizationContextMenuComponent } from './app/menu/organization-context-menu/organization-context-menu.component'

registerLocaleData(localeEs)
registerLocaleData(localeFr)
registerLocaleData(localeEn)
registerLocaleData(localePt)
registerLocaleData(localeDe)
registerLocaleData(localeZhCn)
registerLocaleData(localeSk, 'sk')

function initializeApp2(config: AuthClientConfig): () => Promise<void> {
  config.set({
    clientId: environment.auth0.clientId,
    domain: environment.auth0.domain,
    audience: environment.auth0.audience,
    redirectUri: environment.auth0.redirectUri,
    useRefreshTokens: environment.auth0.useRefreshTokens
  })
  return () =>
    new Promise<void>((resolve, reject) => {
      // Do some asynchronous stuff
      config.set({
        clientId: environment.auth0.clientId,
        domain: environment.auth0.domain,
        audience: environment.auth0.audience,
        redirectUri: environment.auth0.redirectUri,
        useRefreshTokens: environment.auth0.useRefreshTokens
      })
      resolve()
    })
}

function initializeApp(config: AuthClientConfig): () => Observable<any> {
  const envName = environment.envName

  // NEEDS TO BE SET TO AVOID
  // main.ts:13 Error: Configuration must be specified either through AuthModule.forRoot or through AuthClientConfig.se

  config.set({
    clientId: environment.auth0.clientId,
    domain: environment.auth0.domain,
    audience: environment.auth0.audience,
    redirectUri: environment.auth0.redirectUri,
    useRefreshTokens: environment.auth0.useRefreshTokens
  })

  // if (envName !== 'DEV') {
  return () =>
    from(
      new Promise<void>((resolve, reject) => {
        console.log('initializeApp:: inside promise')
        config.set({
          clientId: environment.auth0.clientId,
          domain: environment.auth0.domain,
          audience: environment.auth0.audience,
          redirectUri: environment.auth0.redirectUri,
          useRefreshTokens: environment.auth0.useRefreshTokens
        })

        // console.log('Config Loaded')
        // console.log(AppConfigService.settings)
        resolve()
      })
    )
  // }
}

@NgModule({
  imports: [
    // angular

    BrowserAnimationsModule,
    BrowserModule,
    // core
    CoreModule,
    ScrollingModule,

    // OVERLAY FORMS
    // OverlayFormsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    // app
    AppRoutingModule,

    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatTooltipModule

    // FontAwesomeModule,
    /* LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AuthModule.forRoot({
      ...auth0
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('access_token')
      }
    })
    */
    // MatExpansionModule
  ],
  declarations: [
    AppComponent,

    TopMenuComponent,
    ChildMenuComponent,
    MenuItemComponent,
    BottomMenuComponent,
    OrganizationContextMenuComponent,
    AccountMenuComponent
  ],
  bootstrap: [AppComponent],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp2,
      deps: [AuthClientConfig],
      multi: true
    },
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    LocaleProvider
  ]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faSquare,
      faCheckSquare,
      farSquare,
      farCheckSquare,
      faStackOverflow,
      faGithub,
      faMedium,
      faInfoCircle,
      faArrowUp
    )
  }
}
