import { Injectable, OnDestroy } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { BehaviorSubject, Subscription, of } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { AuthServiceGeneric } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ProfilingService implements OnDestroy {
  private isProfileCompletedSubject = new BehaviorSubject<boolean>(false)

  private subscription: Subscription
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isProfileCompleted$ = this.isProfileCompletedSubject.asObservable()

  constructor() {}
  ngOnDestroy() {
    // Unsubscribe from the subscription when the service is destroyed
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  markProfileAsCompleted() {
    this.isProfileCompletedSubject.next(true)
  }
}
