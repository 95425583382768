<div class="main-wrapper">
  <form [formGroup]="form" (ngSubmit)="onSubmit($event.submitter.id)" class="default-form">
    <div class="form-fields">

      <div class="improve_epic_form_container form-fields-inner d-flex flex-column flex-lg-row align-items-center justify-content-center">





        <div class="box-right px-0">
          <ng-container  *ngIf="descriptions$ | async as stories">
            <div *ngFor="let story of stories">

              <div (click)="openStoryForm({ story: story, origin: $event })" style="cursor: pointer;">
                
                <p>{{ story.itemName }}</p>
                <p>{{ story.description }}</p>
              </div>
              <mat-divider></mat-divider>
            </div>
            </ng-container>

            <ng-container *ngIf="descriptions$ | async as stories">
              <div *ngFor="let story of stories">
                <mat-checkbox 
                  [formControlName]="story.id" 
                  (change)="onStoryChange(story, $event.checked)">
                  {{ story.itemName }} <!-- Display the feature description or any other property -->
                </mat-checkbox>
              </div>
              <mat-divider></mat-divider>
            </ng-container>
        </div>

      </div>
    </div>

    <div class="form-actions">
      <div class="action-col primary">
        <button mat-raised-button color="primary" type="submit" class="btn-load d-flex align-items-center" [disabled]='!form.valid' id="load"> <mat-icon svgIcon="ai" style="filter: brightness(0) invert(1);height:100%"></mat-icon><span class="pl-2">Load</span></button>
      </div>
      <div class="action-col secondary">
        <button mat-raised-button color="secondary" class="btn-save" [disabled]='!form.valid'>Save
          AI</button>
      </div>
    </div>
  </form>
</div>
