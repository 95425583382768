import { CurrencyPipe } from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
@Pipe({
  name: 'currencyFormatter'
})
export class CurrencyFormatterPipe implements PipeTransform {
  private thousandsSeparator: string

  constructor(@Inject(LOCALE_ID) private _locale: string) {
    this.thousandsSeparator = ' '
  }

  transform(value: number | string): string {
    if (value === '') {
      return ''
    }

    const integer = String(value).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      this.thousandsSeparator
    )
    return new CurrencyPipe(this._locale).transform(
      Number(integer.replace(' ', '')),
      'EUR',
      'symbol',
      '1.0-0'
    )
  }

  parse(value: string): string {
    return value.replace(/[^0-9]/g, '')
  }
}
