import { Action, createReducer, on } from '@ngrx/store'
import * as StrategicThemesActions from './strategic-themes.actions'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { StrategicTheme } from './strategic-themes.model'
export const strategicThemeFeatureKey = 'strategicTheme'

export interface StrategicThemeState extends EntityState<StrategicTheme> {}

export const adapter: EntityAdapter<StrategicTheme> = createEntityAdapter<StrategicTheme>()
export const initialState: StrategicThemeState = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  on(StrategicThemesActions.successAddStrategicTheme, (state, action) =>
    adapter.addOne(action.strategicTheme, state)
  ),
  on(StrategicThemesActions.successLoadStrategicTheme, (state, action) =>
    adapter.upsertOne(action.strategicTheme, state)
  ),
  on(StrategicThemesActions.successAddStrategicThemes, (state, action) =>
    adapter.addMany(action.strategicThemes, state)
  ),
  /*
  on(StrategicThemesActions.upsertStrategicThemes, (state, action) =>
    adapter.upsertMany(action.strategicThemes, state)
  ),
  */
  on(StrategicThemesActions.successUpdateStrategicTheme, (state, action) =>
    adapter.upsertOne(action.strategicTheme, state)
  ),

  on(StrategicThemesActions.successDeleteStrategicTheme, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(StrategicThemesActions.deleteStrategicThemes, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(StrategicThemesActions.successLoadStrategicThemes, (state, action) =>
    adapter.setAll(action.strategicThemes, state)
  ),
  on(StrategicThemesActions.clearStrategicThemes, (state) =>
    adapter.removeAll(state)
  ),

  on(StrategicThemesActions.successPatchStrategicTheme, (state, action) =>
    adapter.setOne(action.strategicTheme, state)
  ),
  on(StrategicThemesActions.successDeleteKeyResult, (state, action) =>
    adapter.setOne(action.strategicTheme, state)
  ),
  on(StrategicThemesActions.successDeleteBudgetAllocation, (state, action) =>
    adapter.setOne(action.strategicTheme, state)
  )
)

export function strategicThemeReducer(
  state: StrategicThemeState | undefined,
  action: Action
) {
  return reducer(state, action)
}
