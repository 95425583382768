/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnInit } from '@angular/core'
import Dexie from 'dexie'

const APP_PREFIX = 'ANMS-'

export interface ItemsIndexed {
  ids: string[]
  entities: any[]
}

@Injectable({
  providedIn: 'root'
})
export class DexieService extends Dexie implements OnInit {
  private db: Dexie
  constructor() {
    super('ngdexieliveQuery')
    this.db = new Dexie('kvasar-entities-db')
    this.db.version(3).stores({
      'ANMS-FEATURES.ITEMS': '++id',
      'ANMS-FEATURES.ORGANIZATIONMEMBERS': '++id',
      'ANMS-FEATURES.STRATEGICTHEMES': '++id',
      'ANMS-ROADMAP.ROADMAPS': '++id',
      'ANMS-FEATURES.HISTORY': '++id',
      'ANMS-FEATURES.ORGANIZATIONS': '++id',
      'ANMS-FEATURES.KANBANS': '++id',
      'ANMS-FEATURES.PORTFOLIOS': '++id',
      'ANMS-FEATURES.ARTS': '++id',
      'ANMS-FEATURES.TEAMS': '++id',
      'ANMS-FEATURES.USERS': '++id',
      'ANMS-PLANIFICATION.PROGRAMINCREMENTS': '++id',
      'ANMS-PLANIFICATION.OBJECTIVES': '++id',
      'ANMS-FEATURES.SOLUTIONS': '++id',
      'ANMS-FEATURES.VALUESTREAMS': '++id',
      'ANMS-FEATURES.KPIS': '++id',
      'ANMS-FEATURES.KPISINSTANCES': '++id',
      testKey: '++id'
    })

    this.db.open().catch(function (err) {
      console.error(err.stack || err)
    })
  }

  ngOnInit(): void {
    this.testIndexDb()
  }
  async updateIndexedDb(key: string, value: ItemsIndexed) {
    const finalKey = `${APP_PREFIX}${key}`

    try {
      await this.db.transaction('rw', this.db[finalKey], async () => {
        for (const id of value.ids) {
          const item = value.entities[id]

          /**
           * Put stored keys when load data and update data.
           */
          await this.db[finalKey].put({ id: item.id, entity: item })
        }
      })
    } catch (error) {
      if (error.name === 'AbortError' || error.name === 'TimeoutError') {
        console.error('Transaction timed out due to inactivity.')
        // Display an appropriate error message to the user and provide options for retrying or taking alternative actions
      } else {
        console.error('An error occurred during the transaction:', error)
      }
    }
  }

  async removeItemFromDbById(id: string, key) {
    await this.db[key].delete(id)
  }

  async testIndexDb() {
    Dexie.exists('kvasar-entities-db').then(function (exists) {
      if (!exists) {
        throw new Error('IndexedDB does not exist')
      }
    })
  }
}
