import { createAction, props } from '@ngrx/store'
import { HistoryAudit } from './history-audit.model'

export const loadHistoryAudits = createAction(
  '[HistoryAudit] Load HistoryAudits'
)

export const loadHistoryAuditsSuccess = createAction(
  '[HistoryAudit] Load HistoryAudits Success',
  props<{ historyaudit: HistoryAudit[] }>()
)

export const loadHistoryAuditsFailure = createAction(
  '[HistoryAudit] Load HistoryAudits Failure',
  props<{ error: any }>()
)

export const loadHistoryAuditSet = createAction(
  '[HistoryAudit] Load HistoryAudit Set',
  props<{ id: string }>()
)

export const loadHistoryAuditSetSuccess = createAction(
  '[HistoryAudit] Load HistoryAudit Set Success',
  props<{ historyaudit: HistoryAudit[] }>()
)
