import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { KPI } from './kpi.model'

export const loadKPIs = createAction('[KPI/API] Load KPIs')
export const successLoadKPIs = createAction(
  '[KPI/API] Success Load KPIs',
  props<{ kpis: KPI[] }>()
)

export const addKPI = createAction('[KPI/API] Add KPI', props<{ kpi: KPI }>())

export const deleteKPI = createAction(
  '[KPI/API] Delete KPI',
  props<{ id: string }>()
)

export const successAddKPI = createAction(
  '[KPI/API] Success Add KPI',
  props<{ kpi: KPI; operation?: string }>()
)

export const successLoadKPI = createAction(
  '[KPI/API] Success Load KPI',
  props<{ kpi: KPI }>()
)

export const successDeleteKPI = createAction(
  '[KPI/API] Success Delete KPI',
  props<{ id }>()
)

export const successPatchKPI = createAction(
  '[KPI/API] Success Patch KPI',
  props<{ kpi: KPI }>()
)
export const successPutKPI = createAction(
  '[KPI/API] Success Put KPI',
  props<{ kpi: KPI }>()
)
export const updateKPI = createAction(
  '[KPI/API] Update KPI ',
  props<{ kpi: Update<KPI> }>()
)

export const error = createAction(
  '[KPI/API] Error KPI petition',
  props<{ error }>()
)
