import { CurrencyPipe } from '@angular/common'
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injectable,
  Injector,
  LOCALE_ID,
  OnChanges,
  SimpleChanges
} from '@angular/core'
import { CurrencyFormatterPipe } from '../pipes/currency-formatter.pipe'

@Directive({
  selector: '[anmsCurrencyFormatter]',
  providers: [CurrencyFormatterPipe]
})
export class CurrencyFormatterDirective {
  private el: HTMLInputElement

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private elementRef: ElementRef,
    private currencyPipe: CurrencyFormatterPipe
  ) {
    this.el = this.elementRef.nativeElement
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    const regex = '^[0-9]*$'
    if (!this.el.value.match(regex)) return
    this.el.value = this.currencyPipe.parse(value) // opossite of transform
  }

  @HostListener('click', ['$event.target.value'])
  onClick(value: string) {
    if (!value.includes('€')) return
    const regex = '^[0-9]*$'
    this.el.value = this.currencyPipe.parse(value) // opossite of transform
    if (!this.el.value.match(regex)) return
  }

  @HostListener('focusout', ['$event.target'])
  onBlur(value) {
    const regex = '^[0-9]*$'
    if (!this.el.value.match(regex)) return
    // 1 - Keep only [0-9] values
    let cleanValue: string = value.value.replace(/[^0-9.]/g, '')
    if (value.value === 'cleanValue') return

    // 2 - Remove left zeros
    cleanValue = cleanValue.replace(/^0+(?!$)/g, '')
    this.el.value = this.currencyPipe.parse(cleanValue)
    // this.el.value = cleanValue
    this.el.value = new CurrencyPipe(this._locale).transform(
      this.el.value,
      'EUR',
      'symbol',
      '1.0-0'
    )
  }

  ngOnInit() {
    this.el.value = this.currencyPipe.transform(this.el.value)
  }
  /* ngOnChanges(changes: SimpleChanges) {
    this.el.value = this.currencyPipe.transform(this.el.value)
  }
  */
}
