import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  ElementRef,
  QueryList,
  EventEmitter,
  Output
} from '@angular/core'

import { MenuService } from '../services/menu.service'
import { NavItem } from '../store/menu.store'
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'

@Component({
  selector: 'anms-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent implements OnInit, AfterViewInit {
  // @ViewChild('childMenu') public childMenu
  // @ViewChildren('lazyImage') lazyImages: QueryList<ElementRef>
  @Output() closeDialogEvent = new EventEmitter()
  navigation: NavItem[]
  showSubmenu = false

  constructor(
    private service: MenuService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.navigation = this.service.getDefaultNavigationMenu()
  }

  ngAfterViewInit() {
    // this.childMenu.changes.subscribe((val) => console.log(val))
    // this.navigation = this.service.getDefaultNavigationMenu()
  }

  onNavigationClick(currentItem?: NavItem) {
    if (currentItem) {
      // Extract the last fragment from the current URL
      const currentUrl = this.router.url
      const urlSegments = currentUrl.split('/')

      // Get the last segment (fragment) or an empty string if it doesn't exist
      const lastFragment = urlSegments[urlSegments.length - 1] || ''

      // Define a regular expression to match the identifier format
      const identifierPattern = /^[a-f0-9]{24}$/

      // Check if the last fragment matches the identifier format
      const isIdentifier = identifierPattern.test(lastFragment)

      // Construct the new URL with the updated organization ID
      let newUrl = `${currentItem.link}${currentItem.id}`

      // Append the last fragment only if it's present and the URL matches the specified pattern and  it's not an identifier
      if (
        currentItem.type === 'organization' &&
        !isIdentifier &&
        currentUrl.startsWith('/organizations/organization')
      ) {
        newUrl += `/${lastFragment}`
      }

      const navigationExtras: NavigationExtras = {}
      // Preserve query parameters and fragment
      /* const navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        fragment: this.route.snapshot.fragment
      }*/
      // Navigate to the new URL
      this.router.navigate([newUrl], navigationExtras)
    }

    this.closeDialogEvent.emit()
  }
}
