import { Action, createReducer, on } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { OrganizationMember } from './organization-member.model'
import * as OrganizationMemberActions from './organization-member.actions'

export const organizationMembersFeatureKey = 'organizationmembers'

export interface State extends EntityState<OrganizationMember> {
  // additional entities state properties
}

export const adapter: EntityAdapter<OrganizationMember> = createEntityAdapter<OrganizationMember>()

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  on(OrganizationMemberActions.successAddOrganizationMember, (state, action) =>
    adapter.addOne(action.organizationMember, state)
  ),
  on(OrganizationMemberActions.upsertOrganizationMember, (state, action) =>
    adapter.upsertOne(action.organizationMember, state)
  ),
  on(OrganizationMemberActions.addOrganizationMembers, (state, action) =>
    adapter.addMany(action.organizationMembers, state)
  ),
  on(OrganizationMemberActions.upsertOrganizationMembers, (state, action) =>
    adapter.upsertMany(action.organizationMembers, state)
  ),
  on(OrganizationMemberActions.updateOrganizationMember, (state, action) =>
    adapter.updateOne(action.organizationMember, state)
  ),
  on(OrganizationMemberActions.updateOrganizationMembers, (state, action) =>
    adapter.updateMany(action.organizationMembers, state)
  ),
  on(
    OrganizationMemberActions.successRemoveOrganizationMember,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(OrganizationMemberActions.deleteOrganizationMembers, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(
    OrganizationMemberActions.successLoadOrganizationMembersByOrganization,
    (state, action) => adapter.upsertMany(action.organizationMembers, state)
  ),

  on(
    OrganizationMemberActions.successLoadOrganizationMembers,
    (state, action) => adapter.setAll(action.organizationMembers, state)
  ),
  on(OrganizationMemberActions.clearOrganizationMembers, (state) =>
    adapter.removeAll(state)
  )
)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors()
