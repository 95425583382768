import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay'
import { Subject } from 'rxjs'
import { DialogPosition, PopoverContent } from './overlay.service'

export interface PopoverCloseEvent<T> {
  type: 'backdropClick' | 'close'
  data: T
}
export class OverlayFormRef<T = any> {
  /** Subject for notifying the user that the dialog has started closing. */
  private afterClosed = new Subject<PopoverCloseEvent<T>>()
  private afterClosed$ = this.afterClosed.asObservable()
  constructor(
    private overlayRef: OverlayRef,
    public content: PopoverContent,
    public data: T
  ) {
    /* overlayRef
      .backdropClick()
      .subscribe(() => this._close('backdropClick', data))*/
  }
  /**
   * Updates the dialog's position.
   * @param position New dialog position.
   */
  updatePosition(position?: DialogPosition): this {
    const strategy = this._getPositionStrategy()

    if (position && (position.left || position.right)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      position.left
        ? strategy.left(position.left)
        : strategy.right(position.right)
    } else {
      strategy.centerHorizontally()
    }

    if (position && (position.top || position.bottom)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      position.top
        ? strategy.top(position.top)
        : strategy.bottom(position.bottom)
    } else {
      strategy.centerVertically()
    }

    this.overlayRef.updatePosition()

    return this
  }

  /** Add a CSS class or an array of classes to the overlay pane. */
  addPanelClass(classes: string | string[]): this {
    this.overlayRef.addPanelClass(classes)
    return this
  }

  /** Remove a CSS class or an array of classes from the overlay pane. */
  removePanelClass(classes: string | string[]): this {
    this.overlayRef.removePanelClass(classes)
    return this
  }
  /** Fetches the position strategy object from the overlay ref. */
  private _getPositionStrategy(): GlobalPositionStrategy {
    return this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.overlayRef.dispose()
  }

  private _close(type, data) {
    this.overlayRef.dispose()
    this.afterClosed.next({
      type,
      data
    })
    this.afterClosed.complete()
  }
}
