<form [formGroup]="form"  (ngSubmit)="saveNew()" class="default-form" >

      <div class="input-wrapper">
        <mat-form-field appearance="fill" class="default-full-width">
          <mat-label >New Feature</mat-label>
          <input  matInput placeholder="New Feature" formControlName="description" #newChild (keydown.enter)="onEnter($event)" >
          <mat-hint></mat-hint>
          <mat-error *ngIf="form.get('description').invalid">
          </mat-error>
        </mat-form-field>
        <button mat-flat-button style="cursor: pointer; width:43px" (click)="closeDialog()"><span class="material-icons">close</span></button>
        <button mat-flat-button type="submit" class="btn-secondary"><span class="material-icons">check</span></button>
      </div>

  </form>
